<template>
    <!-- Onboarding Lock -->
    <div class="onboard-wrapper">
        <onboarding-slider></onboarding-slider>
        <div class="right-panel">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="set-path">
                            <div class="onboarding-content-box lock-box">
                                <div class="lock-content text-center">
                                    <img src="@/assets/img/icons/lock.svg" alt="lock">
                                    <h2>Your account is temporarily <br> disabled.</h2>
                                    <h6>Kindly wait a few seconds</h6>
                                    <a href="javascript:void(0);">Continue</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Onboarding Lock -->
</template>