<!-- eslint-disable vue/multi-word-component-names -->

<template>
  <ul class="main-nav">
    <li :class="homeMenu ? 'active' : 'notactive'">
      <router-link to="/">Home </router-link>

    </li>
    <li class="has-submenu" :class="doctorMenu ? 'active' : 'notactive'">
      <a href="javascript:void(0);" @click="toggleVisibilitydoctors">Encontre um profissional <i
          class="fas fa-chevron-down"></i></a>
      <ul class="submenu" >
        <li v-for="specializationArea in specializationAreas" :key="specializationArea.id" :class="currentPath == 'doctor-blog' ||
          currentPath == 'edit-blog' ||
          currentPath == 'doctor-pending-blog'
          ? 'active'
          : 'notactive'
          ">
          <a v-bind:href="`/pesquisa?e=${specializationArea.description}`" class="">{{ specializationArea.description
            }}</a>
       
        </li>
        <li class="notactive">
          <router-link to="/Especialidades">Todas as especialidades</router-link>
        </li>
      </ul>
 
    </li>


    <li :class="currentPath == 'contato' ? 'active' : 'notactive'">
      <router-link to="contato">Contato</router-link>
    </li>



    <li v-if="isHomeTen">
      <div class="dropdown header-dropdown country-flag">
        <a class="dropdown-toggle nav-tog" data-bs-toggle="dropdown" href="javascript:void(0);">
          <img src="@/assets/img/flags/us.png" alt="Img" />English
        </a>
        <div class="dropdown-menu dropdown-menu-end">
          <a href="javascript:void(0);" class="dropdown-item">
            <img src="@/assets/img/flags/fr.png" alt="Img" />French
          </a>
          <a href="javascript:void(0);" class="dropdown-item">
            <img src="@/assets/img/flags/es.png" alt="Img" />Spanish
          </a>
          <a href="javascript:void(0);" class="dropdown-item">
            <img src="@/assets/img/flags/de.png" alt="Img" />German
          </a>
        </div>
      </div>
    </li>
    <li class="searchbar searchbar-fourteen" v-else-if="isHomeNine">
      <a href="javascript:void(0);" @click="toggleElement"><i class="feather-search"></i></a>
      <div class="togglesearch" v-if="isVisible" @click="handleClick" style="display: block">
        <form @submit.prevent="submitForm">
          <div class="input-group">
            <input type="text" class="form-control" />
            <button type="submit" class="btn btn-primary">Search</button>
          </div>
        </form>
      </div>
    </li>
    <li class="searchbar" :style="isHomeSeven2Route ? 'display: none;' : 'display: block;'">
      <a href="javascript:void(0);" @click="toggleElement"><i class="feather-search"></i></a>
      <div class="togglesearch" v-if="isVisible" @click="handleClick" style="display: block">
        <form @submit.prevent="submitForm">
          <div class="input-group">
            <input type="text" class="form-control" />
            <button type="submit" class="btn">Search</button>
          </div>
        </form>
      </div>
    </li>
    <li class="login-link">
      <router-link to="/login">Login / Signup</router-link>
    </li>

  </ul>
</template>
<script>
import axios from 'axios';
import { ref } from "vue";

const specializationAreas = ref();
axios.get('SpecializationArea?page=1&pageSize=15')
  .then((response) => {
    specializationAreas.value = response.data.data;
  });
export default {
  data() {
    return {
      specializationAreas,
      isVisible: false,
      isVisiblehomes: false,
      isVisibledoctor: false,
      isVisibledoctorsblog: false,
      isVisiblepatients: false,
      isVisiblepatientsdoctor: false,
      isVisiblepatientssearch: false,
      isVisiblepatientsbooking: false,
      isVisiblepharmacy: false,
      isVisiblepages: false,
      isVisiblecall: false,
      isVisibleinvoice: false,
      isVisibleauthentication: false,
      isVisibleerror: false,
      isVisibleblog: false,
      isVisibleadmin: false,
    };
  },
  computed: {
    isHomeTen() {
      return this.$route.path === "/index-10";
    },
    isHomeNine() {
      return this.$route.path === "/index-9";
    },
    isHomeOneRoute() {
      return (
        this.$route.path === "/" ||
        this.$route.path === "/about-us" ||
        this.$route.path === "/blank-page" ||
        this.$route.path === "/blog-details" ||
        this.$route.path === "/blog-grid" ||
        this.$route.path === "/blog-list" ||
        this.$route.path === "/coming-soon" ||
        this.$route.path === "/components" ||
        this.$route.path === "/contato" ||
        this.$route.path === "/doctor-register" ||
        this.$route.path === "/doctor-search-grid" ||
        this.$route.path === "/doctor-signup" ||
        this.$route.path === "/error-404" ||
        this.$route.path === "/error-500" ||
        this.$route.path === "/faq" ||
        this.$route.path === "/forgot-password" ||
        this.$route.path === "/forgot-password2" ||
        this.$route.path === "/login-email-otp" ||
        this.$route.path === "/login-email" ||
        this.$route.path === "/login-phone-otp" ||
        this.$route.path === "/login" ||
        this.$route.path === "/maintenance" ||
        this.$route.path === "/map-grid" ||
        this.$route.path === "/map-list" ||
        this.$route.path === "/mobile-otp" ||
        this.$route.path === "/patient-signup" ||
        this.$route.path === "/pharmacy-register" ||
        this.$route.path === "/pricing" ||
        this.$route.path === "/privacy-policy" ||
        this.$route.path === "/register" ||
        this.$route.path === "/pesquisa" ||
        this.$route.path === "/search" ||
        this.$route.path === "/signup-success" ||
        this.$route.path === "/terms-condition" ||
        this.$route.path === "/email-otp" ||
        this.$route.path === "/login-phone"
      );
    },
    isHomeOne1Route() {
      return (
        this.$route.path === "/" ||
        this.$route.path === "/about-us" ||
        this.$route.path === "/blank-page" ||
        this.$route.path === "/blog-details" ||
        this.$route.path === "/blog-grid" ||
        this.$route.path === "/blog-list" ||
        this.$route.path === "/coming-soon" ||
        this.$route.path === "/components" ||
        this.$route.path === "/contato" ||
        this.$route.path === "/doctor-register" ||
        this.$route.path === "/doctor-search-grid" ||
        this.$route.path === "/doctor-signup" ||
        this.$route.path === "/error-404" ||
        this.$route.path === "/error-500" ||
        this.$route.path === "/faq" ||
        this.$route.path === "/forgot-password" ||
        this.$route.path === "/forgot-password2" ||
        this.$route.path === "/login-email-otp" ||
        this.$route.path === "/login-email" ||
        this.$route.path === "/login-phone-otp" ||
        this.$route.path === "/login" ||
        this.$route.path === "/maintenance" ||
        this.$route.path === "/map-grid" ||
        this.$route.path === "/map-list" ||
        this.$route.path === "/mobile-otp" ||
        this.$route.path === "/patient-signup" ||
        this.$route.path === "/pharmacy-register" ||
        this.$route.path === "/pricing" ||
        this.$route.path === "/privacy-policy" ||
        this.$route.path === "/register" ||
        this.$route.path === "/pesquisa" ||
        this.$route.path === "/search" ||
        this.$route.path === "/signup-success" ||
        this.$route.path === "/terms-condition" ||
        this.$route.path === "/email-otp" ||
        this.$route.path === "/login-phone"
      );
    },
    isHomeSeven2Route() {
      return (
        this.$route.path === "/index-7" ||
        this.$route.path === "/index-8" ||
        this.$route.path === "/index-9" ||
        this.$route.path === "/index-10" ||
        this.$route.path === "/index-12" ||
        this.$route.path === "/accounts" ||
        this.$route.path === "/add-billing" ||
        this.$route.path === "/add-prescription" ||
        this.$route.path === "/appointments" ||
        this.$route.path === "/available-timings" ||
        this.$route.path === "/booking-2" ||
        this.$route.path === "/booking-success" ||
        this.$route.path === "/booking" ||
        this.$route.path === "/cart" ||
        this.$route.path === "/change-password" ||
        this.$route.path === "/chat-doctor" ||
        this.$route.path === "/chat" ||
        this.$route.path === "/checkout" ||
        this.$route.path === "/dependent" ||
        this.$route.path === "/doctor-add-blog" ||
        this.$route.path === "/doctor-blog" ||
        this.$route.path === "/doctor-change-password" ||
        this.$route.path === "/doctor-dashboard" ||
        this.$route.path === "/doctor-pending-blog" ||
        this.$route.path === "/doctor-profile-settings" ||
        this.$route.path === "/doctor-profile" ||
        this.$route.path === "/doctor-profile-2" ||
        this.$route.path === "/edit-billing" ||
        this.$route.path === "/edit-blog" ||
        this.$route.path === "/edit-prescription" ||
        this.$route.path === "/favourites" ||
        this.$route.path === "/invoice-view" ||
        this.$route.path === "/invoices" ||
        this.$route.path === "/medical-details" ||
        this.$route.path === "/medical-records" ||
        this.$route.path === "/my-patients" ||
        this.$route.path === "/orders-list" ||
        this.$route.path === "/patient-accounts" ||
        this.$route.path === "/patient-dashboard" ||
        this.$route.path === "/patient-profile" ||
        this.$route.path === "/payment-success" ||
        this.$route.path === "/pharmacy-details" ||
        this.$route.path === "/pharmacy-index" ||
        this.$route.path === "/product-all" ||
        this.$route.path === "/product-checkout" ||
        this.$route.path === "/product-description" ||
        this.$route.path === "/profile-settings" ||
        this.$route.path === "/reviews" ||
        this.$route.path === "/available-timings" ||
        this.$route.path === "/social-media" ||
        this.$route.path === "/video-call" ||
        this.$route.path === "/voice-call" ||
        this.$route.path === "/pharmacy-search"
      );
    },
    currentPath() {
      return this.$route.name;
    },
    homeMenu() {
      return (
        this.$route.name == "index" ||
        this.$route.name == "index-2" ||
        this.$route.name == "index-3" ||
        this.$route.name == "index-4" ||
        this.$route.name == "index-5" ||
        this.$route.name == "index-6" ||
        this.$route.name == "index-7" ||
        this.$route.name == "index-8" ||
        this.$route.name == "index-9" ||
        this.$route.name == "index-10" ||
        this.$route.name == "index-11" ||
        this.$route.name == "index-12" ||
        this.$route.name == "index-13" ||
        this.$route.name == "index-14"
      );
    },
    doctorMenu() {
      return (
        this.$route.name == "doctor-dashboard" ||
        this.$route.name == "appointments" ||
        this.$route.name == "available-timings" ||
        this.$route.name == "my-patients" ||
        this.$route.name == "patient-profile" ||
        this.$route.name == "edit-prescription" ||
        this.$route.name == "add-billing" ||
        this.$route.name == "chat-doctor" ||
        this.$route.name == "doctor-profile-settings" ||
        this.$route.name == "reviews" ||
        this.$route.name == "doctor-register" ||
        this.$route.name == "doctor-blog" ||
        this.$route.name == "edit-blog" ||
        this.$route.name == "blog-details" ||
        this.$route.name == "doctor-add-blog" ||
        this.$route.name == "doctor-pending-blog" ||
        this.$route.name == "add-prescription" ||
        this.$route.name == "edit-billing" ||
        this.$route.name == "doctor-experience-settings" ||
        this.$route.name == "doctor-education-settings" ||
        this.$route.name == "doctor-awards-settings" ||
        this.$route.name == "doctor-insurance-settings" ||
        this.$route.name == "doctor-clinics-settings" ||
        this.$route.name == "doctor-business-settings"
      );
    },
    patientsMenu() {
      return (
        this.$route.name == "map-grid" ||
        this.$route.name == "map-list" ||
        this.$route.name == "search" ||
        this.$route.name == "pesquisa" ||
        this.$route.name == "doctor-profile" ||
        this.$route.name == "doctor-profile-2" ||
        this.$route.name == "booking" ||
        this.$route.name == "booking-2" ||
        this.$route.name == "checkout" ||
        this.$route.name == "booking-success" ||
        this.$route.name == "patient-dashboard" ||
        this.$route.name == "favourites" ||
        this.$route.name == "chat" ||
        this.$route.name == "profile-settings" ||
        this.$route.name == "change-password" ||
        this.$route.name == "dependent"
      );
    },
    pharmacyMenu() {
      return (
        this.$route.name == "pharmacy-index" ||
        this.$route.name == "pharmacy-details" ||
        this.$route.name == "pharmacy-search" ||
        this.$route.name == "product-all" ||
        this.$route.name == "product-description" ||
        this.$route.name == "cart" ||
        this.$route.name == "product-checkout" ||
        this.$route.name == "payment-success" ||
        this.$route.name == "pharmacy-register"
      );
    },
    pagesMenu() {
      return (
        this.$route.name == "about-us" ||
        this.$route.name == "contato" ||
        this.$route.name == "blank-page" ||
        this.$route.name == "pricing" ||
        this.$route.name == "faq" ||
        this.$route.name == "maintenance" ||
        this.$route.name == "coming-soon" ||
        this.$route.name == "terms-condition" ||
        this.$route.name == "privacy-policy" ||
        this.$route.name == "components" ||
        this.$route.name == "voice-call" ||
        this.$route.name == "video-call" ||
        this.$route.name == "invoices" ||
        this.$route.name == "invoice-view" ||
        this.$route.name == "login-email" ||
        this.$route.name == "login-phone" ||
        this.$route.name == "doctor-signup" ||
        this.$route.name == "patient-signup" ||
        this.$route.name == "forgot-password" ||
        this.$route.name == "forgot-password2" ||
        this.$route.name == "login-email-otp" ||
        this.$route.name == "login-phone-otp" ||
        this.$route.name == "error-404" ||
        this.$route.name == "error-500" ||
        this.$route.name == "invoices" ||
        this.$route.name === "mobile-otp"
      );
    },
    blogMenu() {
      return (
        this.$route.name == "blog-list" ||
        this.$route.name == "blog-grid" ||
        this.$route.name == "blog-details"
      );
    },
    adminMenu() {
      return this.$route.name == "admin/index" || this.$route.name == "pharmacy/index";
    },
  },
  methods: {
    submitForm() {
      this.$router.push("/search");
    },
    toggleElement() {
      this.isVisible = !this.isVisible;
    },
    toggleVisibilityhomes() {
      this.isVisiblehomes = !this.isVisiblehomes;
    },
    toggleVisibilitydoctors() {
      this.isVisibledoctor = !this.isVisibledoctor;
    },
    toggleVisibilitydoctorsblog() {
      this.isVisibledoctorsblog = !this.isVisibledoctorsblog;
    },
    toggleVisibilitypatients() {
      this.isVisiblepatients = !this.isVisiblepatients;
    },
    toggleVisibilitypatientsdoctors() {
      this.isVisiblepatientsdoctor = !this.isVisiblepatientsdoctor;
    },
    toggleVisibilitysearch() {
      this.isVisiblepatientssearch = !this.isVisiblepatientssearch;
    },
    toggleVisibilitybooking() {
      this.isVisiblepatientsbooking = !this.isVisiblepatientsbooking;
    },
    toggleVisibilitypharmacy() {
      this.isVisiblepharmacy = !this.isVisiblepharmacy;
    },
    toggleVisibilitypages() {
      this.isVisiblepages = !this.isVisiblepages;
    },
    toggleVisibilitycall() {
      this.isVisiblecall = !this.isVisiblecall;
    },
    toggleVisibilityinvoice() {
      this.isVisibleinvoice = !this.isVisibleinvoice;
    },
    toggleVisibilityauthentication() {
      this.isVisibleauthentication = !this.isVisibleauthentication;
    },
    toggleVisibilityerror() {
      this.isVisibleerror = !this.isVisibleerror;
    },
    toggleVisibilityblog() {
      this.isVisibleblog = !this.isVisibleblog;
    },
    toggleVisibilityadmin() {
      this.isVisibleadmin = !this.isVisibleadmin;
    },
    redirectReloadmapgrid() {
      this.$router.push({ path: "/map-grid" }).then(() => {
        this.$router.go();
      });
    },
    redirectReloadmaplist() {
      this.$router.push({ path: "/map-list" }).then(() => {
        this.$router.go();
      });
    },
    redirectReloadsearch() {
      this.$router.push({ path: "/pesquisa" }).then(() => {
        this.$router.go();
      });
    },

    redirectReloadIndex11() {
      this.$router.push({ path: "index-11" }).then(() => {
        this.$router.go();
      });
    },
  },
};
</script>
