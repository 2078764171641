<template>
  <section class="app-section app-sec-twelve pharmacy-app-sec">
    <div class="container">
      <div class="app-twelve border-0">
        <div class="app-bg">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
              <div class="mobile-img">
                <img src="@/assets/img/mobile-img.png" class="img-fluid" alt="img" />
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="app-content">
                <div class="app-header">
                  <h5>Working for Your Better Health.</h5>
                  <h2>Download the Doccure App today!</h2>
                </div>
                <div class="app-scan">
                  <p>Scan the QR code to get the app now</p>
                  <img src="@/assets/img/scan-img.png" alt="scan-image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
