<template>
    <!-- Onboarding Payments -->

    <div class="onboard-wrapper">
        <onboarding-professional-slider></onboarding-professional-slider>
        <div class="right-panel">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 p-0">
                        <div class="right-panel-title text-center">
                            <router-link to="/"> <img src="@/assets/img/logo-light.png"
                                    alt="saascare-logo"></router-link>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <onboarding-professional-sidebar></onboarding-professional-sidebar>
                    </div>
                    <div class="col-lg-8 col-md-12">
                        <div class="onboarding-content-box">
                            <div class="row">
                                <div class="col-lg-10">
                                    <div class="onboarding-title">
                                        <h2>Detalhes de Pagamento <span>*</span></h2>
                                        <h6>Entre com seus dados de pagamentos</h6>
                                    </div>
                                    <div class="onboarding-content passcode-wrap mail-box">
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <label class="onboarding-lable">Nome no cartão <span>*</span></label>
                                                    <input type="text" placeholder="Nome no cartão"
                                                        class="form-control placeholder-style" required="">
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="form-group">
                                                    <label class="onboarding-lable">Expiration
                                                        Date<span>*</span></label>
                                                    <vue-select :options="Month" placeholder="Mês" />
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="form-group">
                                                    <label class="onboarding-lable">&nbsp;</label>
                                                    <datepicker v-model="startdate" class="picker" :editable="true"
                                                        :clearable="false" />
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div class="form-group">
                                                    <label class="onboarding-lable">CVV<span>*</span></label>
                                                    <input type="text" placeholder="Enter CVV"
                                                        class="form-control placeholder-style" maxlength="3"
                                                        required="">
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <label class="onboarding-lable">Número do cartão<span>*</span></label>
                                                    <input type="text" placeholder="4111 1111 1111 1111"
                                                        class="form-control placeholder-style" required="">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-1g-12">
                                            <div class="save-detail">
                                                <div class="tog-button">
                                                    <label class="switch">
                                                        <input type="checkbox">
                                                        <span class="tog-slider round"></span>
                                                    </label>
                                                </div>
                                                <div class="save-card">
                                                    <h5>Savar cartão para futuras compras?</h5>
                                                    <h6> If you need more info, please check budget planning</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="onboarding-btn Personalize-btn">
                                            <router-link to="/onboarding-professional-preferences">Continue</router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2">
                                    <div class="payment-icon">
                                        <img src="@/assets/img/icons/payment-card.png" alt="Image" class="img-fluid">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- /Onboarding Payments -->
</template>
<script>

import { ref } from 'vue'
const currentDate = ref(new Date())
export default {
    data() {
        return {
            startdate: currentDate,
            Month: ["Selecionar Mês", "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", " Dezembro"],
        }
    },
    components: {
    },
    mounted() {

    },
}
</script>
