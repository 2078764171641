<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="stickysidebar">
    <div class="filter-contents ">
      <div class="filter-header">
        <h4 class="filter-title"><a href="#filtros" data-bs-toggle="collapse">Filtros</a></h4>
      </div>
      <div id="filtros" class="filter-details collapse show">

        <!-- Filter Grid -->
        <div class="filter-grid">
          <h4>
            <a href="#especialidades" data-bs-toggle="collapse">Especialidade</a>
          </h4>
          <div id="especialidades" class="collapse show">
            <div class="filter-collapse">
              <ul>

                <li>
                  <v-autocomplete :items="specializationAreas" item-title="text" item-value="id"
                    id="specializationAreasSearch" v-model="specializationSelectedId" auto-select-first
                    class="flex-full-width" density="comfortable" no-data-text="Informação não localizada"
                    placeholder="Selecione a especialidade" variant="solo"
                    @keydown.enter="selecionaEspecialidade($event)"
                    @update:modelValue="selecionaEspecialidade($event)" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- /Filter Grid -->

        <!-- Filter Grid -->
        <div class="filter-grid">
          <h4>
            <a href="#estados" data-bs-toggle="collapse">Estado</a>
          </h4>
          <div id="estados" class="collapse show">
            <div class="filter-collapse">
              <ul>
                <li>
                  <v-autocomplete :items="states" item-title="text" item-value="id" v-model="stateSelectedId"
                    auto-select-first id="statesSearch" placeholder="Selecione o estado"
                    @keydown.enter="selecionaEstado($event)" class="flex-full-width"
                    no-data-text="Informação não localizada" density="comfortable" variant="solo"
                    @update:modelValue="selecionaEstado($event)" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- /Filter Grid -->

        <!-- Filter Grid -->
        <div class="filter-grid">
          <h4>
            <a href="#cidades" data-bs-toggle="collapse">Cidade</a>
          </h4>
          <div id="cidades" class="collapse show">
            <div class="filter-collapse">
              <ul>
                <li>
                  <v-autocomplete :items="cities" item-title="text" item-value="id" v-model="citySelectedId"
                    auto-select-first id="citiesSearch" placeholder="Selecione a cidade"
                    @keydown.enter="selecionaCidade($event)" class="flex-full-width"
                    no-data-text="Informação não localizada" density="comfortable" variant="solo"
                    @update:modelValue="selecionaCidade($event)" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- /Filter Grid -->


        <!-- Filter Grid -->
        <div class="filter-grid">
          <h4>
            <a href="#bairros" data-bs-toggle="collapse">Bairro</a>
          </h4>
          <div id="bairros" class="collapse show">
            <div class="filter-collapse">
              <ul>
                <li>
                  <v-autocomplete :items="neighborhoods" item-title="text" item-value="id"
                    v-model="neighborhoodSelectedId" auto-select-first id="neighborhoodsSearch"
                    placeholder="Selecione o bairro" @keydown.enter="selecionaBairro($event)" class="flex-full-width"
                    no-data-text="Informação não localizada" density="comfortable" variant="solo"
                    @update:modelValue="selecionaBairro($event)" />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- /Filter Grid -->

        <!-- Filter Grid -->
        <!--<div class="filter-grid">
          <h4>
            <a href="#collapsethree" data-bs-toggle="collapse"
              >Consultation Fee</a
            >
          </h4>
          <div id="collapsethree" class="collapse show">
            <div class="filter-collapse">
              <div class="filter-content filter-content-slider">
                <p>$10 <span>$10000</span></p>
                <div class="slider-wrapper">
                  <div id="price-range">
                    <Slider v-model="value3" :merge="merge" :format="format" />
                  </div>
                </div>
                <div class="price-wrapper">
                  <h6>
                    Price:
                    <span>
                      $<span id="pricerangemin">10</span> - $<span id="pricerangemax"
                        >10000</span
                      >
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>-->
        <!-- /Filter Grid -->



        <!-- Filter Grid -->
        <!--<div class="filter-grid">
          <h4>
            <a href="#collapsefive" data-bs-toggle="collapse"
              >Experience</a
            >
          </h4>
          <div id="collapsefive" class="collapse show">
            <div class="filter-collapse">
              <ul>
                <li>
                  <label class="custom_check d-inline-flex">
                    <input type="checkbox" name="experience" />
                    <span class="checkmark"></span>
                    1-5 Years
                  </label>
                </li>
                <li>
                  <label class="custom_check d-inline-flex">
                    <input type="checkbox" name="experience" />
                    <span class="checkmark"></span>
                    5+ Years
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>-->
        <!-- /Filter Grid -->

        <!-- Filter Grid -->
        <!--<div class="filter-grid">
          <h4>
            <a href="#collapsesix" data-bs-toggle="collapse"
              >Online Consultation</a
            >
          </h4>
          <div id="collapsesix" class="collapse show">
            <div class="filter-collapse">
              <ul>
                <li>
                  <label class="custom_check d-inline-flex">
                    <input type="checkbox" name="online" />
                    <span class="checkmark"></span>
                    <i class="feather-video online-icon"></i> Video Call
                  </label>
                </li>
                <li>
                  <label class="custom_check d-inline-flex">
                    <input type="checkbox" name="online" />
                    <span class="checkmark"></span>
                    <i class="feather-mic online-icon"></i> Audio Call
                  </label>
                </li>
                <li>
                  <label class="custom_check d-inline-flex">
                    <input type="checkbox" name="online" />
                    <span class="checkmark"></span>
                    <i class="feather-message-square online-icon"></i> Chat
                  </label>
                </li>
                <li>
                  <label class="custom_check d-inline-flex">
                    <input type="checkbox" name="online" />
                    <span class="checkmark"></span>
                    <i class="feather-users online-icon"></i> Instant Consulting
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>-->
        <!-- /Filter Grid -->

        <!-- Filter Grid -->
        <!--<div class="filter-grid">
          <h4>
            <a href="#collapseseven" data-bs-toggle="collapse"
              >By Rating</a
            >
          </h4>
          <div id="collapseseven" class="collapse show">
            <div class="filter-collapse">
              <ul>
                <li>
                  <div class="custom_check rating_custom_check d-inline-flex">
                    <input type="checkbox" name="online" />
                    <span class="checkmark"></span>
                    <div class="rating">
                      <i class="fas fa-star filled"></i>
                      <i class="fas fa-star filled ms-1"></i>
                      <i class="fas fa-star filled ms-1"></i>
                      <i class="fas fa-star filled ms-1"></i>
                      <i class="fas fa-star filled ms-1"></i>
                      <span class="rating-count">(40)</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="custom_check rating_custom_check d-inline-flex">
                    <input type="checkbox" name="online" />
                    <span class="checkmark"></span>
                    <div class="rating">
                      <i class="fas fa-star filled"></i>
                      <i class="fas fa-star filled ms-1"></i>
                      <i class="fas fa-star filled ms-1"></i>
                      <i class="fas fa-star filled ms-1"></i>
                      <i class="fas fa-star ms-1"></i>
                      <span class="rating-count">(35)</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="custom_check rating_custom_check d-inline-flex">
                    <input type="checkbox" name="online" />
                    <span class="checkmark"></span>
                    <div class="rating">
                      <i class="fas fa-star filled"></i>
                      <i class="fas fa-star filled ms-1"></i>
                      <i class="fas fa-star filled ms-1"></i>
                      <i class="fas fa-star ms-1"></i>
                      <i class="fas fa-star ms-1"></i>
                      <span class="rating-count">(20)</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="custom_check rating_custom_check d-inline-flex">
                    <input type="checkbox" name="online" />
                    <span class="checkmark"></span>
                    <div class="rating">
                      <i class="fas fa-star filled"></i>
                      <i class="fas fa-star filled ms-1"></i>
                      <i class="fas fa-star ms-1"></i>
                      <i class="fas fa-star ms-1"></i>
                      <i class="fas fa-star ms-1"></i>
                      <span class="rating-count">(10)</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="custom_check rating_custom_check d-inline-flex">
                    <input type="checkbox" name="online" />
                    <span class="checkmark"></span>
                    <div class="rating">
                      <i class="fas fa-star filled"></i>
                      <i class="fas fa-star ms-1"></i>
                      <i class="fas fa-star ms-1"></i>
                      <i class="fas fa-star ms-1"></i>
                      <i class="fas fa-star ms-1"></i>
                      <span class="rating-count">(05)</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>-->
        <!-- /Filter Grid -->

        <!-- Filter Grid -->
        <!--<div class="filter-grid">
          <h4>
            <a href="#collapseeight" data-bs-toggle="collapse"
              >Languages</a
            >
          </h4>
          <div id="collapseeight" class="collapse show">
            <div class="filter-collapse">
              <ul>
                <li>
                  <label class="custom_check d-inline-flex">
                    <input type="checkbox" name="language" />
                    <span class="checkmark"></span>
                    English
                  </label>
                </li>
                <li>
                  <label class="custom_check d-inline-flex">
                    <input type="checkbox" name="language" />
                    <span class="checkmark"></span>
                    French
                  </label>
                </li>
                <li>
                  <label class="custom_check d-inline-flex">
                    <input type="checkbox" name="language" />
                    <span class="checkmark"></span>
                    Spanish
                  </label>
                </li>
                <li>
                  <label class="custom_check d-inline-flex">
                    <input type="checkbox" name="language" />
                    <span class="checkmark"></span>
                    German
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </div>-->
        <!-- /Filter Grid -->

        <!-- Filter Btn -->
        <!-- <div class="filter-btn apply-btn">
          <div class="row">
            <div class="col-6">
              <a href="javascript:void(0);" @click="pesquisa" class="btn btn-primary">Aplicar</a>
            </div>
            <div class="col-6">
              <a href="javascript:void(0);" @click="limpar" class="btn btn-outline-primary">Limpar</a>
            </div>
          </div>
        </div>-->
        <!-- /Filter Btn -->
      </div>
    </div>
  </div>
</template>


<script>

import Slider from "@vueform/slider";
import axios from 'axios';
import { ref } from "vue";

const specializationAreas = ref();

const states = ref();
const cities = ref();
const neighborhoods = ref();

const specializationAreaId = ref();
const specializationAreaSearchedName = ref();
const specializationAreaName = ref();
const stateId = ref();
const stateName = ref();
const cityId = ref();
const cityName = ref();
const neighborhoodId = ref();
const neighborhoodName = ref();
let specializationSelectedId;
let stateSelectedId;
let citySelectedId;
let neighborhoodSelectedId;
export default {

  data() {
    return {
      specializationAreas,
      states,
      cities,
      neighborhoods,
      specializationAreaId,
      specializationAreaSearchedName,
      specializationAreaName,
      specializationSelectedId,
      stateSelectedId,
      citySelectedId,
      neighborhoodSelectedId,
      stateId,
      stateName,
      cityId,
      cityName,
      neighborhoodId,
      neighborhoodName,
      value3: [0, 50],
      merge: 10,
      format: {
        prefix: "$",
        suffix: "0",
      },
    };
  },
  props: {
    especialidade: String
  },
  mounted() {
    document.querySelectorAll(".fav-icon").forEach(function (element) {
      element.addEventListener("click", function () {
        element.classList.toggle("selected");
      });
    });

    if (document.getElementById("more") != null) {
      const button = document.getElementById("more");
      const container = document.getElementById("fill-more");

      let isLess = true;

      function showMoreLess() {
        if (isLess) {
          isLess = false;
          container.style.height = "auto";
          button.innerHTML = "Show less <i class='feather-arrow-up-circle ms-1'>";
        } else {
          isLess = true;
          container.style.height = "180px";
          button.innerHTML = "Show more <i class='feather-arrow-down-circle ms-1'></i>";
        }
      }

      button.addEventListener("click", showMoreLess);
    }

    if (this.especialidade != undefined) {
      specializationAreaSearchedName.value = this.especialidade;
    }

    axios.get('SpecializationArea?page=1&pageSize=50')
      .then((response) => {
        let data = response.data.data.map((results) => {
          if (this.specializationSelectedId == '' || this.specializationSelectedId == undefined) {
            if (specializationAreaSearchedName.value === results.description) {
              this.specializationSelectedId = results.id
              specializationAreaId.value = this.specializationSelectedId
              specializationAreaName.value = specializationAreaSearchedName.value
              this.pesquisa()
            }
          }
          return {
            id: results.id,
            text: results.description
          };
        });
        specializationAreas.value = data
      });

    axios.get('States?page=1&pageSize=50&countryId=d11b3e24-3ec6-46e3-9a42-c06be1ae53bb')
      .then((response) => {
        //    states.value = response.data.data;
        states.value = response.data.data.map((results) => {
          return {
            id: results.id,
            text: results.name
          };
        });
      });
  },
  components: {
    Slider,
  },
  methods: {
    selecionaEspecialidade(e) {
      this.$nextTick(
        () => {
          let data = specializationAreas.value.find(f => f.id == e)
          if (data != undefined) {
            specializationAreaName.value = data.text
            specializationAreaId.value = data.id
            this.pesquisa()
          }
        }
      )
    },
    selecionaEstado(e) {
      this.$nextTick(
        () => {
          let data = states.value.find(f => f.id == e)
          this.neighborhoodSelectedId = '';
          this.citySelectedId = '';          
          neighborhoods.value = []
          cities.value = []
          neighborhoodId.value = ''
          neighborhoodName.value = ''
          cityId.value = ''
          cityName.value = ''
          if (data != undefined) {
            stateId.value = data.id
            stateName.value = data.text
            axios.get('Cities?page=1&pageSize=800&stateId=' + e)
              .then((response) => {
                cities.value = response.data.data.map((results) => {
                  return {
                    id: results.id,
                    text: results.name
                  };
                });
              });
            this.pesquisa()
          }
        }
      )
    },
    selecionaCidade(e) {
      this.$nextTick(
        () => {
          let data = cities.value.find(f => f.id == e)
          neighborhoods.value = []
          neighborhoodId.value = ''
          neighborhoodName.value = ''
          this.neighborhoodSelectedId = '';
          if (data != undefined) {
            this.citySelectedId = data.id
            cityId.value = data.id
            cityName.value = data.text
            axios.get('Neighborhoods?page=1&pageSize=3000&cityId=' + e)
              .then((response) => {
                neighborhoods.value = response.data.data.map((results) => {
                  return {
                    id: results.id,
                    text: results.name
                  };
                });
              });
            this.pesquisa()
          }
        }
      )
    },
    selecionaBairro(e) {
      this.$nextTick(
        () => {
          let data = neighborhoods.value.find(f => f.id == e)
          if (data != undefined) {
            this.neighborhoodSelectedId = data.id
            neighborhoodId.value = data.id
            neighborhoodName.value = data.text
            this.pesquisa()
          }
        }
      )
    },
    limpar() {

    },
    pesquisa() {
      this.$emit('pesquisa', {
        specializationAreaId: specializationAreaId.value, specializationAreaName: specializationAreaName.value,
        countryId: 'd11b3e24-3ec6-46e3-9a42-c06be1ae53bb', countryName: 'Brasil',
        stateId: stateId.value, stateName: stateName.value,
        cityId: cityId.value, cityName: cityName.value,
        neighborhoodId: neighborhoodId.value, neighborhoodName: neighborhoodName.value
      })
    }

  },

};
</script>
<style>
.stickysidebar {
  position: sticky;
  top: 0;
}

.slider-connect {
  background: var(--slider-connect-bg, #0e82fd) !important;
  cursor: pointer;
}

.slider-tooltip {
  background: var(--slider-tooltip-bg, #0b2540) !important;
  border: 1px solid var(--slider-tooltip-bg, #0b2540) !important;
  border-radius: var(--slider-tooltip-radius, 5px);
  color: var(--slider-tooltip-color, #fff);
  display: block;
  font-size: var(--slider-tooltip-font-size, 0.875rem);
  font-weight: var(--slider-tooltip-font-weight, 600);
  line-height: var(--slider-tooltip-line-height, 1.25rem);
  min-width: var(--slider-tooltip-min-width, 20px);
  padding: var(--slider-tooltip-py, 2px) var(--slider-tooltip-px, 6px);
  position: absolute;
  text-align: center;
  white-space: nowrap;
}

.roundskin .slider-tooltip {
  background: var(--slider-tooltip-bg, #0e82fd) !important;
  border: 1px solid var(--slider-tooltip-bg, #0e82fd) !important;
  border-radius: var(--slider-tooltip-radius, 5px);
  color: var(--slider-tooltip-color, #fff);
  display: block;
  font-size: var(--slider-tooltip-font-size, 0.875rem);
  font-weight: var(--slider-tooltip-font-weight, 600);
  line-height: var(--slider-tooltip-line-height, 1.25rem);
  min-width: var(--slider-tooltip-min-width, 20px);
  padding: var(--slider-tooltip-py, 2px) var(--slider-tooltip-px, 6px);
  position: absolute;
  text-align: center;
  white-space: nowrap;
}

.slider-handle {
  background-color: #fff !important;
  border: 2px solid #0e82fd !important;
}

.slider-tooltip.slider-tooltip-top {
  display: none !important;
}
</style>
<style src="@vueform/slider/themes/default.css"></style>
