<template>
    <!-- Onboarding Personalize -->

    <div class="onboard-wrapper">
        <onboarding-slider></onboarding-slider>
        <div class="right-panel">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 p-0">
                        <div class="right-panel-title text-center">
                            <router-link to="/"> <img src="@/assets/img/logo-light.png"
                                    alt="saascare-logo"></router-link>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <onboarding-sidebar></onboarding-sidebar>
                    </div>
                    <div class="col-lg-8 col-md-12">
                        <div class="onboarding-content-box">
                            <div class="onboarding-title profile-title">
                                <h2>Personalize seu perfil <span>*</span></h2>
                            </div>
                            <div class="onboarding-content passcode-wrap mail-box">
                                <Form :validation-schema="schema" @submit="onSubmit" id="formAddress">
                                    <div class="row">
                                        <div class="col-lg-4">
                                                <div class="form-group">
                                                    <label class="onboarding-lable">Nome <span>*</span></label>
                                                    <Field name="name" id="name" type="text" placeholder="Nome"
                                                        :rules="ValidateName"
                                                        class="form-control placeholder-style" required=""/>
                                                        <ErrorMessage name="name" />
                                                </div>
                                            </div>
                                            <div class="col-lg-4">   
                                                <div class="form-group">
                                                    <label class="onboarding-lable">Nome do Meio<span>*</span></label>
                                                    <Field name="middleName" id="middleName" type="text" placeholder="Nome do meio"
                                                        :rules="ValidateName"
                                                        class="form-control placeholder-style" required=""/>
                                                        <ErrorMessage name="middleName" />
                                                </div>
                                            </div>
                                            <div class="col-lg-4">   
                                                <div class="form-group">
                                                    <label class="onboarding-lable">Sobrenome <span>*</span></label>
                                                    <Field name="surName" id="surName" type="text" placeholder="Sobrenome"
                                                        :rules="ValidateName"
                                                        class="form-control placeholder-style" required=""/>
                                                        <ErrorMessage name="surName" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label class="onboarding-lable">Genero <span>*</span></label>
                                                    <vue-select :options="Gender" placeholder="Selecione o genero" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label class="onboarding-lable">Data de Nascimento <span>*</span></label>
                                                    <datepicker v-model="startdate" class="picker" :editable="true"
                                                        :clearable="false" />
                                                </div>
                                             </div>

                                             <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label class="onboarding-lable">Estado<span>*</span></label>
                                                    <v-autocomplete :items="states" item-title="text" item-value="id" v-model="stateSelectedId" 
                                                        :rules="requiredRule" 
                                                        auto-select-first id="statesSearch" placeholder="Selecione o estado"
                                                        @keydown.enter="selecionaEstado($event)" class="flex-full-width"
                                                        no-data-text="Informação não localizada" density="comfortable" variant="solo"
                                                        @update:modelValue="selecionaEstado($event)" />
                                                        <ErrorMessage name="statesSearch" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label class="onboarding-lable">Cidade <span>*</span></label>
                                                    <v-autocomplete :items="cities" item-title="text" item-value="id" v-model="citySelectedId"
                                                        auto-select-first id="citiesSearch" placeholder="Selecione a cidade"
                                                        :rules="requiredRule" 
                                                        @keydown.enter="selecionaCidade($event)" class="flex-full-width"
                                                        no-data-text="Informação não localizada" density="comfortable" variant="solo"
                                                        @update:modelValue="selecionaCidade($event)" />
                                                        <ErrorMessage name="citiesSearch" />
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <label class="onboarding-lable">Bairro<span>*</span></label>
                                                    <v-autocomplete :items="neighborhoods" name="neighborhoods" item-title="text" item-value="id"
                                                        v-model="neighborhoodSelectedId" auto-select-first id="neighborhoodsSearch"
                                                        :rules="requiredRule" 
                                                        placeholder="Selecione o bairro" @keydown.enter="selecionaBairro($event)" class="flex-full-width"
                                                        no-data-text="Informação não localizada" density="comfortable" variant="solo"
                                                        @update:modelValue="selecionaBairro($event)" />
                                                        <ErrorMessage name="neighborhoodsSearch" />
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <label class="onboarding-lable">Endereço<span>*</span></label>
                                                    <Field name="address" id="address" type="text" placeholder="Entre com o Endereço"
                                                        :rules="ValidateAddress"
                                                        class="form-control placeholder-style" required="" />
                                                        <ErrorMessage name="address" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label class="onboarding-lable">Número<span>*</span></label>
                                                    <Field name="numberAddress" id="numberAddress" type="text" placeholder="Entre com o número"
                                                        :rules="ValidateNumber"
                                                        class="placeholder-style form-control" required="" />
                                                        <ErrorMessage name="numberAddress" />
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label class="onboarding-lable">CEP <span>*</span></label>
                                                    <Field type="text" placeholder="Entre com o CEP"
                                                        name="cepAddress" id="cepAddress" 
                                                        :rules="ValidateCEP"
                                                        class="placeholder-style form-control" required="" />
                                                        <ErrorMessage name="cepAddress" />
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <label class="onboarding-lable">Complemento </label>
                                                    <Field type="text" placeholder="Complemento"
                                                        id="complemento" name="complemento"
                                                        class="placeholder-style form-control" required="" />
                                                </div>
                                            </div>
                                    </div>
                                </Form>
                                <div class="onboarding-btn Personalize-btn">
                                    <button type="submit" form="formAddress">Continuar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- /Onboarding Personalize -->
</template>
<script>
let customerPassword = localStorage.getItem("customerPassword");
let customerUsername = localStorage.getItem("customerUsername");
let customerEmail = localStorage.getItem("customerEmail");
let token = localStorage.getItem("token");
let phone = localStorage.getItem("phone");
let customerPeopleId = localStorage.getItem('customerPeopleId');
let customerId = localStorage.getItem('customerId');

import { ref } from 'vue'
import axios from 'axios';
import { Form, Field, ErrorMessage } from 'vee-validate';

const states = ref();
const cities = ref();
const neighborhoods = ref();

const countryId = ref();
const countryName = ref();
const stateId = ref();
const stateName = ref();
const cityId = ref();
const cityName = ref();
const neighborhoodId = ref();
const neighborhoodName = ref();

let stateSelectedId;
let citySelectedId;
let neighborhoodSelectedId;

const currentDate = ref(new Date())
export default {
    data() {
        return {
            countryId: "d11b3e24-3ec6-46e3-9a42-c06be1ae53bb", 
            countryName: "Brasil",
            startdate: currentDate,
            Gender: ["Selecione o Gênero", "Masculino", "Feminino"],
            states,
            cities,
            neighborhoods,
            stateSelectedId,
            citySelectedId,
            neighborhoodSelectedId,
            stateId,
            stateName,
            cityId,
            cityName,
            neighborhoodId,
            neighborhoodName, 
            errors: [],
            requiredRule: [
                value => !!value || 'Campo é obrigatório',
            ],
            customerPassword,
            customerUsername,
            customerEmail,
            token,
            phone,
            customerPeopleId,
            customerId
        }
    },
    mounted() 
    {
        axios.get('States?page=1&pageSize=50&countryId=d11b3e24-3ec6-46e3-9a42-c06be1ae53bb').then((response) => 
        {
            //states.value = response.data.data;
            states.value = response.data.data.map((results) => {
                return {
                    id: results.id,
                    text: results.name
                };
            });
        });
    },
    components: {
        Form,
        Field,
        ErrorMessage, 
    },
    methods: {
        selecionaEstado(e) {
        this.$nextTick(
            () => {
            let data = states.value.find(f => f.id == e)
            this.neighborhoodSelectedId = '';
            this.citySelectedId = '';          
            neighborhoods.value = []
            cities.value = []
            neighborhoodId.value = ''
            neighborhoodName.value = ''
            cityId.value = ''
            cityName.value = ''
            if (data != undefined) {
                stateId.value = data.id
                stateName.value = data.text
                axios.get('Cities?page=1&pageSize=800&stateId=' + e)
                .then((response) => {
                    cities.value = response.data.data.map((results) => {
                    return {
                        id: results.id,
                        text: results.name
                    };
                    });
                });
            }
            }
        )
        },
        selecionaCidade(e) {
        this.$nextTick(
            () => {
            let data = cities.value.find(f => f.id == e)
            neighborhoods.value = []
            neighborhoodId.value = ''
            neighborhoodName.value = ''
            this.neighborhoodSelectedId = '';
            if (data != undefined) {
                this.citySelectedId = data.id
                cityId.value = data.id
                cityName.value = data.text
                axios.get('Neighborhoods?page=1&pageSize=3000&cityId=' + e)
                .then((response) => {
                    neighborhoods.value = response.data.data.map((results) => {
                    return {
                        id: results.id,
                        text: results.name
                    };
                    });
                });
            }
            }
        )
        },
        selecionaBairro(e) {
        this.$nextTick(
            () => {
            let data = neighborhoods.value.find(f => f.id == e)
            if (data != undefined) {
                this.neighborhoodSelectedId = data.id
                neighborhoodId.value = data.id
                neighborhoodName.value = data.text
            }
            }
        )
        },
        onSubmit(values) {
            this.errors = [];

            if (stateId.value == undefined || stateId.value == '') 
            {
                return 'Selecione um Estados';
            } 

            if (cityId.value == undefined || cityId.value == '') 
            {
                return 'Selecione uma Cidade';
            } 
            
            if (neighborhoodId.value == undefined || neighborhoodId.value == '') 
            {
                return 'Selecione um Bairro';
            } 

            localStorage.setItem("customerFirstName", values.name);
            localStorage.setItem("customerMiddleName", values.middleName);
            localStorage.setItem("customerSurName", values.surName);
            
            localStorage.setItem("customerZipCode",values.cepAddress);
            localStorage.setItem("customerStreet",values.address);
            localStorage.setItem("customerNumber", values.numberAddress);
            localStorage.setItem("customerComplement", values.complemento);
            localStorage.setItem("customerNeighborhoodId", neighborhoodId.value);
            localStorage.setItem("customerNeighborhoodName", neighborhoodName.value);
            localStorage.setItem("customerCityId", cityId.value);
            localStorage.setItem("customerCityName", cityName.value);
            localStorage.setItem("customerStateId", stateId.value);
            localStorage.setItem("customerStateName", stateName.value);
            localStorage.setItem("customerCountryId", this.countryId);
            localStorage.setItem("customerCountryName", this.countryName);
            
            //window.location.replace("/onboarding-verification");
            window.location.replace("/onboarding-identity");
        },
        ValidateName(value){
            this.errors = [];
            if (value === undefined && document.getElementById("name").value != '') {
                value = document.getElementById("name").value;
            }
            if (!value) {
                return 'O campo é obrigatório';
            }
            return true;
        },
        ValidateAddress(value){
            this.errors = [];
            if (value === undefined && document.getElementById("address").value != '') {
                value = document.getElementById("address").value;
            }

            this.errors = [];
            if (!value) {
                return 'O campo é obrigatório';
            }
            return true;
        },
        ValidateNumber(value){
            this.errors = [];
            if (value === undefined && document.getElementById("numberAddress").value != '') {
                value = document.getElementById("numberAddress").value;
            }

            this.errors = [];
            if (!value) {
                return 'O campo é obrigatório';
            }
            return true;
        },
        ValidateCEP(value){
            this.errors = [];
            if (value === undefined && document.getElementById("cepAddress").value != '') {
                value = document.getElementById("cepAddress").value;
            }

            this.errors = [];
            if (!value) {
                return 'O campo é obrigatório';
            }
            return true;
        },
        validateState(value){
            this.errors = [];
            if (value === undefined && document.getElementById("statesSearch").value != '') {
                value = document.getElementById("statesSearch").value;
            }

            this.errors = [];
            if (!value) {
                return 'O campo é obrigatório';
            }
            return true;
        }
    },

}
</script>
