<template>
    <div role="tabpanel" id="doc_overview" class="tab-pane fade show active">
        <div class="row">
            <div class="col-md-9">

                <!-- About Details -->
                <div class="widget about-widget">
                    <h4 class="widget-title">About Me</h4>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.</p>
                </div>
                <!-- /About Details -->


                <!-- Awards Details -->
                <div class="widget awards-widget">
                    <h4 class="widget-title">Awards</h4>
                    <div class="experience-box">
                        <ul class="experience-list">
                            <li>
                                <div class="experience-user">
                                    <div class="before-circle"></div>
                                </div>
                                <div class="experience-content">
                                    <div class="timeline-content">
                                        <p class="exp-year">July 2023</p>
                                        <h4 class="exp-title">Humanitarian Award</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                            Proin a ipsum tellus. Interdum et malesuada fames ac ante
                                            ipsum primis in faucibus.</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="experience-user">
                                    <div class="before-circle"></div>
                                </div>
                                <div class="experience-content">
                                    <div class="timeline-content">
                                        <p class="exp-year">March 2011</p>
                                        <h4 class="exp-title">Certificate for International Volunteer
                                            Service</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                            Proin a ipsum tellus. Interdum et malesuada fames ac ante
                                            ipsum primis in faucibus.</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="experience-user">
                                    <div class="before-circle"></div>
                                </div>
                                <div class="experience-content">
                                    <div class="timeline-content">
                                        <p class="exp-year">May 2008</p>
                                        <h4 class="exp-title">The Dental Professional of The Year Award
                                        </h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                            Proin a ipsum tellus. Interdum et malesuada fames ac ante
                                            ipsum primis in faucibus.</p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- /Awards Details -->

            </div>
        </div>
    </div>
</template>