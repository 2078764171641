<template>
  <div
    class="tab-pane fade show active"
    id="pills-upcoming"
    role="tabpanel"
    aria-labelledby="pills-upcoming-tab"
  >
    <div class="row">
      <!-- Appointment Grid -->
      <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
        <div class="appointment-wrap appointment-grid-wrap">
          <ul>
            <li>
              <div class="appointment-grid-head">
                <div class="patinet-information">
                  <router-link to="/patient-upcoming-appointment">
                    <img
                      src="@/assets/img/doctors/doctor-thumb-21.jpg"
                      alt="User Image"
                    />
                  </router-link>
                  <div class="patient-info">
                    <p>#Apt0001</p>
                    <h6>
                      <router-link to="/patient-upcoming-appointment"
                        >Dr Edalin Hendry</router-link
                      >
                    </h6>
                    <p class="visit">General Visit</p>
                  </div>
                </div>
                <div class="grid-user-msg">
                  <span class="video-icon"
                    ><a href="#"><i class="fa-solid fa-video"></i></a
                  ></span>
                </div>
              </div>
            </li>
            <li class="appointment-info">
              <p><i class="fa-solid fa-clock"></i>11 Nov 2024</p>
              <p><i class="fa-solid fa-clock"></i>10.45 AM</p>
            </li>
            <li class="appointment-action">
              <ul>
                <li>
                  <router-link to="/patient-upcoming-appointment"
                    ><i class="fa-solid fa-eye"></i
                  ></router-link>
                </li>
                <li>
                  <a href="#"><i class="fa-solid fa-comments"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa-solid fa-xmark"></i></a>
                </li>
              </ul>
              <div class="appointment-detail-btn">
                <a href="#" class="start-link"
                  ><i class="fa-solid fa-calendar-check me-1"></i>Attend</a
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- /Appointment Grid -->

      <!-- Appointment Grid -->
      <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
        <div class="appointment-wrap appointment-grid-wrap">
          <ul>
            <li>
              <div class="appointment-grid-head">
                <div class="patinet-information">
                  <router-link to="/patient-upcoming-appointment">
                    <img
                      src="@/assets/img/doctors/doctor-thumb-13.jpg"
                      alt="User Image"
                    />
                  </router-link>
                  <div class="patient-info">
                    <p>#Apt0002</p>
                    <h6>
                      <router-link to="/patient-upcoming-appointment"
                        >Dr.Shanta Nesmith</router-link
                      >
                    </h6>
                  </div>
                </div>
                <div class="grid-user-msg">
                  <span class="hospital-icon"
                    ><a href="#"><i class="fa-solid fa-hospital"></i></a
                  ></span>
                </div>
              </div>
            </li>
            <li class="appointment-info">
              <p><i class="fa-solid fa-clock"></i>05 Nov 2024</p>
              <p><i class="fa-solid fa-clock"></i>11.50 AM</p>
            </li>
            <li class="appointment-action">
              <ul>
                <li>
                  <router-link to="/patient-upcoming-appointment"
                    ><i class="fa-solid fa-eye"></i
                  ></router-link>
                </li>
                <li>
                  <a href="#"><i class="fa-solid fa-comments"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa-solid fa-xmark"></i></a>
                </li>
              </ul>
              <div class="appointment-detail-btn">
                <a href="#" class="start-link"
                  ><i class="fa-solid fa-calendar-check me-1"></i>Attend</a
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- /Appointment Grid -->

      <!-- Appointment Grid -->
      <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
        <div class="appointment-wrap appointment-grid-wrap">
          <ul>
            <li>
              <div class="appointment-grid-head">
                <div class="patinet-information">
                  <router-link to="/patient-upcoming-appointment">
                    <img
                      src="@/assets/img/doctors/doctor-thumb-14.jpg"
                      alt="User Image"
                    />
                  </router-link>
                  <div class="patient-info">
                    <p>#Apt0003</p>
                    <h6>
                      <router-link to="/patient-upcoming-appointment"
                        >Dr.John Ewel</router-link
                      >
                    </h6>
                  </div>
                </div>
                <div class="grid-user-msg">
                  <span class="telephone-icon"
                    ><a href="#"><i class="fa-solid fa-phone"></i></a
                  ></span>
                </div>
              </div>
            </li>
            <li class="appointment-info">
              <p><i class="fa-solid fa-clock"></i>27 Oct 2024</p>
              <p><i class="fa-solid fa-clock"></i>09.30 AM</p>
            </li>
            <li class="appointment-action">
              <ul>
                <li>
                  <router-link to="/patient-upcoming-appointment"
                    ><i class="fa-solid fa-eye"></i
                  ></router-link>
                </li>
                <li>
                  <a href="#"><i class="fa-solid fa-comments"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa-solid fa-xmark"></i></a>
                </li>
              </ul>
              <div class="appointment-detail-btn">
                <a href="#" class="start-link"
                  ><i class="fa-solid fa-calendar-check me-1"></i>Attend</a
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- /Appointment Grid -->

      <!-- Appointment Grid -->
      <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
        <div class="appointment-wrap appointment-grid-wrap">
          <ul>
            <li>
              <div class="appointment-grid-head">
                <div class="patinet-information">
                  <router-link to="/patient-upcoming-appointment">
                    <img
                      src="@/assets/img/doctors/doctor-thumb-15.jpg"
                      alt="User Image"
                    />
                  </router-link>
                  <div class="patient-info">
                    <p>#Apt0004</p>
                    <h6>
                      <router-link to="/patient-upcoming-appointment"
                        >Dr.Susan Fenimore</router-link
                      >
                    </h6>
                  </div>
                </div>
                <div class="grid-user-msg">
                  <span class="hospital-icon"
                    ><a href="#"><i class="fa-solid fa-hospital"></i></a
                  ></span>
                </div>
              </div>
            </li>
            <li class="appointment-info">
              <p><i class="fa-solid fa-clock"></i>18 Oct 2024</p>
              <p><i class="fa-solid fa-clock"></i>12.20 PM</p>
            </li>
            <li class="appointment-action">
              <ul>
                <li>
                  <router-link to="/patient-upcoming-appointment"
                    ><i class="fa-solid fa-eye"></i
                  ></router-link>
                </li>
                <li>
                  <a href="#"><i class="fa-solid fa-comments"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa-solid fa-xmark"></i></a>
                </li>
              </ul>
              <div class="appointment-detail-btn">
                <a href="#" class="start-link"
                  ><i class="fa-solid fa-calendar-check me-1"></i>Attend</a
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- /Appointment Grid -->

      <!-- Appointment Grid -->
      <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
        <div class="appointment-wrap appointment-grid-wrap">
          <ul>
            <li>
              <div class="appointment-grid-head">
                <div class="patinet-information">
                  <router-link to="/patient-upcoming-appointment">
                    <img
                      src="@/assets/img/doctors/doctor-thumb-16.jpg"
                      alt="User Image"
                    />
                  </router-link>
                  <div class="patient-info">
                    <p>#Apt0005</p>
                    <h6>
                      <router-link to="/patient-upcoming-appointment"
                        >Dr.Juliet Rios</router-link
                      >
                    </h6>
                  </div>
                </div>
                <div class="grid-user-msg">
                  <span class="video-icon"
                    ><a href="#"><i class="fa-solid fa-video"></i></a
                  ></span>
                </div>
              </div>
            </li>
            <li class="appointment-info">
              <p><i class="fa-solid fa-clock"></i>10 Oct 2024</p>
              <p><i class="fa-solid fa-clock"></i>11.30 AM</p>
            </li>
            <li class="appointment-action">
              <ul>
                <li>
                  <router-link to="/patient-upcoming-appointment"
                    ><i class="fa-solid fa-eye"></i
                  ></router-link>
                </li>
                <li>
                  <a href="#"><i class="fa-solid fa-comments"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa-solid fa-xmark"></i></a>
                </li>
              </ul>
              <div class="appointment-detail-btn">
                <a href="#" class="start-link"
                  ><i class="fa-solid fa-calendar-check me-1"></i>Attend</a
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- /Appointment Grid -->

      <!-- Appointment Grid -->
      <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
        <div class="appointment-wrap appointment-grid-wrap">
          <ul>
            <li>
              <div class="appointment-grid-head">
                <div class="patinet-information">
                  <router-link to="/patient-upcoming-appointment">
                    <img
                      src="@/assets/img/doctors/doctor-thumb-17.jpg"
                      alt="User Image"
                    />
                  </router-link>
                  <div class="patient-info">
                    <p>#Apt0006</p>
                    <h6>
                      <router-link to="/patient-upcoming-appointment"
                        >Dr.Joseph Engels</router-link
                      >
                    </h6>
                  </div>
                </div>
                <div class="grid-user-msg">
                  <span class="video-icon"
                    ><a href="#"><i class="fa-solid fa-video"></i></a
                  ></span>
                </div>
              </div>
            </li>
            <li class="appointment-info">
              <p><i class="fa-solid fa-clock"></i>26 Sep 2024</p>
              <p><i class="fa-solid fa-clock"></i>10.20 AM</p>
            </li>
            <li class="appointment-action">
              <ul>
                <li>
                  <router-link to="/patient-upcoming-appointment"
                    ><i class="fa-solid fa-eye"></i
                  ></router-link>
                </li>
                <li>
                  <a href="#"><i class="fa-solid fa-comments"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa-solid fa-xmark"></i></a>
                </li>
              </ul>
              <div class="appointment-detail-btn">
                <a href="#" class="start-link"
                  ><i class="fa-solid fa-calendar-check me-1"></i>Attend</a
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- /Appointment Grid -->

      <!-- Appointment Grid -->
      <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
        <div class="appointment-wrap appointment-grid-wrap">
          <ul>
            <li>
              <div class="appointment-grid-head">
                <div class="patinet-information">
                  <router-link to="/patient-upcoming-appointment">
                    <img
                      src="@/assets/img/doctors/doctor-thumb-18.jpg"
                      alt="User Image"
                    />
                  </router-link>
                  <div class="patient-info">
                    <p>#Apt0007</p>
                    <h6>
                      <router-link to="/patient-upcoming-appointment"
                        >Dr.Victoria Selzer</router-link
                      >
                    </h6>
                  </div>
                </div>
                <div class="grid-user-msg">
                  <span class="video-icon"
                    ><a href="#"><i class="fa-solid fa-video"></i></a
                  ></span>
                </div>
              </div>
            </li>
            <li class="appointment-info">
              <p><i class="fa-solid fa-clock"></i>14 Sep 2024</p>
              <p><i class="fa-solid fa-clock"></i>08.10 AM</p>
            </li>
            <li class="appointment-action">
              <ul>
                <li>
                  <router-link to="/patient-upcoming-appointment"
                    ><i class="fa-solid fa-eye"></i
                  ></router-link>
                </li>
                <li>
                  <a href="#"><i class="fa-solid fa-comments"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa-solid fa-xmark"></i></a>
                </li>
              </ul>
              <div class="appointment-detail-btn">
                <a href="#" class="start-link"
                  ><i class="fa-solid fa-calendar-check me-1"></i>Attend</a
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- /Appointment Grid -->

      <!-- Appointment Grid -->
      <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
        <div class="appointment-wrap appointment-grid-wrap">
          <ul>
            <li>
              <div class="appointment-grid-head">
                <div class="patinet-information">
                  <router-link to="/patient-upcoming-appointment">
                    <img
                      src="@/assets/img/doctors/doctor-thumb-19.jpg"
                      alt="User Image"
                    />
                  </router-link>
                  <div class="patient-info">
                    <p>#Apt0008</p>
                    <h6>
                      <router-link to="/patient-upcoming-appointment"
                        >Dr.Benjamin Hedge</router-link
                      >
                    </h6>
                  </div>
                </div>
                <div class="grid-user-msg">
                  <span class="video-icon"
                    ><a href="#"><i class="fa-solid fa-video"></i></a
                  ></span>
                </div>
              </div>
            </li>
            <li class="appointment-info">
              <p><i class="fa-solid fa-clock"></i>03 Sep 2024</p>
              <p><i class="fa-solid fa-clock"></i>06.00 PM</p>
            </li>
            <li class="appointment-action">
              <ul>
                <li>
                  <router-link to="/patient-upcoming-appointment"
                    ><i class="fa-solid fa-eye"></i
                  ></router-link>
                </li>
                <li>
                  <a href="#"><i class="fa-solid fa-comments"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa-solid fa-xmark"></i></a>
                </li>
              </ul>
              <div class="appointment-detail-btn">
                <a href="#" class="start-link"
                  ><i class="fa-solid fa-calendar-check me-1"></i>Attend</a
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- /Appointment Grid -->

      <!-- Appointment Grid -->
      <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
        <div class="appointment-wrap appointment-grid-wrap">
          <ul>
            <li>
              <div class="appointment-grid-head">
                <div class="patinet-information">
                  <router-link to="/patient-upcoming-appointment">
                    <img
                      src="@/assets/img/doctors/doctor-thumb-21.jpg"
                      alt="User Image"
                    />
                  </router-link>
                  <div class="patient-info">
                    <p>#Apt0009</p>
                    <h6>
                      <router-link to="/patient-upcoming-appointment"
                        >Dr.Kristina Lepley</router-link
                      >
                    </h6>
                  </div>
                </div>
                <div class="grid-user-msg">
                  <span class="video-icon"
                    ><a href="#"><i class="fa-solid fa-video"></i></a
                  ></span>
                </div>
              </div>
            </li>
            <li class="appointment-info">
              <p><i class="fa-solid fa-clock"></i>25 Aug 2024</p>
              <p><i class="fa-solid fa-clock"></i>10.45 AM</p>
            </li>
            <li class="appointment-action">
              <ul>
                <li>
                  <router-link to="/patient-upcoming-appointment"
                    ><i class="fa-solid fa-eye"></i
                  ></router-link>
                </li>
                <li>
                  <a href="#"><i class="fa-solid fa-comments"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa-solid fa-xmark"></i></a>
                </li>
              </ul>
              <div class="appointment-detail-btn">
                <a href="#" class="start-link"
                  ><i class="fa-solid fa-calendar-check me-1"></i>Attend</a
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- /Appointment Grid -->

      <div class="col-md-12">
        <div class="loader-item text-center">
          <a href="javascript:void(0);" class="btn btn-load">Load More</a>
        </div>
      </div>
    </div>
  </div>
</template>
