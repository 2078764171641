<template>
  <div
    class="tab-pane fade"
    id="pills-cancel"
    role="tabpanel"
    aria-labelledby="pills-cancel-tab"
  >
    <div class="row">
      <!-- Appointment Grid -->
      <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
        <div class="appointment-wrap appointment-grid-wrap">
          <ul>
            <li>
              <div class="appointment-grid-head">
                <div class="patinet-information">
                  <router-link to="/patient-cancelled-appointment">
                    <img
                      src="@/assets/img/doctors/doctor-thumb-21.jpg"
                      alt="User Image"
                    />
                  </router-link>
                  <div class="patient-info">
                    <p>#Apt0001</p>
                    <h6>
                      <router-link to="/patient-cancelled-appointment"
                        >Dr Edalin Hendry</router-link
                      >
                    </h6>
                    <p class="visit">General Visit</p>
                  </div>
                </div>
                <div class="grid-user-msg">
                  <span class="video-icon"
                    ><a href="#"><i class="fa-solid fa-video"></i></a
                  ></span>
                </div>
              </div>
            </li>
            <li class="appointment-info">
              <p><i class="fa-solid fa-clock"></i>11 Nov 2024</p>
              <p><i class="fa-solid fa-clock"></i>10.45 AM</p>
            </li>
            <li class="appointment-detail-btn">
              <router-link
                to="/patient-cancelled-appointment"
                class="start-link w-100"
                >View Details</router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- /Appointment Grid -->

      <!-- Appointment Grid -->
      <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
        <div class="appointment-wrap appointment-grid-wrap">
          <ul>
            <li>
              <div class="appointment-grid-head">
                <div class="patinet-information">
                  <router-link to="/patient-cancelled-appointment">
                    <img
                      src="@/assets/img/doctors/doctor-thumb-13.jpg"
                      alt="User Image"
                    />
                  </router-link>
                  <div class="patient-info">
                    <p>#Apt0002</p>
                    <h6>
                      <router-link to="/patient-cancelled-appointment"
                        >Dr.Shanta Nesmith</router-link
                      >
                    </h6>
                    <p class="visit">General Visit</p>
                  </div>
                </div>
                <div class="grid-user-msg">
                  <span class="video-icon"
                    ><a href="#"><i class="fa-solid fa-video"></i></a
                  ></span>
                </div>
              </div>
            </li>
            <li class="appointment-info">
              <p><i class="fa-solid fa-clock"></i>05 Nov 2024</p>
              <p><i class="fa-solid fa-clock"></i>11.50 AM</p>
            </li>
            <li class="appointment-detail-btn">
              <router-link
                to="/patient-cancelled-appointment"
                class="start-link w-100"
                >View Details</router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- /Appointment Grid -->

      <!-- Appointment Grid -->
      <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
        <div class="appointment-wrap appointment-grid-wrap">
          <ul>
            <li>
              <div class="appointment-grid-head">
                <div class="patinet-information">
                  <router-link to="/patient-cancelled-appointment">
                    <img
                      src="@/assets/img/doctors/doctor-thumb-14.jpg"
                      alt="User Image"
                    />
                  </router-link>
                  <div class="patient-info">
                    <p>#Apt0003</p>
                    <h6>
                      <router-link to="/patient-cancelled-appointment"
                        >Dr.John Ewel</router-link
                      >
                    </h6>
                    <p class="visit">General Visit</p>
                  </div>
                </div>
                <div class="grid-user-msg">
                  <span class="video-icon"
                    ><a href="#"><i class="fa-solid fa-video"></i></a
                  ></span>
                </div>
              </div>
            </li>
            <li class="appointment-info">
              <p><i class="fa-solid fa-clock"></i>27 Oct 2024</p>
              <p><i class="fa-solid fa-clock"></i>09.30 AM</p>
            </li>
            <li class="appointment-detail-btn">
              <router-link
                to="/patient-cancelled-appointment"
                class="start-link w-100"
                >View Details</router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- /Appointment Grid -->

      <!-- Appointment Grid -->
      <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
        <div class="appointment-wrap appointment-grid-wrap">
          <ul>
            <li>
              <div class="appointment-grid-head">
                <div class="patinet-information">
                  <router-link to="/patient-cancelled-appointment">
                    <img
                      src="@/assets/img/doctors/doctor-thumb-15.jpg"
                      alt="User Image"
                    />
                  </router-link>
                  <div class="patient-info">
                    <p>#Apt0004</p>
                    <h6>
                      <router-link to="/patient-cancelled-appointment"
                        >Dr.Susan Fenimore</router-link
                      >
                    </h6>
                    <p class="visit">General Visit</p>
                  </div>
                </div>
                <div class="grid-user-msg">
                  <span class="hospital-icon"
                    ><a href="#"><i class="fa-solid fa-hospital"></i></a
                  ></span>
                </div>
              </div>
            </li>
            <li class="appointment-info">
              <p><i class="fa-solid fa-clock"></i>18 Oct 2024</p>
              <p><i class="fa-solid fa-clock"></i>12.20 PM</p>
            </li>
            <li class="appointment-detail-btn">
              <router-link
                to="/patient-cancelled-appointment"
                class="start-link w-100"
                >View Details</router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- /Appointment Grid -->

      <!-- Appointment Grid -->
      <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
        <div class="appointment-wrap appointment-grid-wrap">
          <ul>
            <li>
              <div class="appointment-grid-head">
                <div class="patinet-information">
                  <router-link to="/patient-cancelled-appointment">
                    <img
                      src="@/assets/img/doctors/doctor-thumb-16.jpg"
                      alt="User Image"
                    />
                  </router-link>
                  <div class="patient-info">
                    <p>#Apt0005</p>
                    <h6>
                      <router-link to="/patient-cancelled-appointment"
                        >Dr.Juliet Rios</router-link
                      >
                    </h6>
                    <p class="visit">General Visit</p>
                  </div>
                </div>
                <div class="grid-user-msg">
                  <span class="video-icon"
                    ><a href="#"><i class="fa-solid fa-video"></i></a
                  ></span>
                </div>
              </div>
            </li>
            <li class="appointment-info">
              <p><i class="fa-solid fa-clock"></i>10 Oct 2024</p>
              <p><i class="fa-solid fa-clock"></i>11.30 AM</p>
            </li>
            <li class="appointment-detail-btn">
              <router-link
                to="/patient-cancelled-appointment"
                class="start-link w-100"
                >View Details</router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- /Appointment Grid -->

      <!-- Appointment Grid -->
      <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
        <div class="appointment-wrap appointment-grid-wrap">
          <ul>
            <li>
              <div class="appointment-grid-head">
                <div class="patinet-information">
                  <router-link to="/patient-cancelled-appointment">
                    <img
                      src="@/assets/img/doctors/doctor-thumb-17.jpg"
                      alt="User Image"
                    />
                  </router-link>
                  <div class="patient-info">
                    <p>#Apt0006</p>
                    <h6>
                      <router-link to="/patient-cancelled-appointment"
                        >Dr.Joseph Engels</router-link
                      >
                    </h6>
                    <p class="visit">General Visit</p>
                  </div>
                </div>
                <div class="grid-user-msg">
                  <span class="video-icon"
                    ><a href="#"><i class="fa-solid fa-video"></i></a
                  ></span>
                </div>
              </div>
            </li>
            <li class="appointment-info">
              <p><i class="fa-solid fa-clock"></i>26 Sep 2024</p>
              <p><i class="fa-solid fa-clock"></i>10.20 AM</p>
            </li>
            <li class="appointment-detail-btn">
              <router-link
                to="/patient-cancelled-appointment"
                class="start-link w-100"
                >View Details</router-link
              >
            </li>
          </ul>
        </div>
      </div>
      <!-- /Appointment Grid -->

      <div class="col-md-12">
        <div class="loader-item text-center">
          <a href="javascript:void(0);" class="btn btn-load">Load More</a>
        </div>
      </div>
    </div>
  </div>
</template>
