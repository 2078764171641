<template>
    <!-- Footer Section -->
    <footer class="footer pharmacy-footer">
        <div class="container">
            <div class="top-footer">
                <div class="footer-logo">
                    <router-link to="/"><img src="@/assets/img/logo.png" alt="logo"></router-link>
                </div>
                <div class="doccure-info">
                    <p>Doccure is one of India’s most trusted pharmacies, dispensing quality medicines
                        at reasonable prices to over 9 million happy customers
                    </p>
                </div>
            </div>
            <div class="mid-footer">
                <div class="row">
                    <div class="col-xl-2 col-lg-3 col-md-4">
                        <div class="footer-links">
                            <h4>Company</h4>
                            <ul class="pages-links">
                                <li><router-link to="/about-us">About Doccure</router-link></li>
                                <li><a href="javascript:void(0);">Customers Speak</a></li>
                                <li><a href="javascript:void(0);">In the News</a></li>
                                <li><a href="javascript:void(0);">Career</a></li>
                                <li><router-link to="/contato">Contact</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-4">
                        <div class="footer-links">
                            <h4>Shopping</h4>
                            <ul class="pages-links">
                                <li><a href="javascript:void(0);">Browse by A-Z</a></li>
                                <li><a href="javascript:void(0);">Browse by Manufacturers</a></li>
                                <li><a href="javascript:void(0);">Health Articles</a></li>
                                <li><a href="javascript:void(0);">Offers / Coupons</a></li>
                                <li><router-link to="/faq">FAQs</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-4">
                        <div class="footer-links">
                            <h4>Our Policies</h4>
                            <ul class="pages-links">
                                <li><router-link to="/terms-condition">Terms and Conditions</router-link></li>
                                <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                                <li><a href="javascript:void(0);">Fees and Payments</a></li>
                                <li><a href="javascript:void(0);">Shipping and Delivery</a></li>
                                <li><a href="javascript:void(0);">Return, Refund </a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-3 col-md-4">
                        <div class="footer-links">
                            <h4>Our Services</h4>
                            <ul class="pages-links">
                                <li><a href="javascript:void(0);">Order Medicines</a></li>
                                <li><a href="javascript:void(0);">Book Lab Tests</a></li>
                                <li><a href="javascript:void(0);">Consult a Doctor</a></li>
                                <li><a href="javascript:void(0);">Ayurveda Articles</a></li>
                                <li><a href="javascript:void(0);">Careers</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-8">
                        <div class="footer-links">
                            <h4>Subscribe to Newsletter</h4>
                            <form action="javascript:void(0)">
                                <div class="input-block">
                                    <input type="email" class="form-control" placeholder="Enter Email Address">
                                    <button type="submit" class="submit-btn">Submit</button>
                                </div>
                            </form>
                            <div class="app-store-links">
                                <a href="javascript:void(0);"><img src="@/assets/img/icons/app-store.svg" alt="Img"></a>
                                <a href="javascript:void(0);"><img src="@/assets/img/icons/google-play.svg" alt="Img"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mid-foot-two">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <ul class="payment-methods d-flex align-items-center">
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="@/assets/img/icons/payment-icon-01.svg" alt="Img">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="@/assets/img/icons/payment-icon-02.svg" alt="Img">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="@/assets/img/icons/payment-icon-03.svg" alt="Img">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="@/assets/img/icons/payment-icon-04.svg" alt="Img">
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <ul class="social-icons d-flex align-items-center">
                            <li><a href="#" target="_blank"><i class="fa-brands fa-facebook-f"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fa-brands fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fa-brands fa-linkedin-in"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fa-brands fa-instagram"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fa-brands fa-dribbble"></i></a></li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
        <div class="footer-bottom">
            <div class="copy-right text-center">
                <p>Copyright © {{ new Date().getFullYear() }} Doccure. All Rights Reserved</p>
            </div>
        </div>
    </footer>
    <!-- /Footer Section -->
</template>

