<template>
  <div class="left-panel">
    <div class="onboarding-logo text-center">
      <router-link to="/"><img src="@/assets/img/logo-light.png" class="img-fluid" alt="saascare-logo" /></router-link>
    </div>
    <div class="onboard-img">
      <img src="@/assets/img/onboard-img/onb-slide-img.png" class="img-fluid" alt="onboard-slider" />
    </div>
    <div class="onboarding-slider">
      <!-- Slider -->

      <div id="onboard-slider" class="owl-carousel owl-theme onboard-slider slider">
        <!-- Slider Item -->
        <Carousel :autoplay="10000" :wrap-around="true">
          <Slide v-for="slide in slides" :key="slide.title">
            <div class="onboard-item text-center">
              <div class="onboard-content">
                <h3>{{ slide.title }}</h3>
                <p>
                  {{ slide.description }}
                </p>
              </div>

            </div>
          </Slide>
          <template #addons>
            <Pagination />
          </template>
        </Carousel>
        <!-- /Slider Item -->
      </div>

      <!-- /Slider -->
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import { Carousel, Pagination, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";


const slides = [{ title: 'Bem vindo(a) ao SaaSCare', description: 'Para melhor experiência no SaaSCare, seu cadastro é essencial.' }, { title: 'Nos preocupamos com sua segurança', description: 'Realizaremos algumas validações durante seu cadastro.' }];

export default defineComponent({
  data() {
    return {
      slides    
    };
  },
  name: "Autoplay",
  components: {
    Carousel,
    Slide,
    Pagination,
  },
});
</script>
