<template>
  <div class="how-it-work-fourteen">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xl-5 col-lg-4">
          <div class="section-work-head">
            <span>Book best caregiver</span>
            <h2>How it works & Booking</h2>
          </div>
        </div>
        <div class="col-xl-7 col-lg-8">
          <ul class="work-flow-chart">
            <li>
              <img src="@/assets/img/bg/chart-arrow-01.png" alt="Img" />
              <div class="flow-chart-list">
                <span class="chart-icon"
                  ><img src="@/assets/img/icons/flow-chart-icon-01.svg" alt="Img"
                /></span>
                <h6>Location</h6>
                <span class="chart-count">01</span>
              </div>
            </li>
            <li>
              <img src="@/assets/img/bg/chart-arrow-01.png" alt="Img" />
              <div class="flow-chart-list bg-yelllow">
                <span class="chart-icon"
                  ><img src="@/assets/img/icons/flow-chart-icon-02.svg" alt="Img"
                /></span>
                <h6>Booking</h6>
                <span class="chart-count">02</span>
              </div>
            </li>
            <li>
              <img src="@/assets/img/bg/chart-arrow-01.png" alt="Img" />
              <div class="flow-chart-list">
                <span class="chart-icon"
                  ><img src="@/assets/img/icons/flow-chart-icon-03.svg" alt="Img"
                /></span>
                <h6>Caregiver</h6>
                <span class="chart-count">03</span>
              </div>
            </li>
            <li>
              <img src="@/assets/img/bg/chart-arrow-02.png" alt="Img" />
              <div class="flow-chart-list bg-yelllow">
                <span class="chart-icon"
                  ><img src="@/assets/img/icons/flow-chart-icon-04.svg" alt="Img"
                /></span>
                <h6>Takecare</h6>
                <span class="chart-count">04</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
