<template>
    <!-- Onboarding Email -->
    <div class="onboard-wrapper">

        <onboarding-professional-slider></onboarding-professional-slider>

        <div class="right-panel">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 p-0">
                        <div class="right-panel-title text-center">
                            <router-link to="/"> <img src="@/assets/img/logo-light.png"
                                    alt="saascare-logo"></router-link>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <onboarding-professional-sidebar></onboarding-professional-sidebar>
                    </div>
                    <div class="col-lg-8 col-md-12">
                        <div class="onboarding-content-box content-wrap">
                            <div class="onborad-set">
                                <div class="onboarding-title">
                                    <h2>Qual seu e-mail principal?</h2>
                                    <h6>Usaremos ele apenas para informações importantes.</h6>
                                </div>
                                <div class="onboarding-content">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="mb-3">
                                                <Form :validation-schema="schema" @submit="onSubmit" id="formEmail">
                                                    <div>
                                                        <label class="focus-label">E-mail<span>*</span></label>
                                                        <Field name="email" type="email"
                                                            :rules="validateEmailProfessional"
                                                            class="form-control floating mail-icon" />
                                                        <ErrorMessage name="email" />
                                                    </div>
                                                    <!-- Campos Password -->
                                                    <div>
                                                        <label class="focus-label"
                                                            for="password">Senha<span>*</span></label>
                                                        <Field name="password" id="password" v-model="password"
                                                            v-on:click="switchVisibility" :type="passwordFieldType"
                                                            :rules="validatePassword"
                                                            class="form-control floating pass-icon" />
                                                        <ErrorMessage name="password" />
                                                    </div>
                                                    <div>
                                                        <label class="focus-label">Confirmação de
                                                            Senha<span>*</span></label>
                                                        <Field name="passwordconfirm" id="passwordconfirm"
                                                            v-on:click="switchVisibilityOnConfirm"
                                                            :type="passwordConfirmFieldType"
                                                            :rules="validateConfirmPassword" v-model="passwordConfirm"
                                                            class="form-control floating pass-icon-1 " />
                                                        <ErrorMessage name="passwordconfirm" />
                                                        <p v-if="errors.length">
                                                        <ul>
                                                            <li v-for="error in errors">{{ error }}</li>
                                                        </ul>
                                                        </p>
                                                    </div>
                                                    <!-- Campos Password -->
                                                    <div class="onboarding-btn Personalize-btn">
                                                        <button type="submit" form="formEmail">Continuar</button>
                                                    </div>
                                                    <p>
                                                        <br>A senha deve ter no mínimo de 8 letras e conter ao
                                                        menos:</br>
                                                        <br>- 1 letra maiúscula </br>
                                                        <br>- 1 letra minúscula </br>
                                                        <br>- 1 número </br>
                                                        <br>- 1 carácter especial </br>
                                                    </p>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Onboarding Email -->
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import { Form, Field, ErrorMessage } from 'vee-validate';
export default {
    data() {
        return {
            Euro: ["Euro", "INR", "Dinar", "ring"],
            errors: [],
            password: "",
            passwordFieldType: "password",
            passwordConfirm: "",
            passwordConfirmFieldType: "password",
        }
    },
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    methods: {
        onSubmit(values) {
            axios.get('/BasicSearches/emailExists/' + values.email)
                .then(async (response) => {
                    if (response.data.data == true) {
                        this.errors = [];
                        this.errors.push('E-mail já cadastrado');
                        return 'E-mail já cadastrado!';
                    } else {
                        await axios.get(`/BasicSearches/generateEmailConfirmation/${values.email}`);
                        localStorage.setItem("emailValidation", values.email);

                        if (values.password === undefined && document.getElementById("password").value != '') {
                            values.password = document.getElementById("password").value;
                        }

                        localStorage.setItem("professionalPassword", values.password);
                        localStorage.setItem("professionalUsername", values.email);
                        localStorage.setItem("professionalEmail", values.email);

                        window.location.replace("onboarding-professional-email-otp");
                    }
                });
        },
        validateEmailProfessional(value) {
            // if the field is empty
            this.errors = [];
            if (!value) {
                return 'O campo é obrigatório';
            }
            // if the field is not a valid email
            const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
            if (!regex.test(value)) {
                return 'Informe um e-mail válido';
            }
            return true;
        },
        validatePassword(value) {
            // if the field is empty

            if (value === undefined && document.getElementById("password").value != '') {
                value = document.getElementById("password").value;
            }

            this.errors = [];
            if (!value) {
                //this.errors.push('O campo é obrigatório');
                return 'O campo é obrigatório';
            }
            // if the field is not a valid email
            const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;
            if (!regex.test(value)) {
                return 'Informe um senha válida.';
            }
            return true;
        },
        validateConfirmPassword(value) {
            // if the field is empty
            if (value === undefined && document.getElementById("passwordconfirm").value != '') {
                value = document.getElementById("passwordconfirm").value;
            }

            this.errors = [];
            if (!value) {
                return 'O campo é obrigatório.';
            }
            // if the field is not a valid email

            if (document.getElementById("password").value != undefined && document.getElementById("password").value != value) {
                return 'A confirmação de senha deve ser igual a senha.';
            }
            return true;
        },
        switchVisibility() {
            this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
        },
        switchVisibilityOnConfirm() {
            this.passwordConfirmFieldType = this.passwordConfirmFieldType === "password" ? "text" : "password";
        },
    }
    //Fim do Post
}
</script>