<template>
    <div class="row">
        <!-- Appointment Grid -->
        <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
            <div class="appointment-wrap appointment-grid-wrap">
                <ul>
                    <li>
                        <div class="appointment-grid-head">
                            <div class="patinet-information">
                                <router-link to="/doctor-completed-appointment">
                                    <img src="@/assets/img/doctors-dashboard/profile-01.jpg" alt="User Image">
                                </router-link>
                                <div class="patient-info">
                                    <p>#Apt0001</p>
                                    <h6><router-link to="/doctor-completed-appointment">Adrian</router-link></h6>
                                </div>
                            </div>
                            <div class="grid-user-msg">
                                <span class="video-icon"><a href="#"><i class="fa-solid fa-video"></i></a></span>
                            </div>
                        </div>
                    </li>
                    <li class="appointment-info">
                        <p><i class="fa-solid fa-clock"></i>11 Nov 2024 10.45 AM</p>
                        <ul class="d-flex apponitment-types">
                            <li>General Visit</li>
                        </ul>
                    </li>
                    <li class="appointment-detail-btn">
                        <router-link to="/doctor-completed-appointment" class="start-link w-100">View Details</router-link>
                    </li>
                </ul>
            </div>
        </div>
        <!-- /Appointment Grid -->

        <!-- Appointment Grid -->
        <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
            <div class="appointment-wrap appointment-grid-wrap">
                <ul>
                    <li>
                        <div class="appointment-grid-head">
                            <div class="patinet-information">
                                <router-link to="/doctor-completed-appointment">
                                    <img src="@/assets/img/doctors-dashboard/profile-02.jpg" alt="User Image">
                                </router-link>
                                <div class="patient-info">
                                    <p>#Apt0002</p>
                                    <h6><router-link to="/doctor-completed-appointment">Kelly Stevens</router-link><span
                                            class="badge new-tag">New</span></h6>
                                </div>
                            </div>
                            <div class="grid-user-msg">
                                <span class="hospital-icon"><a href="#"><i class="fa-solid fa-hospital"></i></a></span>
                            </div>
                        </div>
                    </li>
                    <li class="appointment-info">
                        <p><i class="fa-solid fa-clock"></i>05 Nov 2024 11.50 AM</p>
                        <ul class="d-flex apponitment-types">
                            <li>General Visit</li>
                        </ul>
                    </li>
                    <li class="appointment-detail-btn">
                        <router-link to="/doctor-completed-appointment" class="start-link w-100">View Details</router-link>
                    </li>
                </ul>
            </div>
        </div>
        <!-- /Appointment Grid -->

        <!-- Appointment Grid -->
        <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
            <div class="appointment-wrap appointment-grid-wrap">
                <ul>
                    <li>
                        <div class="appointment-grid-head">
                            <div class="patinet-information">
                                <router-link to="/doctor-completed-appointment">
                                    <img src="@/assets/img/doctors-dashboard/profile-03.jpg" alt="User Image">
                                </router-link>
                                <div class="patient-info">
                                    <p>#Apt0003</p>
                                    <h6><router-link to="/doctor-completed-appointment">Samuel James</router-link></h6>
                                </div>
                            </div>
                            <div class="grid-user-msg">
                                <span class="telephone-icon"><a href="#"><i class="fa-solid fa-phone"></i></a></span>
                            </div>
                        </div>
                    </li>
                    <li class="appointment-info">
                        <p><i class="fa-solid fa-clock"></i>27 Oct 2024 09.30 AM</p>
                        <ul class="d-flex apponitment-types">
                            <li>General Visit</li>
                        </ul>
                    </li>
                    <li class="appointment-detail-btn">
                        <router-link to="/doctor-completed-appointment" class="start-link w-100">View Details</router-link>
                    </li>
                </ul>
            </div>
        </div>
        <!-- /Appointment Grid -->

        <!-- Appointment Grid -->
        <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
            <div class="appointment-wrap appointment-grid-wrap">
                <ul>
                    <li>
                        <div class="appointment-grid-head">
                            <div class="patinet-information">
                                <router-link to="/doctor-completed-appointment">
                                    <img src="@/assets/img/doctors-dashboard/profile-04.jpg" alt="User Image">
                                </router-link>
                                <div class="patient-info">
                                    <p>#Apt0004</p>
                                    <h6><router-link to="/doctor-completed-appointment">Catherine Gracey</router-link></h6>
                                </div>
                            </div>
                            <div class="grid-user-msg">
                                <span class="hospital-icon"><a href="#"><i class="fa-solid fa-hospital"></i></a></span>
                            </div>
                        </div>
                    </li>
                    <li class="appointment-info">
                        <p><i class="fa-solid fa-clock"></i>18 Oct 2024 12.20 PM</p>
                        <ul class="d-flex apponitment-types">
                            <li>General Visit</li>
                        </ul>
                    </li>
                    <li class="appointment-detail-btn">
                        <router-link to="/doctor-completed-appointment" class="start-link w-100">View Details</router-link>
                    </li>
                </ul>
            </div>
        </div>
        <!-- /Appointment Grid -->

        <!-- Appointment Grid -->
        <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
            <div class="appointment-wrap appointment-grid-wrap">
                <ul>
                    <li>
                        <div class="appointment-grid-head">
                            <div class="patinet-information">
                                <router-link to="/doctor-completed-appointment">
                                    <img src="@/assets/img/doctors-dashboard/profile-05.jpg" alt="User Image">
                                </router-link>
                                <div class="patient-info">
                                    <p>#Apt0005</p>
                                    <h6><router-link to="/doctor-completed-appointment">Robert Miller</router-link></h6>
                                </div>
                            </div>
                            <div class="grid-user-msg">
                                <span class="video-icon"><a href="#"><i class="fa-solid fa-video"></i></a></span>
                            </div>
                        </div>
                    </li>
                    <li class="appointment-info">
                        <p><i class="fa-solid fa-clock"></i>10 Oct 2024 11.30 AM</p>
                        <ul class="d-flex apponitment-types">
                            <li>General Visit</li>
                        </ul>
                    </li>
                    <li class="appointment-detail-btn">
                        <router-link to="/doctor-completed-appointment" class="start-link w-100">View Details</router-link>
                    </li>
                </ul>
            </div>
        </div>
        <!-- /Appointment Grid -->

        <!-- Appointment Grid -->
        <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
            <div class="appointment-wrap appointment-grid-wrap">
                <ul>
                    <li>
                        <div class="appointment-grid-head">
                            <div class="patinet-information">
                                <router-link to="/doctor-completed-appointment">
                                    <img src="@/assets/img/doctors-dashboard/profile-06.jpg" alt="User Image">
                                </router-link>
                                <div class="patient-info">
                                    <p>#Apt0006</p>
                                    <h6><router-link to="/doctor-completed-appointment">Anderea Kearns</router-link></h6>
                                </div>
                            </div>
                            <div class="grid-user-msg">
                                <span class="video-icon"><a href="#"><i class="fa-solid fa-video"></i></a></span>
                            </div>
                        </div>
                    </li>
                    <li class="appointment-info">
                        <p><i class="fa-solid fa-clock"></i>26 Sep 2024 10.20 AM</p>
                        <ul class="d-flex apponitment-types">
                            <li>General Visit</li>
                        </ul>
                    </li>
                    <li class="appointment-detail-btn">
                        <router-link to="/doctor-completed-appointment" class="start-link w-100">View Details</router-link>
                    </li>
                </ul>
            </div>
        </div>
        <!-- /Appointment Grid -->

        <!-- Appointment Grid -->
        <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
            <div class="appointment-wrap appointment-grid-wrap">
                <ul>
                    <li>
                        <div class="appointment-grid-head">
                            <div class="patinet-information">
                                <router-link to="/patient-profile">
                                    <img src="@/assets/img/doctors-dashboard/profile-07.jpg" alt="User Image">
                                </router-link>
                                <div class="patient-info">
                                    <p>#Apt0007</p>
                                    <h6><router-link to="/patient-profile">Peter Anderson</router-link></h6>
                                </div>
                            </div>
                            <div class="grid-user-msg">
                                <span class="video-icon"><a href="#"><i class="fa-solid fa-video"></i></a></span>
                            </div>
                        </div>
                    </li>
                    <li class="appointment-info">
                        <p><i class="fa-solid fa-clock"></i>14 Sep 2024 08.10 AM</p>
                        <ul class="d-flex apponitment-types">
                            <li>General Visit</li>
                        </ul>
                    </li>
                    <li class="appointment-detail-btn">
                        <router-link to="/doctor-appointment-details" class="start-link w-100">View Details</router-link>
                    </li>
                </ul>
            </div>
        </div>
        <!-- /Appointment Grid -->

        <!-- Appointment Grid -->
        <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
            <div class="appointment-wrap appointment-grid-wrap">
                <ul>
                    <li>
                        <div class="appointment-grid-head">
                            <div class="patinet-information">
                                <router-link to="/patient-profile">
                                    <img src="@/assets/img/doctors-dashboard/profile-08.jpg" alt="User Image">
                                </router-link>
                                <div class="patient-info">
                                    <p>#Apt0008</p>
                                    <h6><router-link to="/patient-profile">Emily Musick</router-link></h6>
                                </div>
                            </div>
                            <div class="grid-user-msg">
                                <span class="video-icon"><a href="#"><i class="fa-solid fa-video"></i></a></span>
                            </div>
                        </div>
                    </li>
                    <li class="appointment-info">
                        <p><i class="fa-solid fa-clock"></i>03 Sep 2024 06.00 PM</p>
                        <ul class="d-flex apponitment-types">
                            <li>General Visit</li>
                        </ul>
                    </li>
                    <li class="appointment-detail-btn">
                        <router-link to="/doctor-appointment-details" class="start-link w-100">View Details</router-link>
                    </li>
                </ul>
            </div>
        </div>
        <!-- /Appointment Grid -->

        <!-- Appointment Grid -->
        <div class="col-xl-4 col-lg-6 col-md-12 d-flex">
            <div class="appointment-wrap appointment-grid-wrap">
                <ul>
                    <li>
                        <div class="appointment-grid-head">
                            <div class="patinet-information">
                                <router-link to="/patient-profile">
                                    <img src="@/assets/img/doctors-dashboard/profile-01.jpg" alt="User Image">
                                </router-link>
                                <div class="patient-info">
                                    <p>#Apt0009</p>
                                    <h6><router-link to="/patient-profile">Darrell Tan</router-link></h6>
                                </div>
                            </div>
                            <div class="grid-user-msg">
                                <span class="video-icon"><a href="#"><i class="fa-solid fa-video"></i></a></span>
                            </div>
                        </div>
                    </li>
                    <li class="appointment-info">
                        <p><i class="fa-solid fa-clock"></i>25 Aug 2024 10.45 AM</p>
                        <ul class="d-flex apponitment-types">
                            <li>General Visit</li>
                        </ul>
                    </li>
                    <li class="appointment-detail-btn">
                        <router-link to="/doctor-appointment-details" class="start-link w-100">View Details</router-link>
                    </li>
                </ul>
            </div>
        </div>
        <!-- /Appointment Grid -->

        <div class="col-md-12">
            <div class="loader-item text-center">
                <a href="javascript:void(0);" class="btn btn-load">Load More</a>
            </div>
        </div>

    </div>
</template>