import { createRouter, createWebHistory } from "vue-router";

//import Index from '@/views/frontend/pages/home/index.vue'

import IndexThirteen from "@/views/frontend/pages/home/index-13.vue";
import AboutUs from "@/views/frontend/pages/pages/about-us.vue";

import ContactUs from "@/views/frontend/pages/pages/contact-us.vue";
import VoiceCall from "@/views/frontend/pages/pages/call/voice-call.vue";
import VideoCall from "@/views/frontend/pages/pages/call/video-call.vue";
import Invoices from "@/views/frontend/pages/pages/invoices/invoices.vue";
import InvoiceView from "@/views/frontend/pages/pages/invoices/invoice-view.vue";
import LoginEmail from "@/views/frontend/pages/pages/authentication/login-email.vue";
import LoginPage from "@/views/frontend/pages/pages/authentication/login.vue";
import Mobileotp from "@/views/frontend/pages/pages/authentication/mobile-otp.vue";
import LoginPhone from "@/views/frontend/pages/pages/authentication/login-phone.vue";
import DoctorSignup from "@/views/frontend/pages/pages/authentication/doctor-signup.vue";
import PatientSignup from "@/views/frontend/pages/pages/authentication/patient-signup.vue";
import ForgotPassword from "@/views/frontend/pages/pages/authentication/forgot-password.vue";
import ForgotPasswordTwo from "@/views/frontend/pages/pages/authentication/forgot-password2.vue";
import LoginEmailOtp from "@/views/frontend/pages/pages/authentication/login-email-otp.vue";
import EmailOtp from "@/views/frontend/pages/pages/authentication/email-otp.vue";
import LoginPhoneOtp from "@/views/frontend/pages/pages/authentication/login-phone-otp.vue";
import Error404 from "@/views/frontend/pages/pages/errorpages/error-404.vue";
import Error500 from "@/views/frontend/pages/pages/errorpages/error-500.vue";
import BlankPage from "@/views/frontend/pages/pages/blank-page.vue";
import Pricing from "@/views/frontend/pages/pages/pricing.vue";
import Privacypolicy from "@/views/frontend/pages/pages/privacy-policy.vue";
import Maintenance from "@/views/frontend/pages/pages/maintenance.vue";
import ComingSoon from "@/views/frontend/pages/pages/coming-soon.vue";
import TermsCondition from "@/views/frontend/pages/pages/terms-condition.vue";
import BlogList from "@/views/frontend/pages/blog/blog-list.vue";
import FAQ from "@/views/frontend/pages/pages/faq.vue";
import BlogGrid from "@/views/frontend/pages/blog/blog-grid.vue";
import BlogDetails from "@/views/frontend/pages/blog/blog-details.vue";
import PharmacyIndex from "@/views/frontend/pages/pharmacymenu/pharmacy-index/pharmacy-index.vue";
import PharmacyDetails from "@/views/frontend/pages/pharmacymenu/details/pharmacy-details.vue";
import PharmacySearch from "@/views/frontend/pages/pharmacymenu/pharmacy-search.vue";
import ProductAll from "@/views/frontend/pages/pharmacymenu/product/product-all";
import Product_Description from "@/views/frontend/pages/pharmacymenu/product-description/product-description.vue";
import Cart from "@/views/frontend/pages/pharmacymenu/cart.vue";
import PaymentSuccess from "@/views/frontend/pages/pharmacymenu/payment-success.vue";
import Payment from "@/views/frontend/pages/pharmacymenu/payment.vue";
import PharmacyRegister from "@/views/frontend/pages/pharmacymenu/pharmacy-register.vue";
import ProductCheckout from "@/views/frontend/pages/pharmacymenu/product-checkout.vue";

import Change_Password from "@/views/frontend/pages/patients/change-password.vue";
import Profile_Settings from "@/views/frontend/pages/patients/profile-settings.vue";
import Patient_Invoices from "@/views/frontend/pages/patients/patient-invoices.vue";
import Chat from "@/views/frontend/pages/doctors/chat/chat-doctor.vue";
import Favourites from "@/views/frontend/pages/patients/favourites-index.vue";
import Booking_Success from "@/views/frontend/pages/patients/booking-success.vue";
import Booking_Success_one from "@/views/frontend/pages/patients/booking-success-one.vue";
import Checkout from "@/views/frontend/pages/patients/checkout.vue";
import Paitentdetails from "@/views/frontend/pages/patients/patient-details.vue";
import Patient_Dashboard from "@/views/frontend/pages/patients/dashboard/patient-dashboard.vue";
import map_grid from "@/views/frontend/pages/patients/doctors/map-grid.vue";
import map_list from "@/views/frontend/pages/patients/doctors/maplist/map-list.vue";
import Search from "@/views/frontend/pages/patients/searchdoctor/search.vue";
import Search_2 from "@/views/frontend/pages/patients/searchdoctor/search2/search-2.vue";
import Booking from "@/views/frontend/pages/patients/booking/booking.vue";
import Booking_2 from "@/views/frontend/pages/patients/booking/booking-2.vue";
import Doctor_Profile_2 from "@/views/frontend/pages/patients/doctorprofile/doctor-profile-2/doctor-profile-2.vue";
import Doctor_Search_Grid from "@/views/frontend/pages/patients/searchdoctor/search2/doctor-search-grid.vue";
import Pharmacy_Register_Step1 from "@/views/frontend/pages/pharmacymenu/pharmacy-register-step1.vue";
import Pharmacy_Register_Step2 from "@/views/frontend/pages/pharmacymenu/pharmacy-register-step2.vue";
import Pharmacy_Register_Step3 from "@/views/frontend/pages/pharmacymenu/pharmacy-register-step3.vue";
import Medical_Details from "@/views/frontend/pages/patients/medical-details.vue";
import Medical_Records from "@/views/frontend/pages/patients/medical-records/medical-records.vue";
import Orders_List from "@/views/frontend/pages/patients/orders-list.vue";
import Dependent_Index from "@/views/frontend/pages/patients/dependent-index.vue";
import Patient_Accounts from "@/views/frontend/pages/patients/accounts/patient-accounts.vue";
import Patient_Appointments from "@/views/frontend/pages/patients/patient-appointments/patient-appointments.vue";
import Patient_Appointments_Grid from "@/views/frontend/pages/patients/patient-appointments/patient-appointments-grid.vue";
import Patient_Upcoming_Appointment from "@/views/frontend/pages/patients/patient-appointments/patient-upcoming-appointment.vue";
import Patient_Cancelled_Appointment from "@/views/frontend/pages/patients/patient-appointments/patient-cancelled-appointment.vue";
import Patient_Completed_Appointment from "@/views/frontend/pages/patients/patient-appointments/patient-completed-appointment.vue";
import Patient_Appointment_Details from "@/views/frontend/pages/patients/patient-appointments/patient-appointment-details.vue";
import Doctor_Profile from "@/views/frontend/pages/patients/doctorprofile/doctor-profile.vue";

//Doctor Pages
import DoctorPayment from "@/views/frontend/pages/doctors/doctor-payment.vue";
import Social_Media from "@/views/frontend/pages/doctors/social-media.vue";
import Reviews_Index from "@/views/frontend/pages/doctors/reviews-index.vue";
import Doctor_Profile_Settings from "@/views/frontend/pages/doctors/doctor-profile-settings/doctor-profile-settings.vue";
import Doctor_Experience_Settings from "@/views/frontend/pages/doctors/doctor-profile-settings/doctor-experience-settings.vue";
import Doctor_Awards_Settings from "@/views/frontend/pages/doctors/doctor-profile-settings/doctor-awards-settings.vue";
import Doctor_Insurance_Settings from "@/views/frontend/pages/doctors/doctor-profile-settings/doctor-insurance-settings.vue";
import Doctor_Clinics_Settings from "@/views/frontend/pages/doctors/doctor-profile-settings/doctor-clinics-settings.vue";
import Doctor_Business_Settings from "@/views/frontend/pages/doctors/doctor-profile-settings/doctor-business-settings.vue";
import Doctor_Education_Settings from "@/views/frontend/pages/doctors/doctor-profile-settings/doctor-education-settings.vue";
import Doctor_Change_Password from "@/views/frontend/pages/doctors/doctor-change-password.vue";
import Doctor_Specialities from "@/views/frontend/pages/doctors/doctor-specialities.vue";
import DoctorDashBoard from "@/views/frontend/pages/doctors/dashboard/doctor-dashboard.vue";
import DoctorRegister from "@/views/frontend/pages/doctors/doctor-register.vue";
import DoctorRegisterStep1 from "@/views/frontend/pages/doctors/doctor-register-step1.vue";
import DoctorRegisterStep2 from "@/views/frontend/pages/doctors/doctor-register-step2.vue";
import DoctorRegisterStep3 from "@/views/frontend/pages/doctors/doctor-register-step3.vue";

import Signupsuccess from "@/views/frontend/pages/pages/signup-success.vue";
import FrontendSignup from "@/views/frontend/pages/pages/signup.vue";
import Consultation from "@/views/frontend/pages/pages/consultation.vue";
import DoctorBlog from "@/views/frontend/pages/doctors/doctorblog/doctor-blog.vue";
import DoctorPendingBlog from "@/views/frontend/pages/doctors/doctorblog/doctor-pendingblog.vue";
import DoctorAddBlog from "@/views/frontend/pages/doctors/doctorblog/doctor-addblog.vue";
import DoctorEditlog from "@/views/frontend/pages/doctors/doctorblog/doctor-editblog.vue";
import Accounts from "@/views/frontend/pages/doctors/accounts/accounts.vue";
import Register from "@/views/frontend/pages/doctors/register.vue";
import PatientRegisterstep1 from "@/views/frontend/pages/doctors/patient-register-step1.vue";
import PatientRegisterstep2 from "@/views/frontend/pages/doctors/patient-register-step2.vue";
import PatientRegisterstep3 from "@/views/frontend/pages/doctors/patient-register-step3.vue";
import PatientRegisterstep4 from "@/views/frontend/pages/doctors/patient-register-step4.vue";
import PatientRegisterstep5 from "@/views/frontend/pages/doctors/patient-register-step5.vue";

import OnboardingEmaill from "@/views/frontend/pages/onboard/onboarding-email.vue";
import OnboardingEmaillOtp from "@/views/frontend/pages/onboard/onboarding-email-otp.vue";
import OnboardingPhone from "@/views/frontend/pages/onboard/onboarding-phone.vue";
import OnboardingPhoneOtp from "@/views/frontend/pages/onboard/onboarding-phone-otp.vue";
import OnBoardingPassword from "@/views/frontend/pages/onboard/onboarding-password.vue";
import OnBoardingUsername from "@/views/frontend/pages/onboard/onboarding-username.vue";
import OnBoardingIdentity from "@/views/frontend/pages/onboard/onboarding-identity.vue";
import OnBoardingPersonalData from "@/views/frontend/pages/onboard/onboarding-personal-data.vue";
import OnBoardingAvatar from "@/views/frontend/pages/onboard/onboarding-avatar.vue";
import OnBoardingAddress from "@/views/frontend/pages/onboard/onboarding-address.vue";
import OnBoardingStepVerify from "@/views/frontend/pages/onboard/onboarding-email-step-2-verify.vue";
import OnBoardingLock from "@/views/frontend/pages/onboard/onboarding-lock.vue";
import OnBoardingVerifyAccount from "@/views/frontend/pages/onboard/onboarding-verify-account.vue";

import OnBoardingProfessionalEmaill from "@/views/frontend/pages/onboard-professional/onboarding-email.vue";
import OnBoardingProfessionalEmaillOtp from "@/views/frontend/pages/onboard-professional/onboarding-email-otp.vue";
import OnBoardingProfessionalPhone from "@/views/frontend/pages/onboard-professional/onboarding-phone.vue";
import OnBoardingProfessionalPhoneOtp from "@/views/frontend/pages/onboard-professional/onboarding-phone-otp.vue";
import OnBoardingProfessionalPassword from "@/views/frontend/pages/onboard-professional/onboarding-password.vue";
import OnBoardingProfessionalUsername from "@/views/frontend/pages/onboard-professional/onboarding-username.vue";
import OnBoardingProfessionalIdentity from "@/views/frontend/pages/onboard-professional/onboarding-identity.vue";
import OnBoardingProfessionalPersonalData from "@/views/frontend/pages/onboard-professional/onboarding-personal-data.vue";
import OnBoardingProfessionalVerification from "@/views/frontend/pages/onboard-professional/onboarding-verification.vue";
import OnBoardingProfessionalPayments from "@/views/frontend/pages/onboard-professional/onboarding-payments.vue";
import OnBoardingProfessionalAvatar from "@/views/frontend/pages/onboard-professional/onboarding-avatar.vue";
import OnBoardingProfessionalPreferences from "@/views/frontend/pages/onboard-professional/onboarding-preferences.vue";
import OnBoardingProfessionalAvailability from "@/views/frontend/pages/onboard-professional/onboarding-availability.vue";
import OnBoardingProfessionalConsultation from "@/views/frontend/pages/onboard-professional/onboarding-consultation.vue";
import OnBoardingProfessionalCost from "@/views/frontend/pages/onboard-professional/onboarding-cost.vue";
import OnBoardingProfessionalStepVerify from "@/views/frontend/pages/onboard-professional/onboarding-email-step-2-verify.vue";
import OnBoardingProfessionalLock from "@/views/frontend/pages/onboard-professional/onboarding-lock.vue";
import OnBoardingProfessionalVerifyAccount from "@/views/frontend/pages/onboard-professional/onboarding-verify-account.vue";

import DoctorRequest from "@/views/frontend/pages/doctors/doctor-request.vue";
import Appointments from "@/views/frontend/pages/doctors/appointments/appointments.vue";
import DoctorUpcomingAppointment from "@/views/frontend/pages/doctors/appointments/doctor-upcoming-appointment.vue";
import DoctorAppointmentStart from "@/views/frontend/pages/doctors/appointments/doctor-appointment-start.vue";
import DoctorCancelledAppointment from "@/views/frontend/pages/doctors/appointments/doctor-cancelled-appointment.vue";
import DoctorCancelledAppointmentTwo from "@/views/frontend/pages/doctors/appointments/doctor-cancelled-appointment-two.vue";
import DoctorCompletedAppointment from "@/views/frontend/pages/doctors/appointments/doctor-completed-appointment.vue";
import DoctorAppointmentsGrid from "@/views/frontend/pages/doctors/appointments/doctor-appointments-grid.vue";
import AvailableTimings from "@/views/frontend/pages/doctors/scheduletiming/available-timings.vue";
import MyPatients from "@/views/frontend/pages/doctors/patients/my-patients.vue";
import PatientProfile from "@/views/frontend/pages/doctors/patients/patient-profile.vue";
import ChatDoctor from "@/views/frontend/pages/doctors/chat/chat-doctor.vue";

const routes = [
  /**************** Frontend  *************/
  {
    path: "/",
    name: "index",
    component: IndexThirteen,
    meta: {
      headerClass: "header-custom header-ten home-care-header",
    },
  },

  {
    path: "/index-13",
    name: "index-13",
    component: IndexThirteen,
    meta: {
      headerClass: "header-custom header-ten home-care-header",
    },
  },

  {
    path: "/about-us",
    name: "about-us",
    component: AboutUs,
  },
  {
    path: "/contato",
    name: "contato",
    component: ContactUs,
  },
  {
    path: "/voice-call",
    name: "voice-call",
    component: VoiceCall,
  },
  {
    path: "/video-call",
    name: "video-call",
    component: VideoCall,
  },
  {
    path: "/invoices",
    name: "invoices",
    component: Invoices,
  },
  {
    path: "/invoice-view",
    name: "invoice-view",
    component: InvoiceView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
  },
  {
    path: "/mobile-otp",
    name: "mobile-otp",
    component: Mobileotp,
  },
  {
    path: "/login-email",
    name: "login-email",
    component: LoginEmail,
  },
  {
    path: "/login-phone",
    name: "login-phone",
    component: LoginPhone,
  },
  {
    path: "/doctor-signup",
    name: "doctor-signup",
    component: DoctorSignup,
  },
  {
    path: "/patient-signup",
    name: "patient-signup",
    component: PatientSignup,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/forgot-password2",
    name: "forgot-password2",
    component: ForgotPasswordTwo,
  },
  {
    path: "/login-email-otp",
    name: "login-email-otp",
    component: LoginEmailOtp,
  },
  {
    path: "/email-otp",
    name: "email-otp",
    component: EmailOtp,
  },
  {
    path: "/login-phone-otp",
    name: "login-phone-otp",
    component: LoginPhoneOtp,
  },
  {
    path: "/error-404",
    name: "error-404",
    component: Error404,
  },
  {
    path: "/error-500",
    name: "error-500",
    component: Error500,
  },
  {
    path: "/blank-page",
    name: "blank-page",
    component: BlankPage,
  },
  {
    path: "/pricing",
    name: "pricing",
    component: Pricing,
  },
  {
    path: "/politica-privacidade",
    name: "politica-privacidade",
    component: Privacypolicy,
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: Maintenance,
  },
  {
    path: "/coming-soon",
    name: "coming-soon",
    component: ComingSoon,
  },
  {
    path: "/termos-condicoes",
    name: "termos-condicoes",
    component: TermsCondition,
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQ,
  },
  {
    path: "/blog-list",
    name: "blog-list",
    component: BlogList,
  },
  {
    path: "/blog-grid",
    name: "blog-grid",
    component: BlogGrid,
  },
  {
    path: "/blog-details",
    name: "blog-details",
    component: BlogDetails,
  },
  {
    path: "/pharmacy-index",
    name: "pharmacy-index",
    component: PharmacyIndex,
  },
  {
    path: "/pharmacy-details",
    name: "pharmacy-details",
    component: PharmacyDetails,
  },
  {
    path: "/pharmacy-search",
    name: "pharmacy-search",
    component: PharmacySearch,
  },
  {
    path: "/product-all",
    name: "product-all",
    component: ProductAll,
  },
  {
    path: "/product-description",
    name: "product-description",
    component: Product_Description,
  },
  {
    path: "/cart",
    name: "cart",
    component: Cart,
  },
  {
    path: "/payment-success",
    name: "payment-success",
    component: PaymentSuccess,
  },
  {
    path: "/payment",
    name: "payment",
    component: Payment,
  },
  {
    path: "/pharmacy-register",
    name: "pharmacy-register",
    component: PharmacyRegister,
  },
  {
    path: "/product-checkout",
    name: "product-checkout",
    component: ProductCheckout,
  },

  {
    path: "/change-password",
    name: "change-password",
    component: Change_Password,
  },

  {
    path: "/profile-settings",
    name: "profile-settings",
    component: Profile_Settings,
  },
  {
    path: "/patient-invoices",
    name: "patient-invoices",
    component: Patient_Invoices,
  },
  {
    path: "/patient-accounts",
    name: "patient-accounts",
    component: Patient_Accounts,
  },
  {
    path: "/patient-appointments",
    name: "patient-appointments",
    component: Patient_Appointments,
  },
  {
    path: "/patient-appointments-grid",
    name: "patient-appointments-grid",
    component: Patient_Appointments_Grid,
  },
  {
    path: "/patient-upcoming-appointment",
    name: "patient-upcoming-appointment",
    component: Patient_Upcoming_Appointment,
  },
  {
    path: "/patient-cancelled-appointment",
    name: "patient-cancelled-appointment",
    component: Patient_Cancelled_Appointment,
  },
  {
    path: "/patient-completed-appointment",
    name: "patient-completed-appointment",
    component: Patient_Completed_Appointment,
  },
  {
    path: "/patient-appointment-details",
    name: "patient-appointment-details",
    component: Patient_Appointment_Details,
  },

  {
    path: "/chat",
    name: "chat",
    component: Chat,
  },
  {
    path: "/favourites",
    name: "favourites",
    component: Favourites,
  },
  {
    path: "/booking-success",
    name: "booking-success",
    component: Booking_Success,
  },
  {
    path: "/booking-success-one",
    name: "booking-success-one",
    component: Booking_Success_one,
  },
  {
    path: "/checkout",
    name: "checkout",
    component: Checkout,
  },
  {
    path: "/patient-details",
    name: "patient-details",
    component: Paitentdetails,
  },
  {
    path: "/patient-dashboard",
    name: "patient-dashboard",
    component: Patient_Dashboard,
  },
  {
    path: "/map-grid",
    name: "map-grid",
    component: map_grid,
  },
  {
    path: "/map-list",
    name: "map-list",
    component: map_list,
  },
  {
    path: "/search",
    name: "search",
    component: Search,
  },
  {
    path: "/pesquisa",
    name: "pesquisa",
    component: Search_2,
    props: true,
  },
  {
    path: "/booking",
    name: "booking",
    component: Booking,
  },
  {
    path: "/doctor-profile-2",
    name: "doctor-profile-2",
    component: Doctor_Profile_2,
  },
  {
    path: "/doctor-profile",
    name: "doctor-profile",
    component: Doctor_Profile,
  },
  {
    path: "/doctor-experience-settings",
    name: "doctor-experience-settings",
    component: Doctor_Experience_Settings,
  },
  {
    path: "/doctor-education-settings",
    name: "doctor-education-settings",
    component: Doctor_Education_Settings,
  },
  {
    path: "/doctor-awards-settings",
    name: "doctor-awards-settings",
    component: Doctor_Awards_Settings,
  },
  {
    path: "/doctor-insurance-settings",
    name: "doctor-insurance-settings",
    component: Doctor_Insurance_Settings,
  },
  {
    path: "/doctor-clinics-settings",
    name: "doctor-clinics-settings",
    component: Doctor_Clinics_Settings,
  },
  {
    path: "/doctor-business-settings",
    name: "doctor-business-settings",
    component: Doctor_Business_Settings,
  },
  {
    path: "/doctor-specialities",
    name: "doctor-specialities",
    component: Doctor_Specialities,
  },
  {
    path: "/booking-2",
    name: "booking-2",
    component: Booking_2,
  },
  {
    path: "/doctor-search-grid",
    name: "doctor-search-grid",
    component: Doctor_Search_Grid,
  },
  {
    path: "/pharmacy-register-step1",
    name: "pharmacy-register-step1",
    component: Pharmacy_Register_Step1,
  },
  {
    path: "/pharmacy-register-step2",
    name: "pharmacy-register-step2",
    component: Pharmacy_Register_Step2,
  },
  {
    path: "/pharmacy-register-step3",
    name: "pharmacy-register-step3",
    component: Pharmacy_Register_Step3,
  },
  {
    path: "/medical-details",
    name: "medical-details",
    component: Medical_Details,
  },
  {
    path: "/medical-records",
    name: "medical-records",
    component: Medical_Records,
  },
  {
    path: "/orders-list",
    name: "orders-list",
    component: Orders_List,
  },
  {
    path: "/dependent",
    name: "dependent",
    component: Dependent_Index,
  },
  {
    path: "/patient-accounts",
    name: "patient-accounts",
    component: Patient_Accounts,
  },
  {
    path: "/doctor-dashboard",
    name: "doctor-dashboard",
    component: DoctorDashBoard,
  },
  {
    path: "/doctor-payment",
    name: "doctor-payment",
    component: DoctorPayment,
  },
  {
    path: "/social-media",
    name: "social-media",
    component: Social_Media,
  },
  {
    path: "/reviews",
    name: "reviews",
    component: Reviews_Index,
  },
  {
    path: "/doctor-profile-settings",
    name: "doctor-profile-settings",
    component: Doctor_Profile_Settings,
  },
  {
    path: "/doctor-change-password",
    name: "doctor-change-password",
    component: Doctor_Change_Password,
  },
  {
    path: "/doctor-register",
    name: "doctor-register",
    component: DoctorRegister,
  },
  {
    path: "/doctor-register-step1",
    name: "doctor-register-step1",
    component: DoctorRegisterStep1,
  },
  {
    path: "/doctor-register-step2",
    name: "doctor-register-step2",
    component: DoctorRegisterStep2,
  },
  {
    path: "/doctor-register-step3",
    name: "doctor-register-step3",
    component: DoctorRegisterStep3,
  },

  {
    path: "/signup-success",
    name: "signup-success",
    component: Signupsuccess,
  },
  {
    path: "/signup",
    name: "signup",
    component: FrontendSignup,
  },
  {
    path: "/consultation",
    name: "consultation",
    component: Consultation,
  },
  {
    path: "/doctor-blog",
    name: "doctor-blog",
    component: DoctorBlog,
  },
  {
    path: "/doctor-pending-blog",
    name: "doctor-pending-blog",
    component: DoctorPendingBlog,
  },
  {
    path: "/doctor-add-blog",
    name: "doctor-add-blog",
    component: DoctorAddBlog,
  },
  {
    path: "/edit-blog",
    name: "edit-blog",
    component: DoctorEditlog,
  },
  {
    path: "/accounts",
    name: "accounts",
    component: Accounts,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/patient-register-step1",
    name: "patient-register-step1",
    component: PatientRegisterstep1,
  },
  {
    path: "/patient-register-step2",
    name: "patient-register-step2",
    component: PatientRegisterstep2,
  },
  {
    path: "/patient-register-step3",
    name: "patient-register-step3",
    component: PatientRegisterstep3,
  },
  {
    path: "/patient-register-step4",
    name: "patient-register-step4",
    component: PatientRegisterstep4,
  },
  {
    path: "/patient-register-step5",
    name: "patient-register-step5",
    component: PatientRegisterstep5,
  },
  {
    path: "/onboarding-email",
    name: "onboarding-email",
    component: OnboardingEmaill,
  },
  {
    path: "/onboarding-email-otp",
    name: "onboarding-email-otp",
    component: OnboardingEmaillOtp,
  },
  {
    path: "/onboarding-phone",
    name: "onboarding-phone",
    component: OnboardingPhone,
  },
  {
    path: "/onboarding-phone-otp",
    name: "onboarding-phone-otp",
    component: OnboardingPhoneOtp,
  },
  {
    path: "/onboarding-password",
    name: "onboarding-password",
    component: OnBoardingPassword,
  },
  {
    path: "/onboarding-username",
    name: "onboarding-username",
    component: OnBoardingUsername,
  },
  {
    path: "/onboarding-identity",
    name: "/onboarding-identity",
    component: OnBoardingIdentity,
  },
  {
    path: "/onboarding-personal-data",
    name: "onboarding-personal-data",
    component: OnBoardingPersonalData,
  },
  {
    path: "/onboarding-avatar",
    name: "onboarding-avatar",
    component: OnBoardingAvatar,
  },
  {
    path: "/onboarding-address",
    name: "onboarding-address",
    component: OnBoardingAddress,
  },
  {
    path: "/onboarding-email-step-2-verify",
    name: "onboarding-email-step-2-verify",
    component: OnBoardingStepVerify,
  },
  {
    path: "/onboarding-lock",
    name: "onboarding-lock",
    component: OnBoardingLock,
  },
  {
    path: "/onboarding-verify-account",
    name: "onboarding-verify-account",
    component: OnBoardingVerifyAccount,
  },
  {
    path: "/onboarding-professional-email",
    name: "onboarding-professional-email",
    component: OnBoardingProfessionalEmaill,
  },
  {
    path: "/onboarding-professional-avatar",
    name: "onboarding-professional-avatar",
    component: OnBoardingProfessionalAvatar,
  },
  {
    path: "/onboarding-professional-email-otp",
    name: "onboarding-professional-email-otp",
    component: OnBoardingProfessionalEmaillOtp,
  },
  {
    path: "/onboarding-professional-phone",
    name: "onboarding-professional-phone",
    component: OnBoardingProfessionalPhone,
  },
  {
    path: "/onboarding-professional-phone-otp",
    name: "onboarding-professional-phone-otp",
    component: OnBoardingProfessionalPhoneOtp,
  },
  {
    path: "/onboarding-professional-password",
    name: "onboarding-professional-password",
    component: OnBoardingProfessionalPassword,
  },
  {
    path: "/onboarding-professional-username",
    name: "onboarding-professional-username",
    component: OnBoardingProfessionalUsername,
  },
  {
    path: "/onboarding-professional-identity",
    name: "/onboarding-professional-identity",
    component: OnBoardingProfessionalIdentity,
  },
  {
    path: "/onboarding-professional-personalize",
    name: "onboarding-professional-personalize",
    component: OnBoardingProfessionalPersonalData,
  },
  {
    path: "/onboarding-professional-verification",
    name: "onboarding-professional-verification",
    component: OnBoardingProfessionalVerification,
  },
  {
    path: "/onboarding-professional-payments",
    name: "onboarding-professional-payments",
    component: OnBoardingProfessionalPayments,
  },
  {
    path: "/onboarding-professional-preferences",
    name: "onboarding-professional-preferences",
    component: OnBoardingProfessionalPreferences,
  },
  {
    path: "/onboarding-professional-availability",
    name: "onboarding-professional-availability",
    component: OnBoardingProfessionalAvailability,
  },
  {
    path: "/onboarding-professional-consultation",
    name: "onboarding-professional-consultation",
    component: OnBoardingProfessionalConsultation,
  },
  {
    path: "/onboarding-professional-cost",
    name: "onboarding-professional-cost",
    component: OnBoardingProfessionalCost,
  },
  {
    path: "/onboarding-professional-email-step-2-verify",
    name: "onboarding-professional-email-step-2-verify",
    component: OnBoardingProfessionalStepVerify,
  },
  {
    path: "/onboarding-professional-lock",
    name: "onboarding-professional-lock",
    component: OnBoardingProfessionalLock,
  },
  {
    path: "/onboarding-professional-verify-account",
    name: "onboarding-professional-verify-account",
    component: OnBoardingProfessionalVerifyAccount,
  },
  {
    path: "/doctor-request",
    name: "doctor-request",
    component: DoctorRequest,
  },
  {
    path: "/appointments",
    name: "appointments",
    component: Appointments,
  },
  {
    path: "/doctor-upcoming-appointment",
    name: "doctor-upcoming-appointment",
    component: DoctorUpcomingAppointment,
  },
  {
    path: "/doctor-appointment-start",
    name: "doctor-appointment-start",
    component: DoctorAppointmentStart,
  },
  {
    path: "/doctor-cancelled-appointment",
    name: "doctor-cancelled-appointment",
    component: DoctorCancelledAppointment,
  },
  {
    path: "/doctor-cancelled-appointment-2",
    name: "doctor-cancelled-appointment-two",
    component: DoctorCancelledAppointmentTwo,
  },
  {
    path: "/doctor-completed-appointment",
    name: "doctor-completed-appointment",
    component: DoctorCompletedAppointment,
  },
  {
    path: "/doctor-appointments-grid",
    name: "doctor-appointments-grid",
    component: DoctorAppointmentsGrid,
  },
  {
    path: "/available-timings",
    name: "available-timings",
    component: AvailableTimings,
  },
  {
    path: "/my-patients",
    name: "my-patients",
    component: MyPatients,
  },
  {
    path: "/patient-profile",
    name: "patient-profile",
    component: PatientProfile,
  },
  {
    path: "/chat-doctor",
    name: "chat-doctor",
    component: ChatDoctor,
  },
];

export const router = createRouter({
  history: createWebHistory("/"),
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from, next) => {
  // Scroll to the top of the page
  window.scrollTo({ top: 0, behavior: "smooth" });

  // Continue with the navigation
  next();
});
