<template>
  <div class="setting-tab">
    <div class="appointment-tabs">
      <ul class="nav">
        <li class="nav-item">
          <router-link class="nav-link" to="/doctor-profile-settings"
            >Basic Details</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/doctor-experience-settings"
            >Experience</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/doctor-education-settings"
            >Education</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/doctor-awards-settings">Awards</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/doctor-insurance-settings"
            >Insurances</router-link
          >
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/doctor-clinics-settings">Clinics</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/doctor-business-settings"
            >Business Hours</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>
