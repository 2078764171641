<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <!-- Footer -->
  <footer class="footer footer-one">
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-4">
            <div class="footer-widget footer-about">
              <div class="footer-logo">
                <router-link to="/"><img src="@/assets/img/logo-dark.png" alt="logo" /></router-link>
              </div>
              <div class="footer-about-content">
                <p>
                  A Solução Completa para Pacientes, Profissionais e Empresas de Home Care
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-9">
            <div class="row">
              <div class="col-lg-4 col-md-4">
                <div class="footer-widget footer-menu">
                  <h2 class="footer-title">Para Pacientes</h2>
                  <ul>
                    <li><a href="/pesquisa">Encontre um profissional</a></li>
                    <li><a href="/login">Login</a></li>
                    <li><a href="/onboarding-email">Crie sua conta</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="footer-widget footer-menu">
                  <h2 class="footer-title">Para Profissionais</h2>
                  <ul>
                    <li><a href="/login">Login</a></li>
                    <li><a href="/onboarding-professional-email">Crie sua conta</a></li>
                    <!--<li><router-link to="search">Dentist</router-link></li>-->
                  </ul>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="footer-widget footer-contact">
                  <h2 class="footer-title">Contato</h2>
                  <div class="footer-contact-info">
                    <!--<div class="footer-address">
                      <p>
                        <i class="feather-map-pin"></i> 3556 Beech Street, USA
                      </p>
                    </div>
                    <div class="footer-address">
                      <p><i class="feather-phone-call"></i> +1 315 369 5943</p>
                    </div>-->
                    <div class="footer-address mb-0">
                      <p><i class="feather-mail"></i> <a
                          href="mailto:contato@saascare.com.br">contato@saascare.com.br</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--<div class="col-lg-3 col-md-7">
            <div class="footer-widget">
              <h2 class="footer-title">Inscreva-se em nossa Newsletter</h2>
              <div class="subscribe-form">
                <form action="#">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Informe o e-mail"
                  />
                  <button type="submit" class="btn">Enviar</button>
                </form>
              </div>
              <div class="social-icon">
                <ul>
                  <li>
                    <a href="javascript:void(0);"
                      ><i class="fab fa-facebook"></i
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"
                      ><i class="fab fa-instagram"></i
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"
                      ><i class="fab fa-twitter"></i
                    ></a>
                  </li>
                  <li>
                    <a href="javascript:void(0);"
                      ><i class="fab fa-linkedin-in"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="copyright-text">
                <p class="mb-0">
                  Todos os direitos reservados © {{ new Date().getFullYear() }} SaaSCare. Um produto <a
                    href="https://www.techsparks.com.br" class="techsparks" target="_blank">TechSparks</a>
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <!-- Copyright Menu -->
              <div class="copyright-menu">
                <ul class="policy-menu">
                  <li><a href="/politica-privacidade">Política de Privacidade</a></li>
                  <li>
                    <a href="/termos-condicoes">Termos e condições</a>
                  </li>
                </ul>
              </div>
              <!-- /Copyright Menu -->
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
  </footer>
  <!-- /Footer -->
</template>