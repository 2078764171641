<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1" />

    <div class="page-wrapper">
      <!-- Contact Us -->
      <section class="contact-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-5 col-md-12">
              <div class="section-inner-header contact-inner-header">
                <h6>Entre em contato</h6>
                <h2>Como podemos lhe ajudar?</h2>
              </div>
              <!--<div class="card contact-card">
                <div class="card-body">
                  <div class="contact-icon">
                    <i class="feather-map-pin"></i>
                  </div>
                  <div class="contact-details">
                    <h4>Address</h4>
                    <p>8432 Mante Highway, Bla bla bla, USA</p>
                  </div>
                </div>
              </div>
              <div class="card contact-card">
                <div class="card-body">
                  <div class="contact-icon">
                    <i class="feather-phone"></i>
                  </div>
                  <div class="contact-details">
                    <h4>Phone Number</h4>
                    <p>+1 315 369 5943</p>
                  </div>
                </div>
              </div>-->
              <div class="card contact-card">
                <div class="card-body">
                  <div class="contact-icon">
                    <i class="feather-mail"></i>
                  </div>
                  <div class="contact-details">
                    <h4>E-mail</h4>
                    <p><a href="mailto:contato@saascare.com.br">contato@saascare.com.br</a></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-7 col-md-12 d-flex">
              <div class="card contact-form-card w-100">
                <div class="card-body">
                  <form action="javascript:;">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="mb-2">Nome</label>
                          <input type="text" class="form-control" placeholder="Informe seu nome" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="mb-2">E-mail</label>
                          <input type="text" class="form-control" placeholder="Informe seu e-mail" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="mb-2">Telefone/Celular</label>
                          <input type="text" class="form-control" placeholder="Informe seu telefone ou celular" />
                        </div>
                      </div>
                      
                      <div class="col-md-12">
                        <div class="mb-3">
                          <label class="mb-2">Mensagem</label>
                          <textarea class="form-control" placeholder="Seus comentários vão aqui"></textarea>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group-btn mb-0">
                          <b-button type="submit" class="btn btn-primary prime-btn">Enviar Mensagem</b-button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- /Contact Us -->

     
    </div>
    <indexfooter></indexfooter>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Contato",
      text: "Home",
      text1: "Contato",
    };
  },
};
</script>
