<template>
    <!-- Onboarding Identity -->

    <div class="onboard-wrapper">
        <onboarding-slider></onboarding-slider>
        <div class="right-panel">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 p-0">
                        <div class="right-panel-title text-center">
                            <router-link to="/"> <img src="@/assets/img/logo-light.png"
                                    alt="saascare-logo"></router-link>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <onboarding-sidebar></onboarding-sidebar>
                    </div>
                    <div class="col-lg-8 col-md-12">
                        <div class="onboarding-content-box">
                            <div class="onboarding-title">
                                <h2>Envio de Documento Pessoal<span>*</span></h2>
                                <h6>Precisamos determinar se um documento de identidade é autêntico e pertence a você. Só precisamos seguir algumas etapas que nos ajudarão a construir um sistema seguro</h6>
                            </div>
                            <div class="onboarding-content passcode-wrap mail-box">
                                <Form @submit="onSubmit" v-slot="{ errors }" id="formOnboarding">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <vue-select :options="DocType" v-model="selectedDocType" placeholder="Selecione o tipo de documento" />
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <Field name="docNro" id="docNro" type="text" placeholder="Entre com o número do documento"
                                                :rules="ValidateDoc"
                                                class="form-control placeholder-style" required=""/>
                                                <ErrorMessage name="docNro" />
                                        </div>
                                    </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <vue-select :options="Type" placeholder="Selecione o tipo de arquivo" />
                                            </div>
                                        </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <div class="call-option file-option ">
                                                <input type="file" @change="onFileChange" id="radio1" accept=".pdf, .jpeg, .jpg" name="selector" class="option-radio">
                                                <label for="radio1" class="call-lable verify-lable verify-file"> <img
                                                        src="@/assets/img/icons/file.png" alt="Image">Upload de Documento
                                                </label>
                                                <div v-if="errorMessage" class="error-message">{{ errorMessage }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                            </div>
                            <div class="onboarding-btn Personalize-btn">
                                <button type="submit" form="formOnboarding">Finalizar</button>
                            </div>
                            <!-- Modal -->

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- /Onboarding Identity -->
    <div class="modal fade" id="id-verified" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog id-upload modal-dialog-centered">
            <div class="modal-content id-pop-content">
                <div class="modal-header id-pop-header justify-content-center">
                    <img src="@/assets/img/icons/success-tick.svg" alt="Image">
                </div>
                <div class="modal-body id-pop-body text-center">
                    <h2>Seu documento foi carregado com sucesso</h2>
                    <p>Esse documento será analizado para que seu cadastro seja aprovado.</p>
                </div>
                <div class="modal-footer id-pop-footer text-center">
                    <div class="onboarding-btn pop-btn ">
                        <router-link to="/onboarding-personalize">Continue</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
let customerPassword = localStorage.getItem("customerPassword");
let customerUsername = localStorage.getItem("customerUsername");
let customerEmail = localStorage.getItem("customerEmail");
let token = localStorage.getItem("token");
let phone = localStorage.getItem("phone");
let customerPeopleId = localStorage.getItem('customerPeopleId');
let customerId = localStorage.getItem('customerId');
let customerFirstName = localStorage.getItem("customerFirstName");
let customerMiddleName = localStorage.getItem("customerMiddleName");
let customerSurName = localStorage.getItem("customerSurName");
            
let customerZipCode = localStorage.getItem("customerZipCode");
let customerStreet = localStorage.getItem("customerStreet");
let customerNumber = localStorage.getItem("customerNumber");
let customerComplement = localStorage.getItem("customerComplement");
let customerNeighborhoodId = localStorage.getItem("customerNeighborhoodId");
let customerNeighborhoodName = localStorage.getItem("customerNeighborhoodName");
let customerCityId = localStorage.getItem("customerCityId");
let customerCityName = localStorage.getItem("customerCityName");
let customerStateId = localStorage.getItem("customerStateId");
let customerStateName = localStorage.getItem("customerStateName");
let customerCountryId = localStorage.getItem("customerCountryId");
let customerCountryName = localStorage.getItem("customerCountryName");

let customerRG = localStorage.getItem("customerRG");
let customerCPF = localStorage.getItem("customerCPF");

const specializationAreas = ref();
let specializationAreaSelectedId;
const specializationAreaId = ref();
const specializationAreaName = ref();

import { ref } from 'vue'
import axios from 'axios';
import { BlobServiceClient } from '@azure/storage-blob';
import { Form, Field, ErrorMessage } from 'vee-validate';

export default {
    data() {
        return {
            DocType: ["Selecione o tipo de documento", "CPF", "RG"],
            Type: ["Selecione o tipo de arquivo", "PDF", "JPEG"],
            errors: [],
            selectedDocType: null,
            selectedFileType: null,
            customerPassword,
            customerUsername,
            customerEmail,
            token,
            phone,
            customerPeopleId,
            customerId,
            customerFirstName,
            customerMiddleName,
            customerSurName,      
            customerZipCode,
            customerStreet,
            customerNumber,
            customerComplement,
            customerNeighborhoodId,
            customerNeighborhoodName,
            customerCityId,
            customerCityName,
            customerStateId,
            customerStateName,
            customerCountryId,
            customerCountryName,
            customerRG,
            customerCPF,
            files: [],
            errorMessage: '',
        }
    },
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    methods: 
    {
        ValidateDoc(value) {
            this.errors = [];
            if (value === undefined && document.getElementById("docNro").value != '') {
                value = document.getElementById("docNro").value;
            }
            if (!value) {
                return 'O campo é obrigatório';
            }
            const docType = this.selectedDocType;
            const cpfPattern = /^\d{11}$/;
            const cnpjPattern = /^\d{14}$/;
            const rgPattern = /^\d{9,10}$/;
            // Testar o valor de docType
            if (docType === "RG") {
                if (!rgPattern.test(value)) {
                    return 'Formato de RG inválido';
                }
            } else if (docType === "CPF") {
                if (!cpfPattern.test(value)) {
                    return 'Formato de CPF inválido';
                }
            } else if (docType === "CNPJ") {
                if (!cnpjPattern.test(value)) {
                    return 'Formato de CNPJ inválido';
                }
            } else {
                return 'Selecione o tipo de documento';
            }


            return true;
        },
        onFileChange(event) {
            this.files = event.target.files;
            //this.fileUrl = URL.createObjectURL(this.selectedFile);
            this.errorMessage = ''; // Limpa a mensagem de erro ao selecionar um novo arquivo
        },
        //Submit
        onSubmit(values){
                if (values.docNro == undefined || values.docNro == '') 
                {
                    return 'Entre com o número de documento';
                } 

                const docType = this.selectedDocType;
                const fileType = this.selectedFileType;
                var rg = "";
                var cpf = "";
                var cnpj = "";


                const cpfPattern = /^\d{11}$/;
                const cnpjPattern = /^\d{14}$/;
                const rgPattern = /^\d{9,10}$/;

                // Testar o valor de docType
                if (docType === "RG") {
                    if (!rgPattern.test(values.docNro)) {
                        setErrors({ docNro: 'Formato de RG inválido' });
                        return 'Formato de RG inválido';
                    }
                    rg = values.docNro;
                    cpf = "";
                    cnpj = "";
                } else if (docType === "CPF") {
                    if (!cpfPattern.test(values.docNro)) {
                        setErrors({ docNro: 'Formato de CPF inválido' });
                        return 'Formato de CPF inválido';
                    }
                    rg = "";
                    cpf = values.docNro;
                    cnpj = "";
                } else {
                    return 'Selecione o tipo de documento';
                }

                if (this.files.length <= 0) {
                    this.errorMessage = 'Por favor, selecione um arquivo primeiro.';
                    return;
                }

                //window.location.replace("/onboarding-professional-verification");

                const config =  {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                };
//JSON Professional

                const data = {
                "id": this.customerId,
                "people": {
                    "id": this.customerPeopleId,
                    "checked": true,
                    "name": {
                    "firstName": this.customerFirstName + " " + this.customerMiddleName,
                    "lastName": this.customerSurName,
                    "socialName": this.customerFirstName
                    },
                    "emails": [ 
                    {
                        "address": this.customerEmail,
                        "isPrincipal": true,
                        "isVerified": true
                    }],
                    "addresses": [
                    {
                        "zipCode": this.customerZipCode,
                        "street": this.customerStreet,
                        "number": this.customerNumber,
                        "complement": this.customerComplement,
                        "neighborhoodId": this.customerNeighborhoodId,
                        "neighborhoodName": this.customerNeighborhoodName,
                        "cityId": this.customerCityId,
                        "cityName": this.customerCityName,
                        "stateId": this.customerStateId,
                        "stateName": this.customerStateName,
                        "countryId": this.customerCountryId,
                        "countryName": this.customerCountryName,
                        "latitude": "string",
                        "longitude": "string",
                        "geolocationLastUpdade": "2024-09-06T19:06:31.082Z",
                        "peopleAddressTypeId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
                    }],
                    "birthdate": "2024-09-06T19:06:31.081Z",
                    "peopleProducts": [
                    {
                        "id": "string",
                        "productId": process.env.VUE_APP_PRODUCT_ID,
                        "modules": [
                        {
                            "id": process.env.VUE_APP_MODULE_ID,
                            "statusId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                            "roles": [
                            process.env.VUE_APP_CUSTOMERROLE_ID
                            ],
                            "keyCloakClientId": process.env.VUE_APP_KEYCLOAKCLIENT_ID
                        }
                        ]
                    }
                    ],
                    "username": this.customerUsername
                },
                "username": this.customerUsername,
                "password": this.customerPassword,
                "createUser": true,
                "peopleId": this.customerPeopleId,
                "name": this.customerFirstName + " " + this.customerMiddleName + " " + this.customerSurName,
                "birthDate": "2024-09-06T19:06:31.081Z",
                "email": this.customerEmail,
                "phoneNumber": this.phone,
                "rg": rg,
                "cpf": cpf,
                "address": {
                    "zipCode": this.customerZipCode,
                    "street": this.customerStreet,
                    "number": this.customerNumber,
                    "complement": this.customerComplement,
                    "neighborhoodId": this.customerNeighborhoodId,
                    "neighborhoodName": this.customerNeighborhoodName,
                    "cityId": this.customerCityId,
                    "cityName": this.customerCityName,
                    "stateId": this.customerStateId,
                    "stateName": this.customerStateName,
                    "countryId": this.customerCountryId,
                    "countryName": this.customerCountryName,
                    "latitude": "string",
                    "longitude": "string",
                    "geolocationLastUpdade": "2024-09-06T19:06:31.082Z",
                    "peopleAddressTypeId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
                },
                "isActive": false
                };

//JSON Professional
            // Adiciona um interceptor de requisição
            axios.interceptors.request.use(request => {
            // Constrói a URL completa
            const fullUrl = `${request.baseURL || ''}${request.url}`;
            console.log('URL completa:', fullUrl);
            
            // Exibe o corpo da requisição
            console.log('Corpo da requisição:', request.data);
            
            return request;
            }, error => {
            return Promise.reject(error);
            });

            axios.put('/Customer',data,config)
                .then(async (response) => {
                    console.log('Resposta:', response.data);
                    console.log('Status:', response.status);
                    this.uploadFiles();
                })
                .catch(error => {
                    if (error.response) 
                    {
                        // O servidor respondeu com um status diferente de 2xx
                        console.error('Erro de resposta:', error.response.status);
                        console.error('Dados do erro:', error.response.data);
                    } else if (error.request) 
                    {
                        // A requisição foi feita, mas não houve resposta
                        console.error('Erro de requisição:', error.request);
                        } else {
                        // Algo aconteceu ao configurar a requisição
                        console.error('Erro:', error.message);
                        }
                });
            //window.location.replace("/onboarding-professional-verification");
        },
        //Submit
        
        // Upload File
        async uploadFiles() {
            //const sasToken = 'YOUR_SAS_TOKEN'; // Substitua pelo seu SAS Token
            //const containerName = 'YOUR_CONTAINER_NAME'; // Substitua pelo nome do seu container
            //const blobServiceClient = new BlobServiceClient(`https://YOUR_STORAGE_ACCOUNT.blob.core.windows.net?${sasToken}`);
            //const containerClient = blobServiceClient.getContainerClient(containerName);

            const sasToken = process.env.VUE_APP_BLOBSTORAGECUSTOMER_TOKEN;
            const containerName = process.env.VUE_APP_BLOBSTORAGECUSTOMER_CONTAINERNAME;
            const blobServiceClient = new BlobServiceClient(process.env.VUE_APP_BLOBSTORAGECUSTOMER_URL+sasToken);
            const containerClient = blobServiceClient.getContainerClient(containerName);

            for (let file of this.files) {

                // Separar o nome base e a extensão
                const fileExtension = file.name.split('.').pop();
                const baseName = this.customerId || file.name.replace(/\.[^/.]+$/, "");
                const fileName = `${baseName}.${fileExtension}`;

                const blockBlobClient = containerClient.getBlockBlobClient(fileName);
                try {   
                await blockBlobClient.uploadBrowserData(file);
                console.log(`${file.name} Upload com sucesso`);
                window.location.replace("/index-13");
                } catch (error) {
                console.error(`Erro de upload ${file.name}:`, error);
                }
            }
        }
        // Upload File
    }
}
</script>
