<template>
  <section class="service-sec-fourteen">
    <div class="section-bg">
      <img src="@/assets/img/bg/sercice-sec-bg.png" alt="Img" />
    </div>
    <div class="container">
      <div class="section-head-fourteen">
        <h2>Our <span> Services</span></h2>
        <p>More the quantity, higher the discount. Hurry, Buy Now!</p>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4 col-md-12 d-flex">
          <div class="service-type-cards w-100">
            <div class="service-types">
              <div class="doctor-image">
                <router-link to="/pesquisa"
                  ><img src="@/assets/img/service/service-doctor-01.jpg" alt="Img"
                /></router-link>
              </div>
              <div class="service-content">
                <h4><router-link to="/pesquisa">Nurse at Home</router-link></h4>
                <router-link to="/pesquisa" class="explore-link"
                  >Explore<i class="feather-arrow-right-circle"></i
                ></router-link>
              </div>
            </div>
            <div class="service-types">
              <div class="doctor-image">
                <router-link to="/pesquisa"
                  ><img src="@/assets/img/service/service-doctor-02.jpg" alt="Img"
                /></router-link>
              </div>
              <div class="service-content">
                <h4><router-link to="/pesquisa">Mobility Assistance</router-link></h4>
                <router-link to="/pesquisa" class="explore-link"
                  >Explore<i class="feather-arrow-right-circle"></i
                ></router-link>
              </div>
            </div>
            <div class="service-types">
              <div class="doctor-image">
                <router-link to="/pesquisa"
                  ><img src="@/assets/img/service/service-doctor-03.jpg" alt="Img"
                /></router-link>
              </div>
              <div class="service-content">
                <h4><router-link to="/pesquisa">Physiotherapy</router-link></h4>
                <router-link to="/pesquisa" class="explore-link"
                  >Explore<i class="feather-arrow-right-circle"></i
                ></router-link>
              </div>
            </div>
            <div class="service-types">
              <div class="doctor-image">
                <router-link to="/pesquisa"
                  ><img src="@/assets/img/service/service-doctor-04.jpg" alt="Img"
                /></router-link>
              </div>
              <div class="service-content">
                <h4><router-link to="/pesquisa">Medical Equipment</router-link></h4>
                <router-link to="/pesquisa" class="explore-link"
                  >Explore<i class="feather-arrow-right-circle"></i
                ></router-link>
              </div>
            </div>
            <div class="service-types">
              <div class="doctor-image">
                <router-link to="/pesquisa"
                  ><img src="@/assets/img/service/service-doctor-05.jpg" alt="Img"
                /></router-link>
              </div>
              <div class="service-content">
                <h4><router-link to="/pesquisa">Trained Attendants</router-link></h4>
                <router-link to="/pesquisa" class="explore-link"
                  >Explore<i class="feather-arrow-right-circle"></i
                ></router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 d-flex">
          <div class="services-img-col w-100">
            <div class="sec-img-center">
              <img src="@/assets/img/service/service-img.jpg" alt="Img" />
            </div>
            <div class="img-center img-center-one" data-aos-delay="500">
              <img src="@/assets/img/service/service-img-01.jpg" alt="Img" />
            </div>
            <div class="img-center img-center-two">
              <img src="@/assets/img/service/service-img-02.jpg" alt="Img" />
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 d-flex">
          <div class="service-type-cards w-100">
            <div class="service-types service-type-right">
              <div class="service-content">
                <h4><router-link to="/pesquisa">Lab Tests</router-link></h4>
                <router-link to="/pesquisa" class="explore-link"
                  >Explore<i class="feather-arrow-right-circle"></i
                ></router-link>
              </div>
              <div class="doctor-image">
                <router-link to="/pesquisa"
                  ><img src="@/assets/img/service/service-doctor-06.jpg" alt="Img"
                /></router-link>
              </div>
            </div>
            <div class="service-types service-type-right">
              <div class="service-content">
                <h4><router-link to="/pesquisa">Doctor Consultation</router-link></h4>
                <router-link to="/pesquisa" class="explore-link"
                  >Explore<i class="feather-arrow-right-circle"></i
                ></router-link>
              </div>
              <div class="doctor-image">
                <router-link to="/pesquisa"
                  ><img src="@/assets/img/service/service-doctor-07.jpg" alt="Img"
                /></router-link>
              </div>
            </div>
            <div class="service-types service-type-right">
              <div class="service-content">
                <h4><router-link to="/pesquisa">Mother & Baby Care</router-link></h4>
                <router-link to="/pesquisa" class="explore-link"
                  >Explore<i class="feather-arrow-right-circle"></i
                ></router-link>
              </div>
              <div class="doctor-image">
                <router-link to="/pesquisa"
                  ><img src="@/assets/img/service/service-doctor-08.jpg" alt="Img"
                /></router-link>
              </div>
            </div>
            <div class="service-types service-type-right">
              <div class="service-content">
                <h4><router-link to="/pesquisa">Vaccination</router-link></h4>
                <router-link to="/pesquisa" class="explore-link"
                  >Explore<i class="feather-arrow-right-circle"></i
                ></router-link>
              </div>
              <div class="doctor-image">
                <router-link to="/pesquisa"
                  ><img src="@/assets/img/service/service-doctor-09.jpg" alt="Img"
                /></router-link>
              </div>
            </div>
            <div class="service-types service-type-right">
              <div class="service-content">
                <h4><router-link to="/pesquisa">Tele Consultation</router-link></h4>
                <router-link to="/pesquisa" class="explore-link"
                  >Explore<i class="feather-arrow-right-circle"></i
                ></router-link>
              </div>
              <div class="doctor-image">
                <router-link to="/pesquisa"
                  ><img src="@/assets/img/service/service-doctor-10.jpg" alt="Img"
                /></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
