<template>
    <!-- Onboarding Password -->
    <div class="onboard-wrapper">

        <onboarding-slider></onboarding-slider>

        <div class="right-panel">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 p-0">
                        <div class="right-panel-title text-center">
                            <router-link to="/"> <img src="@/assets/img/logo-light.png"
                                    alt="saascare-logo"></router-link>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <onboarding-sidebar></onboarding-sidebar>
                    </div>
                    <div class="col-lg-8">
                        <div class="onboarding-content-box content-wrap">
                            <div class="onboard-set">
                                <div class="onboarding-title pass-title">
                                    <h2>Crie uma senha para sua conta SaaSCare</h2>
                                </div>
                                <div class="onboarding-content">
                                    <div class="row">
                                        <Form :validation-schema="schema" @submit="onSubmit" id="formPassword">
                                            <div class="col-lg-12">
                                                <div class="mb-3 forms-block pass-group">
                                                    <div class="input-placeholder form-focus passcode-wrap mail-box">
                                                        <label class="focus-label">Senha<span>*</span></label>
                                                        <Field name="password" id="password" type="password"
                                                            :rules="validatePassword"
                                                            class="form-control floating pass-icon" />
                                                        <ErrorMessage name="password"></ErrorMessage>
                                                    </div>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div class="col-lg-12">
                                                <div class="mb-3">
                                                    <div class="input-placeholder form-focus passcode-wrap mail-box">
                                                        <label class="focus-label">Confirmação de
                                                            senha<span>*</span></label>
                                                        <Field name="passwordconfirm" id="passwordconfirm"
                                                            type="password" :rules="validateConfirmPassword"
                                                            class="form-control floating pass-icon-1 " />                                                            
                                                        <ErrorMessage name="passwordconfirm"></ErrorMessage>
                                                        <p v-if="errors.length">
                                                        <ul>
                                                            <li v-for="error in errors">{{ error }}</li>
                                                        </ul>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                            <br></br>
                            <div class="row">
                                <div
                                    class="col-lg-6 d-flex justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-start justify-content-xg-start">
                                    <div class="onboarding-back-btn">
                                        <button @click="onBack">Voltar</button>
                                    </div>
                                </div>
                                <div
                                    class="col-lg-6 d-flex justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xg-end">
                                    <div class="onboarding-btn">
                                        <button type="submit" form="formPassword">Continuar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Onboarding Password -->
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import { Form, Field, ErrorMessage } from 'vee-validate';
export default {
    data() {
        return {
            Euro: ["Euro", "INR", "Dinar", "ring"],
            errors: [],
        }
    },
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    mounted() {

    },
    methods: {
        onBack() {
            this.$router.go(-1);
        },
        onSubmit(values) {

            if (values.password === undefined && document.getElementById("password").value != '') {
                values.password = document.getElementById("password").value;
            }

            localStorage.setItem("password", values.password);
            this.$router.push({ path: "/onboarding-avatar" });

        },
        validatePassword(value) {
            // if the field is empty

            if (value === undefined && document.getElementById("password").value != '') {
                value = document.getElementById("password").value;
            }

            this.errors = [];
            if (!value) {
                return 'O campo é obrigatório';
            }
            // if the field is not a valid email
            const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;
            if (!regex.test(value)) {
                return 'Informe um senha válida';
            }
            return true;
        },
        validateConfirmPassword(value) {
            // if the field is empty

            if (value === undefined && document.getElementById("passwordconfirm").value != '') {
                value = document.getElementById("passwordconfirm").value;
            }

            this.errors = [];
            if (!value) {
                return 'O campo é obrigatório';
            }
            // if the field is not a valid email

            if (document.getElementById("password").value != undefined && document.getElementById("password").value != value) {
                return 'A confirmação de senha deve ser igual a senha';
            }
            return true;
        },
    },
}
</script>