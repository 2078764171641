<!-- eslint-disable no-prototype-builtins -->
<template>
    <!-- Onboarding Personalize -->

    <div class="onboard-wrapper">
        <onboarding-slider></onboarding-slider>
        <div class="right-panel">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 p-0">
                        <div class="right-panel-title text-center">
                            <router-link to="/"> <img src="@/assets/img/logo-light.png"
                                    alt="saascare-logo"></router-link>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <onboarding-sidebar></onboarding-sidebar>
                    </div>
                    <div class="col-lg-8 col-md-12">
                        <div class="onboarding-content-box">
                            <div class="onboarding-title profile-title">
                                <h2>Envie sua foto de perfil <span>*</span></h2>
                            </div>
                            <div class="onboarding-content passcode-wrap mail-box">
                                <div class="row">
                                    <div
                                        class="col-lg-12 d-flex justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-center justify-content-xg-center">
                                        <div class="form-group">
                                            <div class="upload-pics">
                                                <cropper :src="image.src" @change="crop" ref="cropper"
                                                    :stencil-component="$options.components.CircleStencil"
                                                    image-restriction="stencil" :stencil-size="{
                                                        width: 500,
                                                        height: 500
                                                    }" :stencil-props="{
                                                        handlers: {},
                                                        movable: false,
                                                        resizable: false,
                                                        aspectRatio: 1,
                                                    }" :canvas="{
                                                        height: 500,
                                                        width: 500
                                                    }" class="upload-pic" />
                                                <div class="row">
                                                    <div class="onboarding-upload-photo-btn">
                                                        <button @click=" $refs.file.click()">
                                                            <input type="file" ref="file" @change="loadImage($event)"
                                                                accept="image/*">
                                                            <i class="fas fa-camera"></i>&nbsp;Enviar
                                                            imagem
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                                <div class="row">
                                    <div
                                        class="col-lg-6 d-flex justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-start justify-content-xg-start">
                                        <div class="onboarding-back-btn">
                                            <button @click="onBack">Voltar</button>
                                        </div>
                                    </div>
                                    <div
                                        class="col-lg-6 d-flex justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-end justify-content-xg-end">
                                        <div class="onboarding-btn">
                                            <button type="submit" @click="onSubmit" form="formAvatar">Continuar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- /Onboarding Personalize -->
</template>
<script>
let customerPeopleId = localStorage.getItem('customerPeopleId');

import { ref } from 'vue'
const currentDate = ref(new Date())
import { Cropper, CircleStencil } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import 'vue-advanced-cropper/dist/theme.compact.css';
import { BlobServiceClient } from '@azure/storage-blob';

const image =  {
    src: null,
    type: null,
    blob: null, 
    originalFileName: null
}

async function ifUrlExist(url, callback) {
    let request = new XMLHttpRequest;
    request.open('GET', url, true);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    request.setRequestHeader('Accept', '*/*');
    request.onprogress = function (event) {
        let status = event.target.status;
        let statusFirstNumber = (status).toString()[0];
        switch (statusFirstNumber) {
            case '2':
                request.abort();
                return true;
            default:
                request.abort();
                return false;
        }
    };
    request.send('');
}

// This function is used to detect the actual image type, 
function getMimeType(file, fallback = null) {
    const byteArray = (new Uint8Array(file)).subarray(0, 4);
    let header = '';
    for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
    }
    switch (header) {
        case "89504e47":
            return "image/png";
        case "47494638":
            return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            return "image/jpeg";
        default:
            return fallback;
    }
}
//https://avatar.iran.liara.run/public
export default {
    name: 'imageUpload',
    data() {
        return {
            image,
            customerPeopleId,
        }
    },
    components: {
        Cropper, CircleStencil
    },
    methods: {
        crop() {
            const { canvas } = this.$refs.cropper.getResult();
            canvas.toBlob((blob) => {
                URL.revokeObjectURL(this.image.src)
                let img = URL.createObjectURL(blob)
                localStorage.setItem("avatar", img);
                // Do something with blob: upload to a server, download and etc.
                this.image.blob = blob; // Armazena o blob na propriedade image
            }, this.image.type);
        },
        reset() {
            this.image = {
                src: null,
                type: null,
                blob: null, // Adiciona a propriedade blob
                originalFileName: null // Inicializa o campo originalFileName
            }
        },
        loadImage(event) {
            // Reference to the DOM input element
            const { files } = event.target;
             
            // Ensure that you have a file before attempting to read it
            if (files && files[0]) {
                const originalFileName = files[0].name; // Nome do arquivo original
                this.image.originalFileName = originalFileName;

                // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
                if (this.image.src) {
                    URL.revokeObjectURL(this.image.src)
                }
                // 2. Create the blob link to the file to optimize performance:
                const blob = URL.createObjectURL(files[0]);

                // 3. The steps below are designated to determine a file mime type to use it during the 
                // getting of a cropped image from the canvas. You can replace it them by the following string, 
                // but the type will be derived from the extension and it can lead to an incorrect result:
                //
                // this.image = {
                //    src: blob;
                //    type: files[0].type
                // }

                // Create a new FileReader to read this image binary data
                const reader = new FileReader();
                // Define a callback function to run, when FileReader finishes its job
                reader.onload = (e) => {
                    // Note: arrow function used here, so that "this.image" refers to the image of Vue component
                    this.image = {
                        // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
                        src: blob,
                        // Determine the image type to preserve it during the extracting the image from canvas:
                        type: getMimeType(e.target.result, files[0].type),
                        blob: this.image.blob, // Mantém o blob existente
                        originalFileName: originalFileName // Armazena o nome do arquivo original
                    };
                    //localStorage.setItem("avatar", this.image.src);
                    //localStorage.setItem("avatarType", this.image.type);
                };
                // Start the reader job - read file as a data url (base64 format)
                reader.readAsArrayBuffer(files[0]);
            }
        },
        onSubmit() {
            try {
                this.uploadFiles();
                this.$router.push({ path: "/onboarding-address" });
            } catch (error) {
                return;
            }
            
        },
        onBack() {
            this.$router.go(-1);
        },
        // Upload File
        async uploadFiles() {
            const containerName = process.env.VUE_APP_BLOBSTORAGECUSTOMER_CONTAINERNAME;
            const blobServiceClient = new BlobServiceClient(process.env.VUE_APP_BLOBSTORAGECUSTOMERAVATAR_URL+process.env.VUE_APP_BLOBSTORAGECUSTOMER_TOKEN);
            const containerClient = blobServiceClient.getContainerClient(containerName);
        
            if (this.image.blob) {
                const fileExtension = this.image.type.split('/').pop();
                const baseName = this.customerPeopleId || this.image.originalFileName.replace(/\.[^/.]+$/, ""); // Usa o nome original do arquivo
                const fileName = 'customer_avatar_' + `${baseName}.${fileExtension}`;
                const blockBlobClient = containerClient.getBlockBlobClient(fileName);
                
                try {
                    await blockBlobClient.uploadData(this.image.blob);
                    console.log(`Upload de ${fileName} com sucesso`);
                } catch (error) {
                    console.error(`Erro de upload ${fileName}:`, error);
                }
            }

        }
        // Upload File
    },
     beforeCreate() {
        this.image = {
            src: (localStorage.hasOwnProperty("avatar") && localStorage.getItem("avatar") != undefined && localStorage.getItem("avatar") != 'undefined') ? ( ifUrlExist(localStorage.getItem("avatar")) ? localStorage.getItem("avatar") : "https://avatar.iran.liara.run/public") : "https://avatar.iran.liara.run/public",
            type: (localStorage.hasOwnProperty("avatar") && localStorage.getItem("avatar") != undefined && localStorage.getItem("avatar") != 'undefined') ? ( ifUrlExist(localStorage.getItem("avatar")) ? localStorage.getItem("avatarType") : "image/png") : "image/png",
        }
    },
    unmounted() {
        // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        //if (this.image.src) {
        //    URL.revokeObjectURL(this.image.src)
        //}
    }

}
</script>
