<template>
  <div class="login-body">
    <headerinfo />
    <!-- Page Content -->
    <div class="login-content-info">
      <div class="container">
        <!-- Signup -->
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-6">
            <div class="account-content">
              <div class="login-shapes">
                <div class="shape-img-left">
                  <img src="@/assets/img/shape-01.png" alt="shape-image" />
                </div>
                <div class="shape-img-right">
                  <img src="@/assets/img/shape-02.png" alt="shape-image" />
                </div>
              </div>
              <div class="account-info">
                <div class="login-back">
                  <router-link to="/login-email"><i class="fa-solid fa-arrow-left-long"></i> Voltar para Login</router-link>
                </div>
                <div class="login-title">
                  <h3>Signup</h3>
                </div>
                <div class="signup-option-btns">
                  <router-link to="/onboarding-professional-email" class="signup-btn-info">
                    <div class="signup-info">
                      <div class="signup-icon">
                        <img src="@/assets/img/icons/doctor-icon.svg" alt="doctor-icon" />
                      </div>
                      <div class="signup-content">
                        <h4>Profissional</h4>
                        <p>Se você é um profissional e deseja se cadastrar no SaaSCare clique aqui</p>
                      </div>
                    </div>
                    <div class="signup-arrow">
                      <i class="fas fa-arrow-right"></i>
                    </div>
                  </router-link>
                  <router-link to="/onboarding-email" class="signup-btn-info">
                    <div class="signup-info">
                      <div class="signup-icon">
                        <img src="@/assets/img/icons/patient-icon.svg" alt="patient-icon" />
                      </div>
                      <div class="signup-content">
                        <h4>Paciente</h4>
                        <p>Se você é uma pessoa e está procurando um profissional no SaaSCare clique aqui</p>
                      </div>
                    </div>
                    <div class="signup-arrow">
                      <i class="fas fa-arrow-right"></i>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Signup -->
      </div>
    </div>
    <!-- /Page Content -->
    <cursor></cursor>
  </div>
</template>
