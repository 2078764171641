<template>
    <!-- Onboarding Password -->
    <div class="onboard-wrapper">

        <onboarding-professional-slider></onboarding-professional-slider>

        <div class="right-panel">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 p-0">
                        <div class="right-panel-title text-center">
                            <router-link to="/"> <img src="@/assets/img/logo-light.png"
                                    alt="saascare-logo"></router-link>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <onboarding-professional-sidebar></onboarding-professional-sidebar>
                    </div>
                    <div class="col-lg-8">
                        <div class="onboarding-content-box content-wrap">
                            <div class="onboard-set">
                                <div class="onboarding-title pass-title">
                                    <h2>Crie um usuário para a sua conta SaaSCare</h2>
                                </div>
                                <div class="onboarding-content">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="mb-3 forms-block pass-group">
                                                <div class="input-placeholder form-focus passcode-wrap mail-box">
                                                    <Form :validation-schema="schema" @submit="onSubmit" id="formUsername">
                                                    <label class="focus-label">Informe o nome de usuário<span>*</span></label>
                                                    <Field name="username" id="username" type="text"
                                                            :rules="validateUsername" class="form-control floating " />
                                                        <ErrorMessage name="username"></ErrorMessage>
                                                        <p v-if="errors.length">
                                                        <ul>
                                                            <li v-for="error in errors">{{ error }}</li>
                                                        </ul>
                                                        </p>
                                                    </Form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="onboarding-btn">
                                <button type="submit" form="formUsername">Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Onboarding Password -->
</template>
<script>
import Vue from 'vue';
import axios from 'axios';
import { Form, Field, ErrorMessage } from 'vee-validate';
export default {
    data() {
        return {
            Euro: ["Euro", "INR", "Dinar", "ring"],
            errors: [],
        }
    },
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    mounted() {
        if (localStorage.hasOwnProperty("username") && localStorage.getItem("username") != undefined && localStorage.getItem("username") != 'undefined') {
            document.getElementById("username").value = localStorage.getItem("username");
        }
    },
    methods: {
        onBack() {
            this.$router.go(-1);
        },
        onSubmit(values) {
            this.errors = [];
            if (values.username === undefined && document.getElementById("username").value != '') {
                values.username = document.getElementById("username").value;
            }

            axios.get('/BasicSearches/usernameExists/' + values.username).then(async (response) => {
                if (response.data.data == true) {
                    this.errors.push('Usuário já cadastrado');
                    return 'Usuário já cadastrado';
                } else {
                    localStorage.setItem("username", values.username);
                    this.$router.push({ path: "/onboarding-professional-password" });
                }
            });
        },
        validateUsername(value) {
            // if the field is empty

            if (value === undefined && document.getElementById("username").value != '') {
                value = document.getElementById("username").value;
            }

            this.errors = [];
            if (!value) {
                return 'O campo é obrigatório';
            }
            // if the field is not a valid email
            const regex = /^(?!(?:[^.]*\.){2})[A-Za-z][A-Za-z0-9.]{5,19}$(?<!\\.)$/;
            if (!regex.test(value)) {
                return 'Informe um nome de usuário válido';
            }
            return true;
        },
    },
}
</script>