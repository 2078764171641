<template>
    <!-- Onboarding Identity -->

    <div class="onboard-wrapper">
        <onboarding-professional-slider></onboarding-professional-slider>
        <div class="right-panel">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 p-0">
                        <div class="right-panel-title text-center">
                            <router-link to="/"> <img src="@/assets/img/logo-light.png"
                                    alt="saascare-logo"></router-link>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <onboarding-professional-sidebar></onboarding-professional-sidebar>
                    </div>
                    <div class="col-lg-8 col-md-12">
                        <div class="onboarding-content-box">
                            <div class="onboarding-title">
                                <h2>Envio de Documento Pessoal<span>*</span></h2>
                                <h6>Precisamos determinar se um documento de identidade é autêntico e pertence a você. Só precisamos seguir algumas etapas que nos ajudarão a construir um sistema seguro</h6>
                            </div>
                            <div class="onboarding-content passcode-wrap mail-box">
                                <Form @submit="onSubmit" v-slot="{ errors }" id="formOnboarding">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <vue-select :options="DocType" v-model="selectedDocType" placeholder="Selecione o tipo de documento" />
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <Field name="docNro" id="docNro" type="text" placeholder="Entre com o número do documento"
                                                    :rules="ValidateDoc"
                                                    class="form-control placeholder-style" required=""/>
                                                    <ErrorMessage name="docNro" />
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <vue-select :options="Type" placeholder="Selecione o tipo de arquivo" />
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <div class="call-option file-option ">
                                                    <input type="file" @change="onFileChange" id="radio1" accept=".pdf, .jpeg, .jpg" name="selector" class="option-radio">
                                                    <label for="radio1" class="call-lable verify-lable verify-file"> <img
                                                            src="@/assets/img/icons/file.png" alt="Image">Envio de documento
                                                    </label>
                                                    <div v-if="errorMessage" class="error-message">{{ errorMessage }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                            <div class="onboarding-btn Personalize-btn">
                                    <button type="submit" form="formOnboarding">Continuar</button>
                            </div>
                            <!-- Modal -->

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- /Onboarding Identity -->
    <div class="modal fade" id="id-verified" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog id-upload modal-dialog-centered">
            <div class="modal-content id-pop-content">
                <div class="modal-header id-pop-header justify-content-center">
                    <img src="@/assets/img/icons/success-tick.svg" alt="Image">
                </div>
                <div class="modal-body id-pop-body text-center">
                    <h2>Seu documento foi carregado com sucesso</h2>
                    <p>Esse documento será analizado para que seu cadastro seja aprovado.</p>
                </div>
                <div class="modal-footer id-pop-footer text-center">
                    <div class="onboarding-btn pop-btn ">
                        <router-link to="/onboarding-professional-personalize">Continue</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
let professionalPassword = localStorage.getItem("professionalPassword");
let professionalUsername = localStorage.getItem("professionalUsername");
let professionalEmail = localStorage.getItem("professionalEmail");
let token = localStorage.getItem("token");
let phone = localStorage.getItem("phone");
let professionalPeopleId = localStorage.getItem('professionalPeopleId');
let professionalId = localStorage.getItem('professionalId');

import { ref } from 'vue'
import axios from 'axios';
import { Form, Field, ErrorMessage } from 'vee-validate';

import { BlobServiceClient } from '@azure/storage-blob';

export default {
    data() {
        return {
            DocType: ["Selecione o tipo de documento", "CPF", "CNPJ", "RG"],
            Type: ["Selecione o tipo de arquivo", "PDF", "JPEG"],
            errors: [],
            selectedDocType: null,
            selectedFileType: null,
            professionalPassword,
            professionalUsername,
            professionalEmail,
            token,
            phone,
            professionalPeopleId,
            professionalId,
            files: [],
            errorMessage: '',
        }
    },
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    methods: 
    {
        ValidateDoc(value) {
            this.errors = [];
            if (value === undefined && document.getElementById("docNro").value != '') {
                value = document.getElementById("docNro").value;
            }
            if (!value) {
                return 'O campo é obrigatório';
            }
            const docType = this.selectedDocType;
            const cpfPattern = /^\d{11}$/;
            const cnpjPattern = /^\d{14}$/;
            const rgPattern = /^\d{9,10}$/;
            // Testar o valor de docType
            if (docType === "RG") {
                if (!rgPattern.test(value)) {
                    return 'Formato de RG inválido';
                }
            } else if (docType === "CPF") {
                if (!cpfPattern.test(value)) {
                    return 'Formato de CPF inválido';
                }
            } else if (docType === "CNPJ") {
                if (!cnpjPattern.test(value)) {
                    return 'Formato de CNPJ inválido';
                }
            } else {
                return 'Selecione o tipo de documento';
            }


            return true;
        },
        onFileChange(event) {
            this.files = event.target.files;
            this.errorMessage = '';
        },
        //Submit
        onSubmit(values){
                if (values.docNro == undefined || values.docNro == '') 
                {
                    return 'Entre com o número de documento';
                } 

                const docType = this.selectedDocType;
                const fileType = this.selectedFileType;
                var rg = "";
                var cpf = "";
                var cnpj = "";


                const cpfPattern = /^\d{11}$/;
                const cnpjPattern = /^\d{14}$/;
                const rgPattern = /^\d{9,10}$/;

                // Testar o valor de docType
                if (docType === "RG") {
                    if (!rgPattern.test(values.docNro)) {
                        setErrors({ docNro: 'Formato de RG inválido' });
                        return 'Formato de RG inválido';
                    }
                    rg = values.docNro;
                    cpf = "";
                    cnpj = "";
                } else if (docType === "CPF") {
                    if (!cpfPattern.test(values.docNro)) {
                        setErrors({ docNro: 'Formato de CPF inválido' });
                        return 'Formato de CPF inválido';
                    }
                    rg = "";
                    cpf = values.docNro;
                    cnpj = "";
                } else if (docType === "CNPJ") {
                    if (!cnpjPattern.test(values.docNro)) {
                        setErrors({ docNro: 'Formato de CNPJ inválido' });
                        return 'Formato de CNPJ inválido';
                    }
                    rg = "";
                    cpf = "";
                    cnpj = values.docNro;
                } else {
                    return 'Selecione o tipo de documento';
                }

                try {
                    //if (!this.uploadFiles()) {return 'Erro ao carregar arquivo.';}
                    localStorage.setItem("professionalRG", rg);
                    localStorage.setItem("professionalCNPJ", cnpj);
                    localStorage.setItem("professionalCPF", cpf);
                    this.uploadFiles();    
                } catch (error) {
                    return 'Erro ao carregar arquivo.';
                }
        },
        //Submit
        // Upload File
        async uploadFiles() {

            const sasToken = process.env.VUE_APP_BLOBSTORAGECUSTOMER_TOKEN;
            const containerName = process.env.VUE_APP_BLOBSTORAGECUSTOMER_CONTAINERNAME;
            const blobServiceClient = new BlobServiceClient(process.env.VUE_APP_BLOBSTORAGECUSTOMER_URL+sasToken);
            const containerClient = blobServiceClient.getContainerClient(containerName);

            for (let file of this.files) {

                // Separar o nome base e a extensão
                const fileExtension = file.name.split('.').pop();
                const baseName = this.professionalId || file.name.replace(/\.[^/.]+$/, "");
                const fileName = 'professionalId_' + `${baseName}.${fileExtension}`;

                const blockBlobClient = containerClient.getBlockBlobClient(fileName);
                try {   
                    await blockBlobClient.uploadBrowserData(file);
                    console.log(`${file.name} Upload com sucesso`);

                    window.location.replace("/onboarding-professional-verification");
                } catch (error) {
                    console.error(`Erro de upload ${file.name}:`, error);
                    return 'Erro';
                }
            }
        }
        // Upload File
    }
}
</script>
