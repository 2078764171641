<template>
  <!-- Onboarding Verification -->

  <div class="onboard-wrapper">
    <onboarding-professional-slider></onboarding-professional-slider>
    <div class="right-panel">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 p-0">
            <div class="right-panel-title text-center">
              <router-link to="/">
                <img src="@/assets/img/logo-light.png" alt="saascare-logo" /></router-link>
            </div>
          </div>
          <div class="col-lg-4 col-md-12">
            <onboarding-professional-sidebar></onboarding-professional-sidebar>
          </div>
          <div class="col-lg-8 col-md-12">
            <div class="onboarding-content-box">
              <div class="onboarding-title">
                <h2>Verificação Profissional<span>*</span></h2>
                <h6>Por favor forneça detalhes para realizarmos uma verificação profissional anexando um cópia ou comprovante de:</h6>
              </div>
              <div class="onboarding-content passcode-wrap mail-box">
                <Form @submit="onSubmit" id="formOnboarding">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="verify-box">
                      <ul class="verify-list">
                        <li class="verify-item">
                          Certificado de Registro no Conselho de Classe OU
                          Registro no Conselho Profissional apropriado
                        </li>
                        <li class="verify-item">
                          Algum comprovante de atuação na especialidade informada
                          de no máximo 3 meses a partir da data desse cadastros.
                          Profissionais do estrangeiros devem fornecer um Certificado de Registro
                          de Classe emitido pelo país de origem
                        </li>
                        <li class="verify-item">Curriculum Vitae</li>
                        <li class="verify-item">Certificado de Registro de Especialista</li>
                        <li class="verify-item">
                          Assinatura digital: cópia da assinatura e número de registro
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group mt-4">
                      <label class="onboarding-lable">Número de registro do conselho de classe <span>*</span></label>
                      <input type="text" placeholder="Número de registro do conselho de classe"
                        class="form-control placeholder-style" required="" />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <div class="call-option file-option">
                        <input type="file" id="radio1" @change="onFileChange" name="selector" class="option-radio" />
                        <label for="radio1" class="call-lable verify-lable verify-file">
                          <img src="@/assets/img/icons/file.png" alt="Image" />Upload
                          Document</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group mt-4">
                      <label class="onboarding-lable">Especialidade<span>*</span></label>
                      <v-autocomplete :items="specializationAreas" item-title="description" item-value="id"
                        id="specializationAreasSearch" v-model="specializationAreaSelectedId" auto-select-first
                        density="comfortable" no-data-text="Informação não localizada"
                        placeholder="Selecione a especialidade" variant="plain"
                        @keydown.enter="selecionaEspecialidade($event)"
                        @update:modelValue="selecionaEspecialidade($event)" />
                    </div>
                  </div>
                  <div class="col-lg-12">
                      <div class="form-group">
                          <label class="onboarding-lable">Descrição dos Serviços </label>
                          <textarea id="aboutServices" v-model="aboutServices" name="aboutServices" placeholder="Deixe aqui um breve descrição dos serviços executados pela sua especialidade"></textarea>
                      </div>
                  </div>
                </div>
                </Form>
              </div>
              <div class="onboarding-btn Personalize-btn">
                <button type="submit" form="formOnboarding">Continuar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Onboarding Verification -->
</template>
<script>
let professionalPassword = localStorage.getItem("professionalPassword");
let professionalUsername = localStorage.getItem("professionalUsername");
let professionalEmail = localStorage.getItem("professionalEmail");
let token = localStorage.getItem("token");
let phone = localStorage.getItem("phone");
let professionalPeopleId = localStorage.getItem('professionalPeopleId');
let professionalId = localStorage.getItem('professionalId');
let professionalFirstName = localStorage.getItem("professionalFirstName");
let professionalMiddleName = localStorage.getItem("professionalMiddleName");
let professionalSurName = localStorage.getItem("professionalSurName");
            
let professionalZipCode = localStorage.getItem("professionalZipCode");
let professionalStreet = localStorage.getItem("professionalStreet");
let professionalNumber = localStorage.getItem("professionalNumber");
let professionalComplement = localStorage.getItem("professionalComplement");
let professionalNeighborhoodId = localStorage.getItem("professionalNeighborhoodId");
let professionalNeighborhoodName = localStorage.getItem("professionalNeighborhoodName");
let professionalCityId = localStorage.getItem("professionalCityId");
let professionalCityName = localStorage.getItem("professionalCityName");
let professionalStateId = localStorage.getItem("professionalStateId");
let professionalStateName = localStorage.getItem("professionalStateName");
let professionalCountryId = localStorage.getItem("professionalCountryId");
let professionalCountryName = localStorage.getItem("professionalCountryName");

let professionalRG = localStorage.getItem("professionalRG");
let professionalCNPJ = localStorage.getItem("professionalCNPJ");
let professionalCPF = localStorage.getItem("professionalCPF");

const specializationAreas = ref();
let specializationAreaSelectedId;
const specializationAreaId = ref();
const specializationAreaName = ref();

import { ref } from 'vue'
import axios from 'axios';
import { Form, Field, ErrorMessage } from 'vee-validate';

import { BlobServiceClient } from '@azure/storage-blob';

export default {
  data() {
    return {
      Area: ["Select Area of Specialisation", "Surgery", "Heart", "Ortho"],
      specializationAreas,
      especialidade: "",
      specializationAreaSelectedId,
      specializationAreaId,
      specializationAreaName,
      professionalPassword,
      professionalUsername,
      professionalEmail,
      token,
      phone,
      professionalPeopleId,
      professionalId,
      professionalFirstName,
      professionalMiddleName,
      professionalSurName,      
      professionalZipCode,
      professionalStreet,
      professionalNumber,
      professionalComplement,
      professionalNeighborhoodId,
      professionalNeighborhoodName,
      professionalCityId,
      professionalCityName,
      professionalStateId,
      professionalStateName,
      professionalCountryId,
      professionalCountryName,
      professionalRG,
      professionalCNPJ,
      professionalCPF,
      files: [],
    };
  },
  components: {
        Form,
        Field,
        ErrorMessage,
  },
  mounted() {
    axios.get('SpecializationArea?page=1&pageSize=100')
      .then((response) => {
        specializationAreas.value = response.data.data;
      });    
  },
  methods: 
  {
    selecionaEspecialidade(e) {
      this.$nextTick(
        () => {
          let data = specializationAreas.value.find(f => f.id == e)
          if (data != undefined) {
            specializationAreaSelectedId = data.id;
            this.specializationAreaId = data.id;
            this.specializationAreaName = data.description;
            
          }
        }
      )
    },
    onFileChange(event) {
            this.files = event.target.files;
            this.errorMessage = '';
    },
//Submit
    onSubmit(values){
            const config =  {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                };
//JSON Professional

            const data = {
            "id": this.professionalId,
            "people": {
                "id": this.professionalPeopleId,
                "checked": true,
                "name": {
                "firstName": this.professionalFirstName + " " + this.professionalMiddleName,
                "lastName": this.professionalSurName,
                "socialName": this.professionalFirstName
                },
                "emails": [ 
                {
                    "address": this.professionalEmail,
                    "isPrincipal": true,
                    "isVerified": true
                }],
                "addresses": [
                {
                    "zipCode": this.professionalZipCode,
                    "street": this.professionalStreet,
                    "number": this.professionalNumber,
                    "complement": this.professionalComplement,
                    "neighborhoodId": this.professionalNeighborhoodId,
                    "neighborhoodName": this.professionalNeighborhoodName,
                    "cityId": this.professionalCityId,
                    "cityName": this.professionalCityName,
                    "stateId": this.professionalStateId,
                    "stateName": this.professionalStateName,
                    "countryId": this.professionalCountryId,
                    "countryName": this.professionalCountryName,
                    "latitude": "string",
                    "longitude": "string",
                    "geolocationLastUpdade": "2024-09-06T19:06:31.082Z",
                    "peopleAddressTypeId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
                }],
                "birthdate": "2024-09-06T19:06:31.081Z",
                "peopleProducts": [
                {
                    "id": "string",
                    "productId": process.env.VUE_APP_PRODUCT_ID,
                    "modules": [
                    {
                        "id": process.env.VUE_APP_MODULE_ID,
                        "statusId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                        "roles": [
                        process.env.VUE_APP_PROFESSIONALROLE_ID
                        ],
                        "keyCloakClientId": process.env.VUE_APP_KEYCLOAKCLIENT_ID
                    }
                    ]
                }
                ],
                "username": this.professionalUsername
            },
            "username": this.professionalUsername,
            "password": this.professionalPassword,
            "createUser": true,
            "peopleId": this.professionalPeopleId,
            "name": this.professionalFirstName + " " + this.professionalMiddleName + " " + this.professionalSurName,
            "birthDate": "2024-09-06T19:06:31.081Z",
            "email": this.professionalEmail,
            "phoneNumber": this.phone,
            "rg": this.professionalRG,
            "cpf": this.professionalCPF,
            "cnpj": this.professionalCNPJ,
            "education": "string",
            "specializationArea": this.specializationAreaName,
            "specializationAreaId": this.specializationAreaId,
            "serviceDescription": this.aboutServices,
            "atuationAddresses": [
                {
                "zipCode": this.professionalZipCode,
                "street": this.professionalStreet,
                "number": this.professionalNumber,
                "complement": this.professionalComplement,
                "neighborhoodId": this.professionalNeighborhoodId,
                "neighborhoodName": this.professionalNeighborhoodName,
                "cityId": this.professionalCityId,
                "cityName": this.professionalCityName,
                "stateId": this.professionalStateId,
                "stateName": this.professionalStateName,
                "countryId": this.professionalCountryId,
                "countryName": this.professionalCountryName,
                "latitude": "string",
                "longitude": "string",
                "geolocationLastUpdade": "2024-09-06T19:06:31.082Z",
                "peopleAddressTypeId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
                }
            ],
            "atuationDistance": 0,
            "virtualConsulting": false,
            "contactAddress": {
                "zipCode": this.professionalZipCode,
                "street": this.professionalStreet,
                "number": this.professionalNumber,
                "complement": this.professionalComplement,
                "neighborhoodId": this.professionalNeighborhoodId,
                "neighborhoodName": this.professionalNeighborhoodName,
                "cityId": this.professionalCityId,
                "cityName": this.professionalCityName,
                "stateId": this.professionalStateId,
                "stateName": this.professionalStateName,
                "countryId": this.professionalCountryId,
                "countryName": this.professionalCountryName,
                "latitude": "string",
                "longitude": "string",
                "geolocationLastUpdade": "2024-09-06T19:06:31.082Z",
                "peopleAddressTypeId": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
            },
            "tags": "string",
            "isActive": false
            };

//JSON Professional


            // Adiciona um interceptor de requisição
            axios.interceptors.request.use(request => {
            // Constrói a URL completa
            const fullUrl = `${request.baseURL || ''}${request.url}`;
            console.log('URL completa:', fullUrl);
            
            // Exibe o corpo da requisição
            console.log('Corpo da requisição:', request.data);
            
            return request;
            }, error => {
            return Promise.reject(error);
            });

            axios.put('/Professional',data,config)
                .then(async (response) => {
                    console.log('Resposta:', response.data);
                    console.log('Status:', response.status);
                    this.uploadFiles();
                })
                .catch(error => {
                    if (error.response) 
                    {
                        // O servidor respondeu com um status diferente de 2xx
                        console.error('Erro de resposta:', error.response.status);
                        console.error('Dados do erro:', error.response.data);
                    } else if (error.request) 
                    {
                        // A requisição foi feita, mas não houve resposta
                        console.error('Erro de requisição:', error.request);
                        } else {
                        // Algo aconteceu ao configurar a requisição
                        console.error('Erro:', error.message);
                        }
                });
            //window.location.replace("/onboarding-professional-verification");


        },
        //Submit
        // Upload File
        async uploadFiles() {

          const sasToken = process.env.VUE_APP_BLOBSTORAGECUSTOMER_TOKEN;
          const containerName = process.env.VUE_APP_BLOBSTORAGECUSTOMER_CONTAINERNAME;
          const blobServiceClient = new BlobServiceClient(process.env.VUE_APP_BLOBSTORAGECUSTOMER_URL+sasToken);
          const containerClient = blobServiceClient.getContainerClient(containerName);

          for (let file of this.files) {

              // Separar o nome base e a extensão
              const fileExtension = file.name.split('.').pop();
              const baseName = this.professionalId || file.name.replace(/\.[^/.]+$/, "");
              const fileName = 'professionalDoc_' + `${baseName}.${fileExtension}`;

              const blockBlobClient = containerClient.getBlockBlobClient(fileName);
              try {   
                  await blockBlobClient.uploadBrowserData(file);
                  console.log(`${file.name} Upload com sucesso`);
                  window.location.replace("/onboarding-professional-payments");
              } catch (error) {
                  console.error(`Erro de upload ${file.name}:`, error);
                  return 'Erro';
              }
          }
        }
          // Upload File        
  }
};


</script>
