<template>
  <section class="neraby-nurses-sec">
    <div class="container">
      <div class="section-head-fourteen">
        <h2>Veja nossas <span> Especialidades </span></h2>
        <p>Encontre um profissional próximo a você!</p>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="nurse-profile-slider owl-carousel">
            <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
              <Slide v-for="item in specializationAreas" :key="item.id">
                <div class="nurse-profile nurse-card">
                  <div class="nurse-img">
                    <!--<router-link to="/doctor-profile"
                      ><img
                        :src="require(`@/assets/img/nurses/${record.Image}`)"
                        alt="Img"
                    /></router-link>
                    <span class="badge">{{ record.Experience }}</span>-->
                    <span class="fav-item img-top-item">
                      <a href="javascript:void(0)" class="fav-icon">
                        <i class="feather-heart"></i>
                      </a>
                    </span>
                  </div>
                  <div class="nurse-pofile-info text-start">
                    <div class="d-flex justify-content-between nurse-content">
                      <div class="nurse-name">
                        <h5>
                          <a v-bind:href="`/pesquisa?e=${item.description}`" class="">{{
                            item.description
                            }}</a>
                        </h5>

                      </div>

                    </div>

                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
          <div class="owl-nav-button">
            <div class="owl-nav nurse-slide-nav nav-control"></div>
            <router-link to="/especialidades" class="view-all">Veja todas as especialidades</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import { ref } from "vue";

const specializationAreas = ref();

export default {
  mounted() {
    specializationAreas.value = this.especialidades;
  },
  data() {
    return {
      specializationAreas,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "start",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  props: {
    especialidades: Object
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
