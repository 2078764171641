<template>
    <!-- Onboarding Email Otp -->
    <div class="onboard-wrapper">

        <onboarding-professional-slider></onboarding-professional-slider>

        <div class="right-panel">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 p-0">
                        <div class="right-panel-title text-center">
                            <router-link to="/"> <img src="@/assets/img/logo-light.png"
                                    alt="saascare-logo"></router-link>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <onboarding-professional-sidebar></onboarding-professional-sidebar>
                    </div>
                    <div class="col-lg-8 col-md-12">
                        <div class="onboarding-content-box content-wrap">
                            <div class="onboard-set">
                                <div class="onboarding-title">
                                    <h2>Informe o código de 6 dígitos que enviamos no seu e-mail.</h2>
                                    <h6>Nós enviamos o código para {{ emailValidation }}</h6>
                                </div>
                                <div class="onboarding-content passcode-wrap">
                                    <div class="d-flex digit-group">
                                        <Form :validation-schema="schema" @submit="onSubmit" id="formDigits">
                                            <Field name="digits" type="digits" :rules="validateDigits"
                                                class="form-control " />
                                            <ErrorMessage name="digits" />
                                            <p v-if="errors.length">
                                            <ul>
                                                <li v-for="error in errors">{{ error }}</li>
                                            </ul>
                                            </p>
                                        </Form>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <div class="otp-info">
                                        <div class="otp-code">
                                            <p>Não recebeu o código?</p>
                                            <button @click="resendCode" name="resendBtn" id="resendBtn">Reenviar
                                                código</button>
                                        </div>
                                        <div class="otp-sec">
                                            <p id="timer" name="timer">
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="onboarding-btn">
                                <button type="submit" form="formDigits">Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Onboarding Email Otp -->
</template>
<script>
let emailValidation = localStorage.getItem("emailValidation");
let professionalPassword = localStorage.getItem("professionalPassword");
let professionalUsername = localStorage.getItem("professionalUsername");
let professionalEmail = localStorage.getItem("professionalEmail");

let timer;
let countdown = 60; // Set the countdown duration in seconds
import Vue from 'vue'
import axios from 'axios';
import { Form, Field, ErrorMessage } from 'vee-validate';
export default {
    data() {
        return {
            Euro: ["Euro", "INR", "Dinar", "ring"],
            emailValidation,
            errors: [],
            professionalPassword,
            professionalUsername,
            professionalEmail
        }
    },
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    methods: {
        startResendTimer() {
            // Disable the button during the countdown
            document.getElementById('resendBtn').disabled = true;

            // Start the countdown
            timer = setInterval(this.updateTimer, 1000);
        },
        updateTimer() {
            const timerElement = document.getElementById('timer');

            if (countdown > 0) {
                timerElement.textContent = `<i class="feather-clock"></i> Você poderá solicitar um novo código em ${countdown} segundos.`;
                countdown--;
            } else {
                // Enable the button when the countdown reaches zero
                document.getElementById('resendBtn').disabled = false;
                timerElement.textContent = '';

                // Reset countdown for the next attempt
                countdown = 60;

                // Stop the timer
                clearInterval(timer);
            }
        },
        resendCode() {
            this.startResendTimer();
            axios.get(`/BasicSearches/generateEmailConfirmation/${emailValidation}`);
        },
        onSubmit(values) {
            this.errors = [];
            if (this.validateDigits(values.digits)) {
                axios.get(`/BasicSearches/ValidateEmailCode/${emailValidation}/${values.digits}`)
                    .then(async (response) => {
                        if (response.data.data != true) {
                            this.errors.push('Código de validação inválido');
                        } else {
                            this.sendPostRequest();
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        this.errors.push('Erro ao validar o código: ' + error.message);
                    });
            }
        },
        validateDigits(value) {
            this.errors = [];
            // if the field is empty
            if (!value) {
                return 'O campo é obrigatório';
            }
            // if the field is not a valid email
            const regex = /^[0-9]\d{5}$/i;
            if (!regex.test(value)) {
                return 'Informe um código válido';
            }

            // All is good
            return true;
        },
//Inicio do Post de inclusão de usuário
        async sendPostRequest() 
        {
            this.errors = [];
            try {
                    const data = {
                        username: this.professionalUsername,
                        password: this.professionalPassword,
                        email: this.professionalEmail,
                        productID: process.env.VUE_APP_PRODUCT_ID,
                        moduleID: process.env.VUE_APP_MODULE_ID,
                        roleID: process.env.VUE_APP_PROFESSIONALROLE_ID,
                        keycloakclientID: process.env.VUE_APP_KEYCLOAKCLIENT_ID
                    };
                    //const response = await axios.post(`/BasicSearches/Professional/onboardingProfessional/${encodeURIComponent(this.professionalUsername)}/${encodeURIComponent(this.professionalEmail)}/${encodeURIComponent(process.env.VUE_APP_PRODUCT_ID)}/${encodeURIComponent(process.env.VUE_APP_MODULE_ID)}/${encodeURIComponent(process.env.VUE_APP_PROFESSIONALROLE_ID)}/${encodeURIComponent(process.env.VUE_APP_KEYCLOAKCLIENT_ID)}`,data);
                    const response = await axios.post(`/BasicSearches/Professional/onboardingProfessional`,data);
                    const onboardingProfessional = response.data;
                    localStorage.setItem('professionalPeopleId', onboardingProfessional.data.peopleId);
                    localStorage.setItem('professionalId', onboardingProfessional.data.id);
                    //${encodeURIComponent(this.professionalPassword)}/
                    const responsetoken = await axios.post(`/getToken`, {
                    moduleId: process.env.VUE_APP_MODULE_ID,
                    username: this.professionalUsername, 
                    password: this.professionalPassword,  
                    refreshToken: ''
                    });
                    const token = responsetoken.data.data.data.access_token;
                    localStorage.setItem('token', token);
                    window.location.replace("onboarding-professional-phone");
                } catch (error) {
                    console.error(error);
                    this.errors.push('Erro ao incluir o usuário. Tente mais tarde.');
                }
        }
//Fim do Post de inclusão de usuário
    },
}
</script>