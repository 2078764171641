<template>
    <div class="on-board-wizard">
        <ul>
            <li>
                <router-link to="/onboarding-email">
                    <div class="onboarding-progress"
                        :class="{ active: (isActive('/onboarding-email') || isActive('/onboarding-email-otp') || isActive('/onboarding-phone') || isActive('/onboarding-phone-otp')) }">
                        <span>1</span>
                    </div>
                    <div class="onboarding-list">
                        <h6>Criar Conta</h6>
                        <p>Informe seus dados para logar no SaaSCare</p>
                        <br />
                        <router-link to="/onboarding-email" class="on-board-wizard-internal">
                            <div :class="isSuccess('emailValidation') ? 'success-onboarding' : 'pending-onboarding'">
                                <p class="text-success"><i class="fas "
                                        :class="isSuccess('emailValidation') ? 'fa-check' : 'fa-exclamation'"></i>
                                    <strong>Email</strong>
                                </p>
                            </div>
                        </router-link>
                        <router-link to="/onboarding-email-otp" class="on-board-wizard-internal">
                            <div :class="isSuccess('emailValid') ? 'success-onboarding' : 'pending-onboarding'">
                                <p class="text-success"><i class="fas "
                                        :class="isSuccess('emailValid') ? 'fa-check' : 'fa-exclamation'"></i>
                                    <strong>Confirmação de
                                        e-mail</strong>
                                </p>
                            </div>
                        </router-link>
                        <router-link to="/onboarding-phone" class="on-board-wizard-internal">
                            <div :class="isSuccess('phone') ? 'success-onboarding' : 'pending-onboarding'">
                                <p class="text-success"><i class="fas "
                                        :class="isSuccess('phone') ? 'fa-check' : 'fa-exclamation'"></i>
                                    <strong>Celular</strong>
                                </p>
                            </div>
                        </router-link>
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/onboarding-avatar">
                    <div class="onboarding-progress"
                        :class="{ active: (isActive('/onboarding-avatar') || isActive('/onboarding-address') || isActive('/onboarding-personal-data')) }">
                        <span>2</span>
                    </div>
                    <div class="onboarding-list">
                        <h6>Informações Pessoais</h6>
                        <p>Informe seus dados pessoais</p>
                        <br />
                        <router-link to="/onboarding-avatar" class="on-board-wizard-internal">
                            <div :class="isSuccess('avatar') ? 'success-onboarding' : 'pending-onboarding'">
                                <p class="text-success"><i class="fas "
                                        :class="isSuccess('avatar') ? 'fa-check' : 'fa-exclamation'"></i>
                                    <strong>Foto de perfil</strong>
                                </p>
                            </div>
                        </router-link>
                        <router-link to="/onboarding-address" class="on-board-wizard-internal">
                            <div :class="isSuccess('address') ? 'success-onboarding' : 'pending-onboarding'">
                                <p class="text-success"><i class="fas "
                                        :class="isSuccess('address') ? 'fa-check' : 'fa-exclamation'"></i>
                                    <strong>Endereço</strong>
                                </p>
                            </div>
                        </router-link>
                        <router-link to="/onboarding-personal-data" class="on-board-wizard-internal">
                            <div :class="isSuccess('personal-data') ? 'success-onboarding' : 'pending-onboarding'">
                                <p class="text-success"><i class="fas "
                                        :class="isSuccess('personal-data') ? 'fa-check' : 'fa-exclamation'"></i>
                                    <strong>Dados Pessoais</strong>
                                </p>
                            </div>
                        </router-link>
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/onboarding-username">

                    <div class="onboarding-progress"
                        :class="{ active: (isActive('/onboarding-username') || isActive('/onboarding-password')) }">
                        <span>3</span>
                    </div>
                    <div class="onboarding-list">
                        <h6>Informações de Login</h6>
                        <p>Informe seus dados de login</p>
                        <br />
                        <router-link to="/onboarding-username" class="on-board-wizard-internal">
                            <div :class="isSuccess('username') ? 'success-onboarding' : 'pending-onboarding'">
                                <p class="text-success"><i class="fas "
                                        :class="isSuccess('username') ? 'fa-check' : 'fa-exclamation'"></i>
                                    <strong>Nome de usuário</strong>
                                </p>
                            </div>
                        </router-link>
                        <router-link to="/onboarding-password" class="on-board-wizard-internal">
                            <div :class="isSuccess('password') ? 'success-onboarding' : 'pending-onboarding'">
                                <p class="text-success"><i class="fas "
                                        :class="isSuccess('password') ? 'fa-check' : 'fa-exclamation'"></i>
                                    <strong>Senha</strong>
                                </p>
                            </div>
                        </router-link>
                    </div>
                </router-link>
            </li>



        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeClass: "active",
        }
    },
    methods: {
        isActive(paths) {
            return paths.includes(this.$route.path);
        },
        isSuccess(step) {
            if (localStorage.hasOwnProperty(step) && localStorage.getItem(step) != undefined && localStorage.getItem(step) != 'undefined') {
                return true;
            }
            else {
                return false;
            }
        },
    },
}
</script>