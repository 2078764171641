const heartCharts = {
  series: [
    {
      data: [140, 100, 180, 130, 100, 130],
    },
  ],
  rateCharts: {
    chart: {
      height: 300,
      type: "bar",
      events: {
        click: function (chart, w, e) {
          // console.log(chart, w, e)
        },
      },
    },
    fill: {
      colors: ["#E8F1FF"],
    },
    plotOptions: {
      bar: {
        columnWidth: "45%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [["Mon"], ["Tue"], ["Wed"], , ["Thu"], ["Fri"], ["Sat"]],
    },
  },
};

const bloodCharts = {
  series: [
    {
      data: [90, 60, 30, 60, 90, 70, 70],
    },
    {
      data: [110, 90, 40, 120, 130, 130, 130],
    },
  ],
  pressureCharts: {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    },

    fill: {
      opacity: 1,
      colors: ["#F1F5F9"],
    },
  },
};

export { heartCharts, bloodCharts };