import { createApp } from 'vue'
import { router } from './router';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'


import App from './App.vue'
import {BootstrapVue3, BToastPlugin} from 'bootstrap-vue-3'
import Antd, { AutoComplete } from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import VueSelect from 'vue3-select2-component'
import VCalendar from 'v-calendar';
import VueFeather from 'vue-feather';
import DatePicker from 'vue3-datepicker';
import VueApexCharts from 'vue3-apexcharts';

import VueTelInput from 'vue-tel-input';
import VueEasyLightbox from "vue-easy-lightbox";
import './plugins/axios';

const vuetify = createVuetify({
    components,
    directives,
  })


// plugins
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'vue3-tel-input/dist/vue3-tel-input.css';

/***********************************************************************/
                     /* Frontend */
/***********************************************************************/


//Breadcrumb
import Breadcrumb from '@/components/frontend/breadcrumb/aboutus.vue'
import DoctorsSidebar from '@/views/frontend/layouts/doctorsidebar.vue'
import BlogSidebar from '@/views/frontend/layouts/blogsidebar.vue'
import PharmacyFilter from '@/views/frontend/layouts/pharmacyfilter.vue'
import Breadcrumb1 from '@/components/frontend/breadcrumb/breadcrumb1.vue'
import Mainmenu from '@/components/frontend/mainmenu.vue'
import Mainnav from '@/components/frontend/mainnav.vue'
import Profilewidget from '@/components/frontend/profilewidget.vue'
import DoctorFooter from '@/views/frontend/layouts/doctor-footer.vue';

//pages
import Header from '@/views/frontend/layouts/header.vue'
import HeaderTwo from  '@/views/frontend/layouts/headertwo.vue'
import Headerinfo from '@/views/frontend/layouts/header-info.vue'
import Cursor from '@/views/frontend/layouts/cursor.vue'
import Scrolltotop from '@/views/frontend/layouts/scrolltotop.vue'
import ProfileSidebar from '@/views/frontend/layouts/profilesidebar.vue'

import Footer from '@/views/frontend/layouts/footer.vue'
import IndexFooter from "@/views/frontend/pages/home/footer.vue";

import home_Banner from '@/views/frontend/pages/home/home-banner.vue'
import How_It_Work from '@/views/frontend/pages/home/how-it-work.vue'
import Service_Section from '@/views/frontend/pages/home/service-section.vue'
import Nurse_Section from '@/views/frontend/pages/home/nurse-section.vue'
import Top_Nurse_Section from '@/views/frontend/pages/home/top-nurse-section.vue'
import Specialization_Section from "@/views/frontend/pages/home/specialization-section.vue";
import Top_Specialization_Section from "@/views/frontend/pages/home/top-specialization-section.vue";
import Work_Section from '@/views/frontend/pages/home/work-section.vue'
import Blog_Section from '@/views/frontend/pages/home/blog-section.vue'
import Pricing_Section from '@/views/frontend/pages/home/pricing-section.vue'
import Faq_Section from '@/views/frontend/pages/home/faq-section.vue'
import IndexFooter_Section from "@/views/frontend/pages/home/footer.vue";
import BlogCarousel from '@/views/frontend/pages/home/blog-carousel.vue'




import BlogdetailComment from '@/views/frontend/pages/blog/blog-details-comment.vue'
import PharmacySlider from '@/views/frontend/pages/pharmacymenu/pharmacy-index/pharmacyslider.vue'
import OverviewContent from '@/views/frontend/pages/pharmacymenu/details/overview-content.vue'
import LocationContent from '@/views/frontend/pages/pharmacymenu/details/location-content.vue'
import ReviewContent from '@/views/frontend/pages/pharmacymenu/details/review-content.vue'
import BusinessContent from '@/views/frontend/pages/pharmacymenu/details/business-content.vue'
import productdetails from '@/views/frontend/pages/pharmacymenu/product-description/product-details.vue'
import productdescriptionsidebar from '@/views/frontend/pages/pharmacymenu/product-description/product-description-sidebar.vue'
import Medlifemedical from '@/views/frontend/pages/pharmacymenu/product/medlifemedical.vue'
import Cart_Section from '@/views/frontend/pages/pharmacymenu/pharmacy-index/cart-section.vue'
import Top_Header from '@/views/frontend/pages/pharmacymenu/pharmacy-index/top-header.vue'
import Pharmacy_Banner from '@/views/frontend/pages/pharmacymenu/pharmacy-index/pharmacy-banner.vue'
import Welcome_Section from '@/views/frontend/pages/pharmacymenu/pharmacy-index/welcome-section.vue'
import Deals_Section from '@/views/frontend/pages/pharmacymenu/pharmacy-index/deals-section.vue'
import Categories_Section from '@/views/frontend/pages/pharmacymenu/pharmacy-index/categories-section.vue'
import Seller_Section from '@/views/frontend/pages/pharmacymenu/pharmacy-index/seller-section.vue'
import Feature_Section from '@/views/frontend/pages/pharmacymenu/pharmacy-index/feature-section.vue'
import Product_Section from '@/views/frontend/pages/pharmacymenu/pharmacy-index/product-section.vue'
import Coupon_Section from '@/views/frontend/pages/pharmacymenu/pharmacy-index/coupon-section.vue'
import Best_Section from '@/views/frontend/pages/pharmacymenu/pharmacy-index/best-section.vue'
import Trusted_Section from '@/views/frontend/pages/pharmacymenu/pharmacy-index/trusted-section.vue'
import Review_Section from '@/views/frontend/pages/pharmacymenu/pharmacy-index/review-section.vue'
import News_Section from '@/views/frontend/pages/pharmacymenu/pharmacy-index/news-section.vue'
import App_Section from '@/views/frontend/pages/pharmacymenu/pharmacy-index/app-section.vue'
import Footer_Section from '@/views/frontend/pages/pharmacymenu/pharmacy-index/footer-section.vue'
import AppointmentMedications from '@/views/frontend/pages/doctors/appointments/appointment-medications.vue';
import ChatImage from '@/views/frontend/pages/doctors/chat/chat-image.vue'


//Patient Pages
import PatientFooter from '@/views/frontend/pages/patients/patientfooter.vue'
import Searchlist from '@/views/frontend/pages/patients/searchdoctor/searchlist.vue'
import search2filter from '@/views/frontend/pages/patients/searchdoctor/search2/search2filter.vue'
import search2map from '@/views/frontend/pages/patients/searchdoctor/search2/search2map.vue'
import Profile_Documentation from '@/views/frontend/pages/patients/doctorprofile/profile-documentation.vue'
import locations from '@/views/frontend/pages/patients/doctorprofile/doctor-profile-2/locations.vue'
import overview from '@/views/frontend/pages/patients/doctorprofile/doctor-profile-2/overview.vue'
import reviews from '@/views/frontend/pages/patients/doctorprofile/doctor-profile-2/reviews.vue'
import businesshours from '@/views/frontend/pages/patients/doctorprofile/doctor-profile-2/businesshours.vue'
import Doctorwidget from '@/views/frontend/pages/patients/doctors/maplist/doctorwidget.vue'
import Medical_Prescription from '@/views/frontend/pages/patients/medical-records/medical-prescription.vue'
import Medical_Records_Table from '@/views/frontend/pages/patients/medical-records/medical-records-table.vue'
import Refund_Request from '@/views/frontend/pages/patients/accounts/refund-request.vue'
import Account_Request from '@/views/frontend/pages/patients/accounts/account-request.vue'
import Appointments_Filter from '@/views/frontend/pages/patients/patient-appointments/appointments-filter.vue'
import Patients_Upcoming from '@/views/frontend/pages/patients/patient-appointments/patients-upcoming.vue'
import Patients_Cancelled from '@/views/frontend/pages/patients/patient-appointments/patients-cancelled.vue'
import Patients_Completed from '@/views/frontend/pages/patients/patient-appointments/patients-completed.vue'
import Patients_Grid_Upcoming from '@/views/frontend/pages/patients/patient-appointments/patients-grid-upcoming.vue'
import Patients_Grid_Cancelled from '@/views/frontend/pages/patients/patient-appointments/patients-grid-cancelled.vue'
import Patients_Grid_Completed from '@/views/frontend/pages/patients/patient-appointments/patients-grid-completed.vue'
import Health_Records from '@/views/frontend/pages/patients/dashboard/health-records.vue'
import Dashboard_Analytics from '@/views/frontend/pages/patients/dashboard/dashboard-analytics.vue';
import Dashboard_Appointment from '@/views/frontend/pages/patients/dashboard/dashboard-appointment.vue';
import Dashboard_Reports from '@/views/frontend/pages/patients/dashboard/dashboard-reports.vue';
import Profile_Insurence from '@/views/frontend/pages/patients/doctorprofile/profile-insurence.vue'
import Profile_Availability from '@/views/frontend/pages/patients/doctorprofile/profile-availability.vue'
import Profile_Awards from '@/views/frontend/pages/patients/doctorprofile/profile-awards.vue'

//Doctors
import DoctorSidebar from '@/views/frontend/layouts/doctorsidebar.vue'
import Settings_Tabs from '@/views/frontend/pages/doctors/doctor-profile-settings/settings-tabs.vue'
import DoctorModel from '@/views/frontend/pages/doctors/doctorblog/doctormodel.vue'
import Accounts_Tab from '@/views/frontend/pages/doctors/accounts/accounts-tab.vue'
import Refund_Request_Tab from '@/views/frontend/pages/doctors/accounts/refund-request-tab.vue'
import OnBoardingSidebar from '@/views/frontend/pages/onboard/onboarding-sidebar.vue'
import OnBoardingSlider from '@/views/frontend/pages/onboard/onboarding-slider.vue'
import OnBoardingProfessionalSidebar from "@/views/frontend/pages/onboard-professional/onboarding-sidebar.vue";
import OnBoardingProfessionalSlider from "@/views/frontend/pages/onboard-professional/onboarding-slider.vue";
import Upcoming from '@/views/frontend/pages/doctors/appointments/upcoming.vue';
import Cancelled from '@/views/frontend/pages/doctors/appointments/cancelled.vue';
import Completed from '@/views/frontend/pages/doctors/appointments/completed.vue'
import UpcomingGrid from '@/views/frontend/pages/doctors/appointments/upcoming-grid.vue';
import CancelledGrid from '@/views/frontend/pages/doctors/appointments/cancelled-grid.vue';
import CompletedGrid from '@/views/frontend/pages/doctors/appointments/completed-grid.vue'
import GeneralAvailability from '@/views/frontend/pages/doctors/scheduletiming/general-availability.vue';
import ClinicAvailability from '@/views/frontend/pages/doctors/scheduletiming/clinic-availability.vue';
import MyPatientsActive from '@/views/frontend/pages/doctors/patients/my-patients-active.vue'
import MyPatientsInactive from '@/views/frontend/pages/doctors/patients/my-patients-inactive.vue';
import PatientProfileAppointment from '@/views/frontend/pages/doctors/patients/appointment.vue'
import PatientProfilePrescription from '@/views/frontend/pages/doctors/patients/prescription.vue'
import PatientProfileMedical from '@/views/frontend/pages/doctors/patients/medical-records.vue'
import PatientProfileBilling from '@/views/frontend/pages/doctors/patients/billing.vue'

// Modal

import DoctorRequestModal from '@/components/frontend/modal/doctor-request-modal.vue'
import AppointmentCancelModal from '@/components/frontend/modal/appointment-cancel-modal.vue';
import CompletedAppointmentModal from '@/components/frontend/modal/completed-appointment-modal.vue';
import AppointmentStartModal from '@/components/frontend/modal/appointment-start-modal.vue';
import AvailabilityTimingsModal from '@/components/frontend/modal/availability-timings-modal.vue';
import MyPatientsModal from '@/components/frontend/modal/my-patients-modal.vue';
import PatientProfileModal from '@/components/frontend/modal/patient-profile-modal.vue';
import ChatDoctorModal from '@/components/frontend/modal/chat-doctor-modal.vue';
import Doctor_Payment_Modal from '@/components/frontend/modal/doctor-payment-modal.vue'
import Accounts_Modal from '@/components/frontend/modal/accounts-modal.vue'
import Medical_Details_Modal from '@/components/frontend/modal/medical-details-modal.vue'
import Patient_Invoices_Modal from '@/components/frontend/modal/patient-invoices-modal.vue'
import Patient_Accounts_Modal from '@/components/frontend/modal/patient-accounts-modal.vue'
import Dependent_Modal from '@/components/frontend/modal/dependent-modal.vue'
import Medical_Records_Modal from '@/components/frontend/modal/medical-records-modal.vue'
import Patient_Appointment_Details_Modal from '@/components/frontend/modal/patient-appointment-details-modal.vue'
import Patient_Dashboard_Modal from '@/components/frontend/modal/patient-dashboard-modal.vue'




const app = createApp(App).use(vuetify);

// Breadcrumb

app.component('breadcrumb',Breadcrumb)
app.component('breadcrumb1',Breadcrumb1)
app.component('doctors-sidebar',DoctorsSidebar)
app.component('blogsidebar',BlogSidebar)
app.component('mainmenu',Mainmenu)
app.component('mainnav',Mainnav)
app.component('profilewidget',Profilewidget)
app.component('doctor-footer', DoctorFooter)


//pages
app.component('layoutheader', Header)
app.component('layoutheadertwo',HeaderTwo)
app.component('headerinfo',Headerinfo)
app.component('cursor',Cursor)
app.component('scrolltotop',Scrolltotop)
app.component('footerindex', Footer)
app.component("indexfooter", IndexFooter);

app.component('home-banner',home_Banner)
app.component('how-it-work',How_It_Work)
app.component('service-section',Service_Section)
app.component('nurse-section',Nurse_Section)
app.component("specialization-section", Specialization_Section);
app.component('top-specialization-section',Top_Specialization_Section)
app.component("top-nurse-section", Top_Nurse_Section);
app.component('work-section',Work_Section)
app.component('blog-section',Blog_Section)
app.component('pricing-section',Pricing_Section)
app.component('faq-section',Faq_Section)
app.component('indexfooter-section', IndexFooter_Section)
app.component('blog-carousel', BlogCarousel)
app.component('blogdetailscomment',BlogdetailComment)
app.component('pharmacyslider',PharmacySlider)
app.component('overview-content',OverviewContent)
app.component('location-content', LocationContent)
app.component('review-content', ReviewContent)
app.component('business-content',BusinessContent)
app.component('pharmacy-filter',PharmacyFilter)
app.component('medlifemedical',Medlifemedical)
app.component('productdescriptionsidebar',productdescriptionsidebar)
app.component('productdetails',productdetails)
app.component('cart-section',Cart_Section)
app.component('top-header',Top_Header)
app.component('pharmacy-banner',Pharmacy_Banner)
app.component('welcome-section',Welcome_Section)
app.component('deals-section',Deals_Section)
app.component('categories-section',Categories_Section)
app.component('seller-section',Seller_Section)
app.component('feature-section',Feature_Section)
app.component('product-section',Product_Section)
app.component('coupon-section',Coupon_Section)
app.component('best-section',Best_Section)
app.component('trusted-section',Trusted_Section)
app.component('review-section',Review_Section)
app.component('news-section',News_Section)
app.component('app-section',App_Section)
app.component('footer-section',Footer_Section)
app.component('appointment-medications', AppointmentMedications)
app.component('chat-image', ChatImage)


//Patient pages
app.component('profilesidebar',ProfileSidebar)
app.component('patientfooter',PatientFooter)
app.component('searchlist',Searchlist)
app.component('search2filter',search2filter)
app.component('search2map',search2map)
app.component('profile-documentation',Profile_Documentation)
app.component('locations',locations)
app.component('overview',overview)
app.component('reviews',reviews)
app.component('businesshours',businesshours)
app.component('doctorwidget',Doctorwidget)
app.component('medical-prescription',Medical_Prescription)
app.component('medical-records-table',Medical_Records_Table)
app.component('refund-request',Refund_Request)
app.component('account-request',Account_Request)
app.component('appointments-filter',Appointments_Filter)
app.component('patients-upcoming',Patients_Upcoming)
app.component('patients-cancelled',Patients_Cancelled)
app.component('patients-completed',Patients_Completed)
app.component('patients-grid-upcoming',Patients_Grid_Upcoming)
app.component('patients-grid-cancelled',Patients_Grid_Cancelled)
app.component('patients-grid-completed',Patients_Grid_Completed)
app.component('dashboard-reports',Dashboard_Reports)
app.component('profile-insurence',Profile_Insurence)
app.component('profile-availability',Profile_Availability)
app.component('profile-awards',Profile_Awards)
app.component('dashboard-appointment',Dashboard_Appointment)
app.component('dashboard-analytics',Dashboard_Analytics)
app.component('health-records',Health_Records)



//Doctors
app.component('doctorsidebar',DoctorSidebar)
app.component('doctormodel',DoctorModel)
app.component('settings-tabs',Settings_Tabs)
app.component('accounts-tab',Accounts_Tab)
app.component('refund-request-tab',Refund_Request_Tab)
app.component('onboarding-sidebar', OnBoardingSidebar)
app.component('onboarding-slider', OnBoardingSlider)
app.component("onboarding-professional-sidebar", OnBoardingProfessionalSidebar);
app.component("onboarding-professional-slider", OnBoardingProfessionalSlider);
app.component('upcoming', Upcoming)
app.component('cancelled', Cancelled)
app.component('completed', Completed)
app.component('upcoming-grid', UpcomingGrid)
app.component('cancelled-grid', CancelledGrid)
app.component('completed-grid', CompletedGrid)
app.component('general-availability', GeneralAvailability)
app.component('clinic-availability', ClinicAvailability)
app.component('my-patients-active', MyPatientsActive)
app.component('my-patients-inactive', MyPatientsInactive)
app.component('patient-profile-appointment',PatientProfileAppointment)
app.component('patient-profile-prescription', PatientProfilePrescription)
app.component('patient-profile-medical', PatientProfileMedical)
app.component('patient-profile-billing', PatientProfileBilling)

// Modal 
app.component('delete-request-modal', DoctorRequestModal)
app.component('appointment-cancel-modal', AppointmentCancelModal)
app.component('completed-appointment-modal', CompletedAppointmentModal)
app.component('appointment-start-modal', AppointmentStartModal)
app.component('availability-timings-modal', AvailabilityTimingsModal)
app.component('my-patients-modal', MyPatientsModal)
app.component('patient-profile-modal', PatientProfileModal)
app.component('chat-doctor-modal', ChatDoctorModal)
app.component('medical-details-modal', Medical_Details_Modal)
app.component('doctor-payment-modal',Doctor_Payment_Modal)
app.component('accounts-modal',Accounts_Modal)
app.component('patient-invoices-modal',Patient_Invoices_Modal)
app.component('patient-accounts-modal',Patient_Accounts_Modal)
app.component('dependent-modal',Dependent_Modal)
app.component('medical-records-modal',Medical_Records_Modal)
app.component('patient-appointment-details-modal',Patient_Appointment_Details_Modal)
app.component('patient-dashboard-modal',Patient_Dashboard_Modal)




app.component('vue-select', VueSelect)
app.component(VueFeather.name, VueFeather)
app.component('datepicker', DatePicker)
app.use(VueApexCharts)
app.use(VueEasyLightbox)
.use(VueTelInput)
.use(BootstrapVue3)
.use(BToastPlugin)
.use(Antd)
app.use(VCalendar, {})
app.use(router).mount('#app');
