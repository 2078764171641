<template>
  <div class="main-wrapper home-pg-fourteen">
    <layoutheader :class="{ 'header-space pharmacy-header': headerBackground !== 'transparent' }" />

    <home-banner :especialidades="specializationAreas" :key="specializationAreas"></home-banner>

    <!--
    
      <specialization-section :especialidades="specializationAreas" :key="specializationAreas"></specialization-section>
    
    <how-it-work></how-it-work>

    <service-section></service-section>

    <top-nurse-section></top-nurse-section>

    <work-section></work-section>

    <nurse-section></nurse-section>

    <blog-section></blog-section>

    <pricing-section></pricing-section>

    <faq-section></faq-section>-->

    <indexfooter-section></indexfooter-section>

    <cursor />
    <scrolltotop />
  </div>
</template>

<script>
import axios from 'axios';
import { onServerPrefetch, ref } from "vue";

const specializationAreas = ref();



export default {

  data() {
    return {
      headerBackground: "transparent",
      specializationAreas
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    axios.get('SpecializationArea?page=1&pageSize=100')
      .then((response) => {
        specializationAreas.value = response.data.data;
      });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scroll =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // Update the background color based on the scroll position
      this.headerBackground = scroll > 95 ? "#ffffff" : "transparent";
    },
  },
};
</script>