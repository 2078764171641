<template>
    <!-- Onboarding Phone -->
    <div class="onboard-wrapper">
        <onboarding-professional-slider></onboarding-professional-slider>
        <div class="right-panel">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 p-0">
                        <div class="right-panel-title text-center">
                            <router-link to="/"> <img src="@/assets/img/onboard-img/onb-logo.png"
                                    alt="Image"></router-link>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <onboarding-professional-sidebar></onboarding-professional-sidebar>
                    </div>
                    <div class="col-lg-8 col-md-12">
                        <div class="onboarding-content-box content-wrap">
                            <div class="onboard-set">
                                <div class="onboarding-title">
                                    <h2>Qual seu celular principal? <span>*</span></h2>
                                    <h6>Usaremos ele apenas para informações importantes. </h6>
                                </div>
                                <div class="onboarding-content passcode-wrap mail-box">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <Form @submit="submitForm" id="formPhone">
                                                    <vue-tel-input v-model="phone"
                                                        invalid-msg="Número de telefone inválido"
                                                        class="form-control form-control-lg group_formcontrol mail-icon2"
                                                        id="phone" name="phone" mode="international" required
                                                        :inputOptions="inputOptions" @validate="validatePhone" />
                                                    <p v-if="errorMessage">{{ errorMessage }}</p>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="onboarding-btn">
                                <button type="submit" form="formPhone">Continuar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- /Onboarding Phone -->
</template>
<script>
let professionalPassword = localStorage.getItem("professionalPassword");
let professionalUsername = localStorage.getItem("professionalUsername");
let professionalEmail = localStorage.getItem("professionalEmail");
let token = localStorage.getItem('token');

//import { ref } from 'vue';
import { ref, onMounted } from 'vue';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import { Form } from 'vee-validate';
import { errorMessages } from '@vue/compiler-sfc';
import { useRouter, useRoute } from 'vue-router';
const validate = ref({});
export default {
    data() {
        return {
            validate,
            inputOptions: { placeholder: 'Informe seu celular principal' },
            professionalPassword,
            professionalUsername,
            professionalEmail
        }
    },
    created() {
        if (!token) {
            this.$router.push('/login'); // Redireciona para a página de login se não estiver autenticado
        }
    },
    components: {
        VueTelInput,
        Form,
    },
    methods: {
        onBack() {
            this.$router.go(-1);
        },
    },
    setup() {
        const router = useRouter();
        const route = useRoute();
        const phone = ref(null);
        const isValid = ref(true);
        const errorMessage = ref('');
        //const isPhoneValid = ref(false);
        //const validatePhone = (isValidPhone) => { isValid.value = isValidPhone && phone.value !== null && phone.value !== '';};
        const validatePhone = (isValid) => {
            validate.value = isValid;
            if (isValid.valid === undefined) {
                return;
            } else {
                if (!isValid) {
                    errorMessage.value = 'Número de telefone inválido';
                } else {
                    errorMessage.value = '';
                }
            }
        };

        const submitForm = () => {
            if (!validate.value.valid) {
                errorMessage.value = 'Por favor, insira um número de telefone válido';
            } else {
                // Lógica para enviar o formulário
                errorMessage.value = '';
                console.log('Formulário enviado com sucesso!', validate.value);
                localStorage.setItem("phone", validate.value.number);
                window.location.replace("onboarding-professional-avatar");
                //router.push({ path: "/onboarding-username" });
            }
        };
        onMounted(async () => {

        });
        return {
            errorMessage,
            isValid,
            validatePhone,
            phone,
            submitForm,
        };
    }
}
</script>