<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb1 :title="title" :text="text" :text1="text1" />

    <!-- Page Content -->
    <div class="content">
      <div class="container">

          <div class="row">
            <doctorsidebar></doctorsidebar>

            <div class="col-md-7 col-lg-8 col-xl-9">
              <div class="dashboard-header">
                <h3>Appointments</h3>
                <ul class="header-list-btns">
                  <li>
                    <div class="input-block dash-search-input">
                      <input type="text" class="form-control" placeholder="Search">
                      <span class="search-icon"><i class="fa-solid fa-magnifying-glass"></i></span>
                    </div>
                  </li>
                  <li>
                    <div class="view-icons">
                      <router-link to="/appointments" class="active"><i class="fa-solid fa-list"></i></router-link>
                    </div>
                  </li>
                  <li>
                    <div class="view-icons">
                      <router-link to="/doctor-appointments-grid"><i class="fa-solid fa-th"></i></router-link>
                    </div>
                  </li>
                  <li>
                    <div class="view-icons">
                      <a href=""><i class="fa-solid fa-calendar-check"></i></a>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="appointment-tab-head">
                <div class="appointment-tabs">
                  <ul class="nav nav-pills inner-tab " id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <button class="nav-link active" id="pills-upcoming-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-upcoming" type="button" role="tab" aria-controls="pills-upcoming"
                        aria-selected="false">Upcoming<span>21</span></button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="pills-cancel-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-cancel" type="button" role="tab" aria-controls="pills-cancel"
                        aria-selected="true">Cancelled<span>16</span></button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button class="nav-link" id="pills-complete-tab" data-bs-toggle="pill"
                        data-bs-target="#pills-complete" type="button" role="tab" aria-controls="pills-complete"
                        aria-selected="true">Completed<span>214</span></button>
                    </li>
                  </ul>
                </div>
                <div class="filter-head">
                  <div class="position-relative daterange-wraper me-2">
                    <div class="input-groupicon calender-input">
                      <input type="text" class="form-control  date-range bookingrange" ref="dateRangeInput"
                        placeholder="From Date - To Date ">
                    </div>
                    <i class="fa-solid fa-calendar-days"></i>
                  </div>
                  <div class="form-sorts dropdown" :class="{ 'table-filter-show': isFilterShown }">
                    <a href="javascript:void(0);" class="dropdown-toggle" id="table-filter" @click="toggleFilter"><i
                        class="fa-solid fa-filter me-2"></i>Filter By</a>
                    <div class="filter-dropdown-menu">
                      <div class="filter-set-view">
                        <div class="accordion" id="accordionExample">
                          <div class="filter-set-content">
                            <div class="filter-set-content-head">
                              <a href="javascript:void(0);" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                aria-expanded="false" aria-controls="collapseTwo">Name<i
                                  class="fa-solid fa-chevron-right"></i></a>
                            </div>
                            <div class="filter-set-contents accordion-collapse collapse show" id="collapseTwo"
                              data-bs-parent="#accordionExample">
                              <ul>
                                <li>
                                  <div class="input-block dash-search-input w-100">
                                    <input type="text" class="form-control" placeholder="Search">
                                    <span class="search-icon"><i class="fa-solid fa-magnifying-glass"></i></span>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="filter-set-content">
                            <div class="filter-set-content-head">
                              <a href="javascript:void(0);" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                aria-expanded="true" aria-controls="collapseOne">Appointment
                                Type<i class="fa-solid fa-chevron-right"></i></a>
                            </div>
                            <div class="filter-set-contents accordion-collapse collapse show" id="collapseOne"
                              data-bs-parent="#accordionExample">
                              <ul>
                                <li>
                                  <div class="filter-checks">
                                    <label class="checkboxs">
                                      <input type="checkbox" checked>
                                      <span class="checkmarks"></span>
                                      <span class="check-title">All Type</span>
                                    </label>
                                  </div>
                                </li>
                                <li>
                                  <div class="filter-checks">
                                    <label class="checkboxs">
                                      <input type="checkbox">
                                      <span class="checkmarks"></span>
                                      <span class="check-title">Video Call</span>
                                    </label>
                                  </div>
                                </li>
                                <li>
                                  <div class="filter-checks">
                                    <label class="checkboxs">
                                      <input type="checkbox">
                                      <span class="checkmarks"></span>
                                      <span class="check-title">Audio Call</span>
                                    </label>
                                  </div>
                                </li>
                                <li>
                                  <div class="filter-checks">
                                    <label class="checkboxs">
                                      <input type="checkbox">
                                      <span class="checkmarks"></span>
                                      <span class="check-title">Chat</span>
                                    </label>
                                  </div>
                                </li>
                                <li>
                                  <div class="filter-checks">
                                    <label class="checkboxs">
                                      <input type="checkbox">
                                      <span class="checkmarks"></span>
                                      <span class="check-title">Direct Visit</span>
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="filter-set-content">
                            <div class="filter-set-content-head">
                              <a href="javascript:void(0);" data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                aria-expanded="false" aria-controls="collapseThree">Visit Type<i
                                  class="fa-solid fa-chevron-right"></i></a>
                            </div>
                            <div class="filter-set-contents accordion-collapse collapse show" id="collapseThree"
                              data-bs-parent="#accordionExample">
                              <ul>
                                <li>
                                  <div class="filter-checks">
                                    <label class="checkboxs">
                                      <input type="checkbox" checked>
                                      <span class="checkmarks"></span>
                                      <span class="check-title">All Visit</span>
                                    </label>
                                  </div>

                                </li>
                                <li>
                                  <div class="filter-checks">
                                    <label class="checkboxs">
                                      <input type="checkbox">
                                      <span class="checkmarks"></span>
                                      <span class="check-title">General</span>
                                    </label>
                                  </div>

                                </li>
                                <li>
                                  <div class="filter-checks">
                                    <label class="checkboxs">
                                      <input type="checkbox">
                                      <span class="checkmarks"></span>
                                      <span class="check-title">Consultation</span>
                                    </label>
                                  </div>

                                </li>
                                <li>
                                  <div class="filter-checks">
                                    <label class="checkboxs">
                                      <input type="checkbox">
                                      <span class="checkmarks"></span>
                                      <span class="check-title">Follow-up</span>
                                    </label>
                                  </div>

                                </li>
                                <li>
                                  <div class="filter-checks">
                                    <label class="checkboxs">
                                      <input type="checkbox">
                                      <span class="checkmarks"></span>
                                      <span class="check-title">Direct Visit</span>
                                    </label>
                                  </div>

                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <div class="filter-reset-btns">
                          <router-link to="/appointments" class="btn btn-light">Reset</router-link>
                          <router-link to="/appointments" class="btn btn-primary">Filter
                            Now</router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-content appointment-tab-content">
                <div class="tab-pane fade show active" id="pills-upcoming" role="tabpanel"
                  aria-labelledby="pills-upcoming-tab">
                  <upcoming></upcoming>
                </div>
                <div class="tab-pane fade" id="pills-cancel" role="tabpanel" aria-labelledby="pills-cancel-tab">
                  <cancelled></cancelled>
                </div>
                <div class="tab-pane fade" id="pills-complete" role="tabpanel" aria-labelledby="pills-complete-tab">
                  <completed></completed>
                </div>
              </div>
            </div>
          </div>

      </div>
    </div>
    <!-- /Page Content -->
    <doctor-footer></doctor-footer>
  </div>
</template>

<script>
import "daterangepicker/daterangepicker.css";
import "daterangepicker/daterangepicker.js";
import { ref } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import DateRangePicker from "daterangepicker";

export default {
  data() {
    return {
      title: "Appointments",
      text: "Home",
      text1: "Appointments",
      isFilterShown: false
    }
  },
  methods: {
    toggleFilter() {
      this.isFilterShown = !this.isFilterShown;
    }
  },
  setup() {
    const dateRangeInput = ref(null);

    // Move the function declaration outside of the onMounted callback
    function booking_range(start, end) {
      return start.format("M/D/YYYY") + " - " + end.format("M/D/YYYY");
    }

    onMounted(() => {
      if (dateRangeInput.value) {
        const start = moment().subtract(6, "days");
        const end = moment();

        new DateRangePicker(
          dateRangeInput.value,
          {
            startDate: start,
            endDate: end,
            ranges: {
              Today: [moment(), moment()],
              Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
              "Last 7 Days": [moment().subtract(6, "days"), moment()],
              "Last 30 Days": [moment().subtract(29, "days"), moment()],
              "This Month": [moment().startOf("month"), moment().endOf("month")],
              "Last Month": [
                moment().subtract(1, "month").startOf("month"),
                moment().subtract(1, "month").endOf("month"),
              ],
            },
          },
          booking_range
        );

        booking_range(start, end);
      }
    });

    return {
      dateRangeInput,
    };
  },
}
</script>
