<template>
    <div class="on-board-wizard">
        <ul>
            <li>
                <router-link to="/onboarding-professional-email">
                    <div class="onboarding-progress"
                        :class="{ active: (isActive('/onboarding-professional-email') || isActive('/onboarding-professional-email-otp') || isActive('/onboarding-professional-phone') || isActive('/onboarding-professional-phone-otp')) }">
                        <span>1</span>
                    </div>
                    <div class="onboarding-list">
                        <h6>Criar Conta</h6>
                        <p>Informe seus dados para logar no SaaSCare</p>
                        <br />
                        <router-link to="/onboarding-professional-email" class="on-board-wizard-internal">
                            <div :class="isSuccess('emailValidation') ? 'success-onboarding' : 'pending-onboarding'">
                                <p class="text-success"><i class="fas "
                                        :class="isSuccess('emailValidation') ? 'fa-check' : 'fa-exclamation'"></i>
                                    <strong>Email</strong>
                                </p>
                            </div>
                        </router-link>
                        <router-link to="/onboarding-professional-email-otp" class="on-board-wizard-internal">
                            <div :class="isSuccess('emailValid') ? 'success-onboarding' : 'pending-onboarding'">
                                <p class="text-success"><i class="fas "
                                        :class="isSuccess('emailValid') ? 'fa-check' : 'fa-exclamation'"></i>
                                    <strong>Confirmação de
                                        e-mail</strong>
                                </p>
                            </div>
                        </router-link>
                        <router-link to="/onboarding-professional-phone" class="on-board-wizard-internal">
                            <div :class="isSuccess('phone') ? 'success-onboarding' : 'pending-onboarding'">
                                <p class="text-success"><i class="fas "
                                        :class="isSuccess('phone') ? 'fa-check' : 'fa-exclamation'"></i>
                                    <strong>Celular</strong>
                                </p>
                            </div>
                        </router-link>
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/onboarding-professional-avatar">
                    <div class="onboarding-progress"
                        :class="{ active: (isActive('/onboarding-professional-avatar') || isActive('/onboarding-professional-address') || isActive('/onboarding-professional-personal-data')) }">
                        <span>2</span>
                    </div>
                    <div class="onboarding-list">
                        <h6>Informações Pessoais</h6>
                        <p>Informe seus dados pessoais</p>
                        <br />
                        <router-link to="/onboarding-professional-avatar" class="on-board-wizard-internal">
                            <div :class="isSuccess('avatar') ? 'success-onboarding' : 'pending-onboarding'">
                                <p class="text-success"><i class="fas "
                                        :class="isSuccess('avatar') ? 'fa-check' : 'fa-exclamation'"></i>
                                    <strong>Foto de perfil</strong>
                                </p>
                            </div>
                        </router-link>
                        <router-link to="/onboarding-professional-personalize" class="on-board-wizard-internal">
                            <div :class="isSuccess('address') ? 'success-onboarding' : 'pending-onboarding'">
                                <p class="text-success"><i class="fas "
                                        :class="isSuccess('address') ? 'fa-check' : 'fa-exclamation'"></i>
                                    <strong>Endereço</strong>
                                </p>
                            </div>
                        </router-link>
                        <router-link to="/onboarding-professional-personalize" class="on-board-wizard-internal">
                            <div :class="isSuccess('personal-data') ? 'success-onboarding' : 'pending-onboarding'">
                                <p class="text-success"><i class="fas "
                                        :class="isSuccess('personal-data') ? 'fa-check' : 'fa-exclamation'"></i>
                                    <strong>Dados Pessoais</strong>
                                </p>
                            </div>
                        </router-link>
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/onboarding-professional-identity">
                    <div class="onboarding-progress" :class="{ active: isActive('/onboarding-professional-identity') }">
                        <span>3</span>
                    </div>
                    <div class="onboarding-list">
                        <h6>Documento Pessoal</h6>
                        <p>Envie seu documento pessoal</p>
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/onboarding-professional-personalize">
                    <div class="onboarding-progress"
                        :class="{ active: isActive('/onboarding-professional-personalize') }">
                        <span>4</span>
                    </div>
                    <div class="onboarding-list">
                        <h6>Informações Pessoais</h6>
                        <p>Informe seus dados pessoais</p>
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/onboarding-professional-verification">
                    <div class="onboarding-progress"
                        :class="{ active: isActive('/onboarding-professional-verification') }">
                        <span>5</span>
                    </div>
                    <div class="onboarding-list">
                        <h6>Documentos Profissionais</h6>
                        <p>Envie seu documento profissional</p>
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/onboarding-professional-payments">
                    <div class="onboarding-progress" :class="{ active: isActive('/onboarding-professional-payments') }">
                        <span>6</span>
                    </div>
                    <div class="onboarding-list">
                        <h6>Detalhes de Pagamento</h6>
                        <p>Entre com seus dados de pagamento</p>
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/onboarding-professional-preferences">
                    <div class="onboarding-progress"
                        :class="{ active: isActive('/onboarding-professional-preferences') }">
                        <span>7</span>
                    </div>
                    <div class="onboarding-list">
                        <h6>Preferences</h6>
                        <p>Setup Your Preferences for your Account</p>
                    </div>
                </router-link>
            </li>
            <li>
                <router-link to="/onboarding-professional-username">
                    <div class="onboarding-progress"
                        :class="{ active: (isActive('/onboarding-professional-username') || isActive('/onboarding-professional-password')) }">
                        <span>8</span>
                    </div>
                    <div class="onboarding-list">
                        <h6>Informações de Login</h6>
                        <p>Informe seus dados de login</p>
                        <br />
                        <router-link to="/onboarding-professional-username" class="on-board-wizard-internal">
                            <div :class="isSuccess('username') ? 'success-onboarding' : 'pending-onboarding'">
                                <p class="text-success"><i class="fas "
                                        :class="isSuccess('username') ? 'fa-check' : 'fa-exclamation'"></i>
                                    <strong>Nome de usuário</strong>
                                </p>
                            </div>
                        </router-link>
                        <router-link to="/onboarding-professional-password" class="on-board-wizard-internal">
                            <div :class="isSuccess('password') ? 'success-onboarding' : 'pending-onboarding'">
                                <p class="text-success"><i class="fas "
                                        :class="isSuccess('password') ? 'fa-check' : 'fa-exclamation'"></i>
                                    <strong>Senha</strong>
                                </p>
                            </div>
                        </router-link>
                    </div>
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            activeClass: "active",
        }
    },
    methods: {
        isActive(paths) {
            return paths.includes(this.$route.path);
        },
        isSuccess(step) {
            if (localStorage.hasOwnProperty(step) && localStorage.getItem(step) != undefined && localStorage.getItem(step) != 'undefined') {
                return true;
            }
            else {
                return false;
            }
        },
    },
}
</script>