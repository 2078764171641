<template>
  <section class="banner-section banner-sec-fourteen">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="banner-content aos aos-init aos-animate" data-aos="fade-up">
            <div class="banner-head">
              
              <h1>Experimente a maneira mais prática de cuidar da sua saúde!</h1>
              <h6>Encontre profissionais próximos a você</h6>

            </div>
            <div class="banner-form-field">
              <form @submit.prevent="submitForm">
                <div class="input-box">
                  <!--<div class="input-block">
                    <div class="icon-badge">
                      <span><i class="feather-map-pin"></i></span>
                    </div>
                    <div class="banner-input-box">
                      <label class="form-label">Localização</label>
                      <input
                        type="text"
                        id="localizacao"
                        v-model="localizacao"
                        class="form-control"
                        placeholder="Ex.: Brasil"
                      />
                    </div>
                  </div>-->
                  <div class="input-block">
                    <div class="icon-badge">
                      <span><i class="fa-solid fa-user-md"></i></span>
                    </div>
                    <div class="banner-input-box">
                      <label class="form-label">Especialidades</label>

                      <v-autocomplete :items="specializationAreas" item-title="description" item-value="id"
                        id="specializationAreasSearch" v-model="specializationSelectedId" auto-select-first
                        density="comfortable" no-data-text="Informação não localizada"
                        placeholder="Selecione a especialidade" variant="plain"
                        @keydown.enter="selecionaEspecialidade($event)"
                        @update:modelValue="selecionaEspecialidade($event)" />
                    </div>
                  </div>
                  <div class="input-btn">
                    <button type="submit" class="btn">
                      <i class="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </div>
                </div>
              </form>
            </div>

          </div>
        </div>
        <div class="col-lg-6">
          <div class="banner-sec-img">
            <span><img src="@/assets/img/doc-slide-1.png" class="img-fluid" alt="Img" /></span>
            <!--<span class="banner-curve aos aos-init aos-animate" data-aos="fade-up" data-aos-delay="500"><img
                src="@/assets/img/banner-img-curve-13.png" class="img-fluid" alt="Img" /></span>-->
            <span class="banner-round-bg"><img src="@/assets/img/banner-round-vector-13.png" alt="Img" /></span>
            <!--<span class="lief-img"><img src="@/assets/img/banner-lief-img.png" alt="Img" /></span>-->
            <span class="banner-curve-two"><img src="@/assets/img/banner-curve-bg.png" alt="Img" /></span>

            <!--<div class="banner-cal-icon">
              <span class="calender-border"
                ><img src="@/assets/img/icons/banner-cal-icon.svg" alt="Img"
              /></span>
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>


import { ref } from "vue";

const specializationAreas = ref();

export default {

  mounted() {    
    specializationAreas.value = this.especialidades;      
  },
  data() {
    return {
      specializationAreas,
      especialidade: "",
      localizacao: "",
      city: '',
      cities: [],
    };
  },
  props: {
    especialidades: Object
  },
  methods: {
    selecionaEspecialidade(e) {
      this.$nextTick(
        () => {
          let data = specializationAreas.value.find(f => f.id == e)
          if (data != undefined) {
            this.especialidade = data.description;
            this.submitForm();
          }
        }
      )
    },
    submitForm() {
      //this.$router.replace({ path: 'pesquisa', query: { e: this.especialidade, l: this.localizacao } });
     
          window.location.replace("/pesquisa?e="+this.especialidade);
    },
  },
};
</script>
