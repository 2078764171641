<template>
    <!-- Appointment List -->
    <div class="appointment-wrap">
        <ul>
            <li>
                <div class="patinet-information">
                    <router-link to="/doctor-cancelled-appointment">
                        <img src="@/assets/img/doctors-dashboard/profile-01.jpg" alt="User Image">
                    </router-link>
                    <div class="patient-info">
                        <p>#Apt0001</p>
                        <h6><router-link to="/doctor-cancelled-appointment">Adrian</router-link></h6>
                    </div>
                </div>
            </li>
            <li class="appointment-info">
                <p><i class="fa-solid fa-clock"></i>11 Nov 2024 10.45 AM</p>
                <ul class="d-flex apponitment-types">
                    <li>General Visit</li>
                    <li>Video Call</li>
                </ul>

            </li>
            <li class="appointment-detail-btn">
                <router-link to="/doctor-cancelled-appointment" class="start-link">View Details</router-link>
            </li>
        </ul>
    </div>
    <!-- /Appointment List -->

    <!-- Appointment List -->
    <div class="appointment-wrap">
        <ul>
            <li>
                <div class="patinet-information">
                    <router-link to="/doctor-cancelled-appointment">
                        <img src="@/assets/img/doctors-dashboard/profile-02.jpg" alt="User Image">
                    </router-link>
                    <div class="patient-info">
                        <p>#Apt0002</p>
                        <h6><router-link to="/doctor-cancelled-appointment">Kelly</router-link><span class="badge new-tag">New</span>
                        </h6>
                    </div>
                </div>
            </li>
            <li class="appointment-info">
                <p><i class="fa-solid fa-clock"></i>05 Nov 2024 11.50 AM</p>
                <ul class="d-flex apponitment-types">
                    <li>General Visit</li>
                    <li>Audio Call</li>
                </ul>

            </li>
            <li class="appointment-detail-btn">
                <router-link to="/doctor-cancelled-appointment" class="start-link">View Details</router-link>
            </li>
        </ul>
    </div>
    <!-- /Appointment List -->

    <!-- Appointment List -->
    <div class="appointment-wrap">
        <ul>
            <li>
                <div class="patinet-information">
                    <router-link to="/doctor-cancelled-appointment">
                        <img src="@/assets/img/doctors-dashboard/profile-03.jpg" alt="User Image">
                    </router-link>
                    <div class="patient-info">
                        <p>#Apt0003</p>
                        <h6><router-link to="/doctor-cancelled-appointment">Samuel</router-link></h6>
                    </div>
                </div>
            </li>
            <li class="appointment-info">
                <p><i class="fa-solid fa-clock"></i>27 Oct 2024 09.30 AM</p>
                <ul class="d-flex apponitment-types">
                    <li>General Visit</li>
                    <li>Video Call</li>
                </ul>

            </li>
            <li class="appointment-detail-btn">
                <router-link to="/doctor-cancelled-appointment" class="start-link">View Details</router-link>
            </li>
        </ul>
    </div>
    <!-- /Appointment List -->

    <!-- Appointment List -->
    <div class="appointment-wrap">
        <ul>
            <li>
                <div class="patinet-information">
                    <router-link to="/doctor-cancelled-appointment">
                        <img src="@/assets/img/doctors-dashboard/profile-04.jpg" alt="User Image">
                    </router-link>
                    <div class="patient-info">
                        <p>#Apt0004</p>
                        <h6><router-link to="/doctor-cancelled-appointment">Catherine</router-link></h6>
                    </div>
                </div>
            </li>
            <li class="appointment-info">
                <p><i class="fa-solid fa-clock"></i>18 Oct 2024 12.20 PM</p>
                <ul class="d-flex apponitment-types">
                    <li>General Visit</li>
                    <li>Direct Visit</li>
                </ul>

            </li>
            <li class="appointment-detail-btn">
                <router-link to="/doctor-cancelled-appointment" class="start-link">View Details</router-link>
            </li>
        </ul>
    </div>
    <!-- /Appointment List -->

    <!-- Appointment List -->
    <div class="appointment-wrap">
        <ul>
            <li>
                <div class="patinet-information">
                    <router-link to="/doctor-cancelled-appointment">
                        <img src="@/assets/img/doctors-dashboard/profile-05.jpg" alt="User Image">
                    </router-link>
                    <div class="patient-info">
                        <p>#Apt0005</p>
                        <h6><router-link to="/doctor-cancelled-appointment">Robert</router-link></h6>
                    </div>
                </div>
            </li>
            <li class="appointment-info">
                <p><i class="fa-solid fa-clock"></i>10 Oct 2024 11.30 AM</p>
                <ul class="d-flex apponitment-types">
                    <li>General Visit</li>
                    <li>Chat</li>
                </ul>

            </li>
            <li class="appointment-detail-btn">
                <router-link to="/doctor-cancelled-appointment" class="start-link">View Details</router-link>
            </li>
        </ul>
    </div>
    <!-- /Appointment List -->

    <!-- Appointment List -->
    <div class="appointment-wrap">
        <ul>
            <li>
                <div class="patinet-information">
                    <router-link to="/doctor-cancelled-appointment">
                        <img src="@/assets/img/doctors-dashboard/profile-06.jpg" alt="User Image">
                    </router-link>
                    <div class="patient-info">
                        <p>#Apt0006</p>
                        <h6><router-link to="/doctor-cancelled-appointment">Anderea</router-link></h6>
                    </div>
                </div>
            </li>
            <li class="appointment-info">
                <p><i class="fa-solid fa-clock"></i>26 Sep 2024 10.20 AM</p>
                <ul class="d-flex apponitment-types">
                    <li>General Visit</li>
                    <li>Chat</li>
                </ul>

            </li>
            <li class="appointment-detail-btn">
                <router-link to="/doctor-cancelled-appointment" class="start-link">View Details</router-link>
            </li>
        </ul>
    </div>
    <!-- /Appointment List -->

    <!-- Appointment List -->
    <div class="appointment-wrap">
        <ul>
            <li>
                <div class="patinet-information">
                    <router-link to="/doctor-cancelled-appointment">
                        <img src="@/assets/img/doctors-dashboard/profile-07.jpg" alt="User Image">
                    </router-link>
                    <div class="patient-info">
                        <p>#Apt0007</p>
                        <h6><router-link to="/doctor-cancelled-appointment">Peter</router-link></h6>
                    </div>
                </div>
            </li>
            <li class="appointment-info">
                <p><i class="fa-solid fa-clock"></i>14 Sep 2024 08.10 AM</p>
                <ul class="d-flex apponitment-types">
                    <li>General Visit</li>
                    <li>Chat</li>
                </ul>

            </li>
            <li class="appointment-detail-btn">
                <router-link to="/doctor-cancelled-appointment" class="start-link">View Details</router-link>
            </li>
        </ul>
    </div>
    <!-- /Appointment List -->

    <!-- Appointment List -->
    <div class="appointment-wrap">
        <ul>
            <li>
                <div class="patinet-information">
                    <router-link to="/doctor-cancelled-appointment">
                        <img src="@/assets/img/doctors-dashboard/profile-08.jpg" alt="User Image">
                    </router-link>
                    <div class="patient-info">
                        <p>#Apt0008</p>
                        <h6><router-link to="/doctor-cancelled-appointment">Emily</router-link></h6>
                    </div>
                </div>
            </li>
            <li class="appointment-info">
                <p><i class="fa-solid fa-clock"></i>03 Sep 2024 06.00 PM</p>
                <ul class="d-flex apponitment-types">
                    <li>General Visit</li>
                    <li>Video Call</li>
                </ul>

            </li>
            <li class="appointment-detail-btn">
                <router-link to="/doctor-cancelled-appointment" class="start-link">View Details</router-link>
            </li>
        </ul>
    </div>
    <!-- /Appointment List -->

    <!-- Pagination -->
    <div class="pagination dashboard-pagination">
        <ul>
            <li>
                <a href="" class="page-link"><i class="fa-solid fa-chevron-left"></i></a>
            </li>
            <li>
                <a href="" class="page-link">1</a>
            </li>
            <li>
                <a href="" class="page-link active">2</a>
            </li>
            <li>
                <a href="" class="page-link">3</a>
            </li>
            <li>
                <a href="" class="page-link">4</a>
            </li>
            <li>
                <a href="" class="page-link">...</a>
            </li>
            <li>
                <a href="" class="page-link"><i class="fa-solid fa-chevron-right"></i></a>
            </li>
        </ul>
    </div>
    <!-- /Pagination -->
</template>